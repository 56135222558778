/*InteractMenu css - Version 1.12 September 17, 2024 */
/* icons: https://www.svgrepo.com/collection/smoothie-line-icons/ */
:root {
  --main-top: 106px;
  --main-bottom: 40px;
  --prompt-left: 130px;
  --prompt-narrow-left: 90px;
  --scan-height: 0px;
  --scanHT: 260px;
  --scanWD: 260px;
  --itemDetailPopupHt: 800px;
  --menu-height: 330px;
  --menu-heightO: 330px;
  --screen-width: 700px;
  --screen-height: 700px;
  --pay-height: 670px;
  --pay-top-card: 570px;
  --pay-top-gift: 570px;
  --pay-top-google: 570px;
  --category-height: 74px;
  --header-spec-ht: 0px;
  --header-disp-ht: 0px;
  --header-msg-ht: 0px;
  --header-land-msg-ht: 0px;
  --header-land-ht: 160px;
  --header-land-info: 0px;
  --options-image-height: 0px;
  --options-header-height: 0px;
  --options-list-height: 2000px;
  --LandingBackgroundColor: #dae1f4;
  --BrandColor: #dae1f4;
  --LandingTextColor: #fcf7f8;
  --DropShadowColor: #b9b9b9;
  /*Payment Border*/
  --CardBorderColorN: #c1f7f2;
  --CardBorderColorC: #c1f7f2;
  --CardBorderColorE: #c1f7f2;
  --signinPhone: #787878;
  --signinVerify: #787878;
  /*--- Config Settings ---*/
  --InputTextColor: #026dc4;
  --InputBGColor: #f5fafa;
  --InputBorderColor: #c1f7f2;
  --InputFontType: 'Verdana';
  --InputFontSize: '14px';
  --ButtonTextColor: #1b347e;
  --ButtonBGColor: #d5ddf6;
  --ButtonFontType: 'Verdana';
  --ButtonFontSize: '14px';
  --HeadingHeight: 124px;
  --HeadingTextColor: #f0f0f0;
  --HeadingFontType: 'Verdana';
  --HeadingFontSize: '14px';
  --ItemNameColor: #f0f0f0;
  --ItemNameFontType: 'Verdana';
  --ItemNameFontSize: '14px';
  --ItemNameFontWht: '14px';
  --ItemDescColor: #f0f0f0;
  --ItemDescFontType: 'Verdana';
  --ItemDescFontSize: '500';
  --ItemDescFontWht: '500';
  --ItemBuyColor: black;
  --ItemTotalColor: #3e80ed;
  --ItemBuyFontType: 'Verdana';
  --ItemBuyFontSize: '14px';
  --ItemBuyFontWht: '14px';
  --ListBGColor: #f0f0f0;
  --ListFontType: 'Verdana';
  --ListFontSize: '14px';
  --ListHeadTextColor: #f0f0f0;
  --ListHeadBGColor: #f0f0f0;
  --ListBodyBGColor: #fafafa95;
  --HeadFontType: 'Verdana';
  --HeadFontSize: '14px';
  --HeadFontNameSize: '18px';
  --ListSectionTextColor: #f0f0f0;
  --ListSectionBGColor: #f0f0f0;
  --SectionFontType: 'Verdana';
  --SectionFontSize: '14px';
  --SectionFontNameSize: '18px';
  --PromptColor: #f0f0f0;
  --PromptFontType: 'Verdana';
  --PromptFontSize: '14px';
  --PromoColor: #33cc33;
  --CartColor: #33cc33;
  --PurchasedColor: #800000;
  --signinTextColor: #214D6E;
  /*-- Addition Hardcoded Controls --*/
  /*-----------------------------*/
  /*--*/
  --okay-color: #248f24;
  --ro-back-color: #fff;
  --attn-color: #009900;
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --brandColor1: #993300;
  --brandColor2: #993300;
  --brandColor3: #993300;
  --brandColor4: #993300;
}
* {
  margin: 0px;
  padding: 0px;
  max-width: 1200px; /* Set here (for all controls) and in PageMain */
}
body {
  font: 12px Arial, Helvetica, sans-serif; /* Myriad Pro */
  color: #a7a6a6;
  padding: 0;
  margin: 0;
  max-width: 750px;
  min-width: 350px;
}
::-webkit-scrollbar {
  display: none;
}/**/
.brsmall {
  display: block;
  height: 0px;
  margin-top: -4px;
  margin-bottom: 0em;
/*  background-color: green;
*/}
/*------------- Logon and Register css ---------------------------------------------------------------------------*/
.pageLoginBorder {
  border: 3px solid var(--signinTextColor);
  border-radius: 5px;
  position: absolute;
  display: block;
  top: 2px;
  left: 2px;
  height: 480px;
  width: 296px;
  background-color: white;
}
.pageRegisterBorder {
  border: 3px solid green;
  border-radius: 5px;
  position: absolute;
  display: block;
  top: 2px;
  left: 2px; /*calc((var(--screen-width) - 350px)/2px);*/
  height: 680px;
  width: 296px;
  background-color: white;
}
.logLabel {
  font-size: large;
  color: black;
  font-weight: bold;
}
.logLabelSub {
  font-size: 12px;
  color: black;
  font-weight: normal;
}        
.loginPageInside {
  /*border: solid 3px black;  */
  position: absolute;
  top: 60px;
  bottom: 1px;
  left: 0;
  right: 0;
  background-color: white;
}
.loginHeading {
  font-size: 24px;
  font-weight: bold;
  color: var(--signinTextColor);
  width: 100%;
}
.loginHeadingS {
  font-size: 18px;
  font-weight: bold;
  color: var(--signinTextColor);
  width: 100%;
}
.loginLabel {
  font-size: large;
  color: red;
  font-weight: bold;
  margin-left: 10px;
}
.signin {
  cursor: pointer;
  color: var(--BrandColor);
  background-color: var(--HeadingTextColor);
  font-family: var(--HeadingFontType);
  font-size: 14px;
  font-weight: bold;
}
.signinField {
  font-size: medium;
  color: black;
  border-radius: 5px;
  border: 3px solid #ccc;
  margin: 2px 2px 2px 0px;
  padding: 2px 2px 2px 2px;
}
.pwField {
  /* border: 2px solid red; */
  padding: 0;
  margin: 8px 3px 1px 20px;
  width: 100%;
  /*background-color: white;*/
  justify-content: right;
  height: 35px;
}
.codeField {
  /*border: 2px solid red;  */
  padding: 0;
  margin: 18px 3px 1px 20px;
  width: 250px;
  /*background-color: white;
  justify-content: right;*/
  height: 35px;
}
.signinPhoneInputM {
  font-size: medium;
  color: black;
  border-radius: 5px;
  border: 3px solid #ccc;
  margin: 2px 2px 2px 0px;
  padding: 2px 2px 2px 2px;
  width: 200px;
  height: 31px;
}
.signinPhoneInput {
  font-size: medium;
  color: black;
  border-radius: 5px;
  border: 3px solid;
  border-color: var(--signinPhone);
  margin: 2px 2px 2px 0px;
  padding: 2px 2px 2px 2px;
  width: 160px;
  height: 19px;
}
.verifyField {
  font-size: medium;
  color: black;
  border-radius: 5px;
  border: 3px solid;
  border-color: var(--signinVerify);
  margin: 2px 2px 2px 0px;
  padding: 2px 2px 2px 2px;
  width: 160px;
  height: 19px;
}
.confirmButton {
  border: none;
  border-radius: 5px;
  position: relative;
  left: 20px;
  top: 0px;
  display: block;
  width: 80%;
  height: 30px;
  color: white;
  font-size: 16px;
  background-color: var(--signinTextColor);
}
.submitButton {
  border: none;
  border-radius: 5px;
  position: relative;
  left: 20px;
  top: 0px;
  display: block;
  width: 80%;
  height: 30px;
  color: white;
  font-size: 16px;
  background-color: var(--signinTextColor);
}
h2 {
  font-size: 24px;
  margin: 10px 0;
  padding: 0 0 0 20px;
  color: blue;
}
/*------------- Header and Footer css ---------------------------------------------------------------------------*/
.clear {
  clear: both;
  margin: 0px;
  padding: 0px;
}
a {
  color: #fff;
  text-decoration: underline;
}
a:hover {
  text-decoration: none;
}
menuhref {
  padding: 0;
  margin: 0px 0px 0px 1px;
}
#Search {
  border-radius: 5px;
  position: absolute;
  top: 2px;
  right: 34px;
  height: 24px;
  width: 160px;
  resize: none;
}
.searchBtnImg {
  position: absolute;
  margin: 0;
  top: 3px;
  right: 10px;
  width: 21px;
  resize: both;
  height: 23px;
}
.searchClearImage {
  position: absolute;
  left: 1px;
  top: 3px;
  width: 14px;
  resize: both;
  height: 14px;
}
.avatarFramePic {
  height: 230px;  
  width: 100%;
  background-color: white;
  border-radius: 10px;
}
.avatarCategoryPic {
  height: 74px; 
  width: 74px;
  margin-left: 18px;
  background-color: white;
  border-radius: 8px;
}
.avatarOrderPic {
  height: 76px;
  width: 76px;
  background-color: var(--GridBGColor);
  border-radius: 10px;
}
.frameFlexPic {
  border-radius: 15px;
  box-shadow: 5px 5px 3px var(--DropShadowColor);
  /*border: 3px solid var(--LandingBackgroundColor);*/
  background-color: white;
  margin: 0 0 0 0;
  padding: 0px 0px 5px 0px;
  height: 295px;
}
.barcodeScannerBox {
  width: var(--scanWD);
  height: var(--scanHT);
  border-radius: 15px;
  border: 3px solid #ccc;
  background-color: #eee;
  left: 100px;
  /*  margin-right: auto;*/
  float: none;
  padding: 10px 10px 20px 10px;
}
.barcodeMessage {
  position: absolute;
  font-size: 18px;
  font-weight: bold;
  color: black; /*var(--HeadingTextColor);*/
  font-family: var(--HeadingFontType);
  left: 29px;
  width: 300px;
  bottom: 2px;
}
#overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 2em;
}
/*-------- Component Css -----------------------------------------------------------*/
.shoppingCart {
}
/*-------- Landing Page -----------------------------------------------------------*/
.headerLanding {
  position: fixed;
  max-width: 750px;
  min-width: 350px;
  width: 100%;
  height: var(--header-land-ht);
  background-color: var(--BrandColor);
  color: var(--LandingTextColor);
  z-index: 10;
  /*border: 1px solid red;  */
}
.landingImagesInfo {
  /*border: 1px solid blue;   */
  top: calc(var(--header-land-ht) + 10px);
  position: relative;
}
.landingAdditional {
  position: absolute;
  top: 614px;
  width: 100%;
  height: 500px;
  /*border: 2px solid blue;  */
}
.landingSubDiv {
  position: relative;
  left: 1%;
  margin: 10px 0 5px 0;
  padding: 2px 5px 0 6px;
  width: 94%;
  height: 95px;
  background-color: var(--LandingBackgroundColor);
  border-radius: 15px;
  box-shadow: 7px 5px 3px var(--DropShadowColor);
  /*border: 2px solid var(--ButtonTextColor);  */
}
.landingSubTextDiv {
  position: relative;
  left: 5px;
  top: 5px;
  width: 97%;
  height: 68px;
  /*border: 2px solid red;  */
}
.landingMessages {
  position: absolute;
  top: 62px;
  left: 18%;
  width: 94%;
  height: 90px;
  /*border: 1px solid red;  */
}
.landingStoreInfo {
  position: absolute;
  top: calc(165px + var(--header-land-msg-ht));
  width: 100%;
  height: 300px;
}
.landingInfo {
  /*border: 1px solid red;  */
  position: absolute;
  /*top: calc(165px + var(--header-land-msg-ht));*/
  top: 0;
  width: 100%;
  height: 300px;
}
.landingList {
  /*border: 2px solid green;  */
  position: absolute;
  top: 140px;
  left: 5px;
  bottom: 25px;
  width: 100%;
  height: 80%;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: scroll;
}
.landingBottom {
  /*border: 2px solid blue;  */
  position: absolute;
  bottom: 15px;
  left: 100px;
  width: 100%;
  height: 25px;
}
.landingBottomRes {
  /*border: 2px solid blue;  */
  position: absolute;
  bottom: 15px;
  left: 76px;
  width: 100%;
  height: 25px;
}
.framePromoPic {
  width: 400px;
  height: 200px;
  border-radius: 15px;
  border: 3px solid #ccc;
  background-color: #eee;
  margin: 0;
  padding: 15px 20px;
}
.popupAboutDetail {
  width: 350px;
  height: 900px;
  border-radius: 15px;
  border: 3px solid blue;
  background-color: white;
  margin: 2px;
  padding: 3px 3px;
}
.popupAccountDetail {
  width: 374px;
  height: 620px;
  border-radius: 15px;
  border: 3px solid blue;
  background-color: white;
  margin: 2px;
  padding: 3px 3px;
}
.frameInfo {
  width: 328px;
  height: 98%;
/*  border-radius: 15px;
  padding: 3px 3px;
  border: 3px solid blue;*/
  background-color: white;
  margin: 2px;
}
.landingHeadTextReg {
  font-size: 14px;
  color: var(--LandingTextColor);
}
.landingHeadText {
  font-size: 16px;
  font-weight: bold;
  color: var(--LandingTextColor);
}
.landingHeadTextLarge {
  font-size: 20px;
  font-weight: bold;
  /*color: var(--LandingTextColor);*/
}
.landingHeadTextSmall {
  font-size: 12px;
  font-weight: bold;
  color: var(--LandingTextColor);
}
.landingTextXXXL {
  font-size: 24px;
  font-weight: bold;
  color: var(--LandingTextColor);
}
.landingTextXXL {
  font-size: 18px;
  font-weight: bold;
  color: var(--LandingTextColor);
}
.landingTextXL {
  font-size: 18px;
  font-weight: bold;
  color: var(--LandingTextColor);
}
.landingRTextXL {
  font-size: 20px;
  font-weight: bold;
  height: 28px;
}
.landingLTextXL {
  font-size: 18px;
  font-weight: bold;
  margin-left: 12px;
  color: var(--LandingTextColor);
}
.landingLTextL {
  font-size: 16px;
  font-weight: bold;
  margin-left: 12px;
  color: var(--LandingTextColor);
}
.landingLTextXLB {
  font-size: 18px;
  font-weight: bold;
  margin-left: 12px;
  color: #0f0f0f;
}
.landingLTextLB {
  font-size: 16px;
  font-weight: bold;
  margin-left: 12px;
  color: #0f0f0f;
}
.landingRSubText {
  font-size: 16px;
  font-weight: bold;
  color: black;
  margin: 4px 5px 1px 5px;
  height: 40px;
}
.landingRButtons {
  /*border: 3px solid blue;*/
  margin: 2px 25px 1px 25px;
  height: 40px;
}
.landingTextL {
  font-size: 16px;
  font-weight: bold;
  color: var(--LandingTextColor);
}
.landingTextM {
  font-size: 14px;
  font-weight: bold;
  color: var(--LandingTextColor);
}
.landingTextS {
  font-size: 12px;
  font-weight: bold;
  color: var(--LandingTextColor);
}
.landingTextP {
  font-size: 18px;
  font-weight: 300;
  color: black;
  margin: 10px 8px 7px 8px
}
.landingTextPS {
  font-size: 18px;
  font-weight: 300;
  color: black;
}
.listRowLoc1 {
  float: left;
  width: 100%;
}
.listRowLoc2 {
  float: left;
  width: 100%;
}
.listRowLoc3 {
  float: left;
  width: 100%;
}
/*---------- Main Menu Style --------------------*/
.popupMenu {
  padding: 0px;
  margin: 0px;
  color: #787878;
  background-color: #fcf7f8;
  border: solid 2px #0073e6;
  border-radius: 5px;
  border-style: groove;
  position: absolute;
  left: -150px;
  width: 150px;
  height: var(--menu-height);
  line-height: 12px;
  font-family: var(--HeadFontType);
  font-size: 12px;
}
.popupMenuO {
  padding: 0px;
  margin: 0px;
  color: #787878;
  background-color: #fcf7f8;
  border: solid 2px #0073e6;
  border-radius: 5px;
  border-style: groove;
  position: absolute;
  left: -150px;
  width: 150px;
  height: var(--menu-heightO);
  line-height: 12px;
  font-family: var(--HeadFontType);
  font-size: 12px;
}
.mainMenu {
  position: relative;
  top: 0;
  padding: 0;
  margin: 0;
  left: 0px;
}
.menuTree { /* Treeview in Menu */
  position: relative;
  top: 65px;
  left: 0px;
  color: #1B2026;
  background-color: white;
  overflow-y: hidden;
}
.menuTreeSub {
  font-size: larger;
  font-weight: bold;
  padding: 0;
}
.menuTreeItem {
/*  border: 3px solid blue;*/
  margin: -4px 0 -4px -22px;
  padding: 0;
  display: flex; /* This allows the -margin to work */
}
/*-----------------------------*/
.popupQRCode {
  padding: 0px;
  margin: 0px;
  color: #787878;
  background-color: #fcf7f8;
  border: solid 2px #0073e6;
  border-radius: 5px;
  border-style: groove;
  position: absolute;
  left: 0;
  width: 230px;
  height: 410px;
  line-height: 13px;
  font-family: var(--HeadFontType);
  font-size: 12px;
}
.headerScan {
  position: absolute;
  left: 0;
  top: 0;
  height: 142px; /*82px;*/
  width: 100%;
  background-color: var(--BrandColor);
  color: var(--LandingTextColor);
  /*border: 1px solid green; */
}
.wholeDiv {
  /*  width: 100%;
  height: 100%; */
  position: absolute;
  width: 100%;
  height: 100%;
  /* border: 5px solid red; */
}
.popupLogin {
  padding: 0px;
  margin: 0px;
  color: #787878;
  background-color: #fcf7f8;
  border: solid 2px #0073e6;
  border-radius: 5px;
  border-style: groove;
  position: absolute;
  left: 0px;
  width: 180px;
  height: var(--login-height); /*300px;*/
  line-height: 13px;
  font-family: var(--HeadFontType);
  font-size: 12px;
}
.popupIM {
  padding: 5px;
  margin: 10px;
  color: #787878;
  background-color: #fcf7f8;
  border: solid 2px #0073e6;
  border-radius: 10px;
  border-style: groove;
  position: absolute;
  left: 0px;
  width: 98%;
  min-width: 350px;
  height: 600px;
  line-height: 22px;
  font-family: var(--HeadFontType);
  font-size: 12px;
}
.popupGoogle {
  padding: 0px;
  margin: 0px;
  color: #787878;
  background-color: #fcf7f8;
  border: solid 2px #0073e6;
  border-radius: 5px;
  border-style: groove;
  position: absolute;
  left: 0px;
  width: 177px;
  height: 48px;
  line-height: 13px;
  font-family: var(--HeadFontType);
  font-size: 12px;
}
.popupTakePicture {
  padding: 0px;
  margin: 0px;
  color: #787878;
  background-color: #fcf7f8;
  border: solid 2px #0073e6;
  border-radius: 5px;
  border-style: groove;
  position: absolute;
  left: 0px;
  width: 298px;
  height: 280px;
  line-height: 13px;
  font-family: var(--HeadFontType);
  font-size: 12px;
}
.pageTakePicture {
  border: 2px solid green;
  border-radius: 5px;
  position: absolute;
  display: block;
  top: -3px;
  left: -3px; /*calc((var(--screen-width) - 350px)/2px);*/
  height: 450px;
  width: 300px;
  background-color: white;
}
.popupChangePW {
  padding: 0px;
  margin: 0px;
  color: #787878;
  background-color: #fcf7f8;
  border: solid 2px #0073e6;
  border-radius: 5px;
  border-style: groove;
  position: absolute;
  left: 0px;
  width: 170px;
  height: 220px;
  line-height: 13px;
  font-family: var(--HeadFontType);
  font-size: 12px;
}
#menuBox {
  border: solid 2px #0073e6;
  border-radius: 5px;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: hidden;
  height: 98%;
  top: 2px;
  right: 1px;
  left: 2px;
  width: 300px;
  max-width: 700px;
  bottom: 2px;
  background-color: white;
  text-decoration: none;
  text-align: left;
  color: #fff;
  line-height: 13px;
  font-family: var(--HeadFontType);
  font-size: 12px;
  scrollbar-face-color: #367CD2;
  scrollbar-shadow-color: #FFFFFF;
  scrollbar-highlight-color: #FFFFFF;
  scrollbar-3dlight-color: #FFFFFF;
  scrollbar-darkshadow-color: #FFFFFF;
  scrollbar-track-color: #FFFFFF;
  scrollbar-arrow-color: #FFFFFF;
}
/*------ Logo --------------------------------------------------------*/
.header {
  width: 100%;
  height: 105px;
  background-color: var(--BrandColor);
  color: var(--LandingTextColor);
  /*border: 1px solid red;*/
}
.bodyCheckout {
  /*border: 1px solid red;   */
  top: 201px;
  position: relative;
}
.headerCheckout {
  position: fixed;
  z-index: 5;
  width: 100%;
  max-width: 750px;
  min-width: 350px;
  height: 195px;
  background-color: var(--BrandColor);
  color: var(--LandingTextColor);
  border: 1px solid green; /**/
}
.footerCheckout {
  position: fixed;
  bottom: 0px;
  z-index: 5;
  width: 100%;
  height: 105px;
  background-color: var(--BrandColor);
  color: var(--LandingTextColor);
  border: 1px solid green; /**/
}
.headerCheckoutCenterMid {
  position: absolute;
  left: 5px;
  top: 43px;
  height: 33px; /*82px;*/
  width: 70%;
  /* border: 1px solid red;  */
}
.headerCheckoutMessage {
  position: absolute;
  color: black;
  background-color: white;
  /*border: 3px solid grey;*/
  border-radius: 10px;
  top: 68px;
  height: var(--header-msg-ht);
  left: 0;
  width: 100%;
}
.headerCheckoutBottom {
  position: absolute;
  left: 0;
  top: calc(68px + var(--header-msg-ht));
  height: 35px; /*82px;*/
  width: 100%;
  /* border: 1px solid green;  */
}
.headerCheckoutButtons {
  /*border: 1px solid green;   */
  position: absolute;
  top: 25px;
  right: 5px;
  width: 35%;
  height: 160px;
}
popupReviewPurchase {
  color: #787878;
  background-color: #fcf7f8;
}
.pageReviewBorder {
  border: 3px solid blue;
  border-radius: 10px;
  position: absolute;
  height: var(--pay-height);
  width: 356px;
  background-color: white;
}
.checkoutHeaderDetail {
  display: block;
  /*border: solid 3px black;  */
  margin: 0;
  top: 24px;
  left: 2px;
  width: 100%;
  height: 65px;
  color: var(--LandingTextColor);
  background-color: var(--BrandColor);
  font-family: var(--InputFontType);
  font-size: var(--InputFontSize);
  text-decoration: none;
  text-align: center;
  font-size: 18px;
}
.checkoutTop {
  display: block;
  border-radius: 10px;
  left: 0;
  top: 0;
  margin: 0;
  height: 27px; /*82px;*/
  width: 100%;
  color: var(--BrandColor);
  background-color: white;
}
.checkoutLeft {
  position: absolute;
  left: 0;
  top: 2px;
  font-size: 14px;
  width: 160px;
  z-index: 4;
  cursor: pointer;
  font-size: 16px;
}  
.checkoutPay {
  border: solid 3px black; /* */
  position: absolute;
  top: 115px;
  bottom: 1px;
  left: 5px;
  right: 3px;
  background-color: white;
}
.listREVContainer {
  position: absolute;
  display: flex;
  top: 125px;
  height: 150px;
  left: 5px;
  right: 3px;
  /*background-color: white;*/
  color: black;
  font-size: 16px;
  line-height: 29px;
  /*justify-content: space-between;*/
  justify-content: space-around;
  /*border: 2px solid cyan;  */
}
.listREVContainer2 {
  position: absolute;
  display: flex;
  top: 290px;
  height: 100px;
  left: 5px;
  right: 3px;
  background-color: white;
  /*justify-content: space-between;*/
  justify-content: space-around;
  /*border: 2px solid red;  */
}
.listREVContainer3 {
  position: absolute;
  /*display: flex;*/
  display: block;
  top: 375px;
  height: 85px;
  left: 5px;
  right: 3px;
  background-color: white;
  /*justify-content: space-between;*/
  justify-content: space-around;
  /*border: 2px solid black;  */
}
.buttonContainer {
  position: absolute;
  /*border: 2px solid blue;  */
  top: 30px;
  width: 90%;
  height: 30px;
  font-size: 18px;
}
.listREVContainer4 {
  position: absolute;
  display: flex;
  top: 465px;
  bottom: 1px;
  left: 47px;
  /*right: 3px;*/
  font-size: 16px;
  line-height: 31px;
  color: black;
  background-color: white;
  /*justify-content: space-between;*/
  justify-content: space-around;
  /*border: 2px solid green;  */
}
.listREVContainer5 {
  position: absolute;
  display: flex;
  top: var(--pay-top-card);
  bottom: 1px;
  left: 5px;
  right: 3px;
  height: 30px;
  background-color: white;
  /*justify-content: space-between;*/
  justify-content: space-around;
  /* border: 2px solid purple; */
}
.listREVContainer6 {
  position: absolute;
  display: flex;
  top: var(--pay-top-gift);
  bottom: 1px;
  left: 5px;
  right: 3px;
  height: 30px;
  background-color: white;
  /*justify-content: space-between;*/
  justify-content: space-around;
  /*border: 2px solid purple;  */
}
.listREVContainer7 {
  position: absolute;
  display: flex;
  top: var(--pay-top-google);
  bottom: 1px;
  left: 5px;
  right: 3px;
  height: 30px;
  background-color: white;
  /*justify-content: space-between;*/
  justify-content: space-around;
  /*border: 2px solid purple;  */
}
.listREVContainer8 {
  position: absolute;
  display: flex;
  top: 285px;
  height: 50px;
  left: 5px;
  right: 3px;
  font-size: 16px;
  line-height: 31px;
  color: black;
  background-color: white;
  /*justify-content: space-between;*/
  justify-content: space-around;
  /*border: 2px solid green;  */
}
.boxREV1 {
  flex: 4;
  padding: 1px;
  text-align: end;
  margin-right: 5px;
  /*border: 1px solid black; */
}
.boxREV2 {
  flex: 2;
  padding: 1px;
  text-align: end;
  padding-right: 25px;
  font-weight: bold;
  font-size: 18px;
  /*border: 1px solid green; */
}
.boxREV25 {
  flex: 1;
  padding: 1px;
  text-align: end;
  padding-right: 25px;
  /*border: 1px solid green; */
}     
.boxREV3 {
  flex: 4;
  padding: 1px;
  text-align: end;
  margin-right: 5px;
  /*border: 1px solid black; */
}
.boxREV4 {
  flex: 2;
  padding: 1px;
  text-align: end;
  padding-right: 25px;
  font-weight: bold;
  font-size: 18px;
  /*border: 1px solid green; */
}
.dispDateCH {
  border: none;
  position: absolute;
  left: 60px;
  width: 260px;
  height: 23px;
  margin-top: 0px;
  padding-left: 3px;
  font-family: var(--InputFontType);
  font-size: var(--InputFontSize);
}
.headerCheckoutTotal {
  /*border: 1px solid red;   */
  position: absolute;
  display: flex;
  justify-content: space-evenly;
  font-size: 18px;
  /*background-color: var(--BrandColor);
  color: var(--LandingTextColor);*/
  left: 0;
  top: 105px;
  width: 180px;
  height: 90px;
}
.boxCHO1 {
  flex: 3;
  padding: 1px;
  text-align: end;
  margin-right: 5px;
  /*border: 1px solid black; */
}
.boxCHO2 {
  flex: 3;
  padding: 1px;
  text-align: end;
  padding-right: 5px;
  /*border: 1px solid green; */
}
.listCHOContainer {
  position: absolute;
  display: flex;
  left: 24px;
  width: 240px;
  height: 24px;
  /*justify-content: space-between;*/
  justify-content: space-around;
  border: 1px solid green; /* */
}
.boxCH1 {
  flex: 13; /* Takes up 13n of the container */
  padding: 3px 0 3px 8px;
  color: black;
  font-size: 14px;
  /*border: 1px solid black;*/
}
.boxCHO3 {
  flex: 6;
  padding: 1px;
  /*border: 1px solid black;*/
  justify-content: flex-end;
  margin-left: auto;
  margin-right: 6px;
}
.boxCHO4 {
  flex: 6; 
  padding: 1px;
  /*border: 1px solid black;*/
  margin-left: auto;
  margin-right: 6px;
}
/*---------------------------------------------------------------*/
.headerReceipts {
  position: fixed;
  width: 100%;
  height: 160px; /*160px; calc(200 + var(--header-spec-ht) + var(--header-disp-ht) + var(--header-msg-ht)); */
  max-width: 750px;
  min-width: 350px;
  z-index: 4;
  background-color: var(--BrandColor);
  color: var(--LandingTextColor);
  /* border: 3px solid red;  */
}
.receiptList {
  /*border: solid 3px green; */
  position: absolute;
  top: 142px; /* 110px;*/
  left: 0px;
  right: 0px;
  background-color: white;
}
.headerRecBottom {
  position: absolute;
  left: 0;
  top: 59px;
  height: 132px; /*82px;*/
  width: 100%;
  /* border: 1px solid green;  */
}
.headerRecCenterTop {
  position: absolute;
  top: 44px;
  left: 25px;
  height: 24px; /*82px;*/
  width: 82%;
  /*border: 1px solid blue;   */
}
.headerRecCenterMid { /* I can't get this box to move?? */
  position: absolute;
  left: 60px;
  top: 55px;
  height: 24px; /*82px;*/
  width: 70%;
  /*border: 1px solid green;   */
}
.headerRecFarRight { /* I can't get this box to move?? */
  position: absolute;
  right: 6px;
  top: 55px;
  height: 30px;
  width: 30px;
  /*border: 1px solid red;   */
}
.SLButton {
  cursor: pointer;
  font-size: medium;
  color: var(--ButtonTextColor);
  background-color: var(--ButtonBGColor);
  font-weight: bold;
  border-radius: 5px;
  left: 160px;
  top: 0px;
  height: 24px;
}
/*.receiptsFav {
  position: absolute;
  top: -5px;
  right: 10px;
  width: 110px;
  z-index: 120;*/
  /* border: 1px solid blue; */
/*}*/
.footerReceipts {
  position: fixed;
  bottom: 0px;
  width: 100%;
  height: 60px;
  max-width: 750px;
  min-width: 350px;
  z-index: 100;
  background-color: var(--BrandColor);
  color: var(--LandingTextColor);
  /*border: 1px solid red;   */
}
.listRECContainer {
  position: absolute;
  display: flex;
  left: 0px;
  width: 96%;/**/
  height: 100%;/**/
  /*justify-content: space-between;*/
  justify-content: space-around;
  /*border: 1px solid green;  */
}
.boxREC1 {
  flex: 6;
  padding: 3px 0 3px 8px;
  color: black;
  font-size: 13px;
  font-weight: bold;
  /*overflow: visible;*/
  /*border: 1px solid black;*/
}
.boxREC2 {
  flex: 1;
  display: block;
  padding: 1px;
  /*border: 1px solid black;*/
  font-size: 14px;
  color: black;
  line-height: 19px;
  justify-content: flex-end;
  margin-left: auto;
  margin-right: 6px;
}
.boxREC3 {
  flex: 1;
  display: block;
  padding: 1px;
  font-size: 16px;
  font-weight: bold;
  line-height: 19px;
  color: black;
  /*border: 1px solid black;*/
  margin-left: auto;
  margin-right: 6px;
}    
/*---------------------------------------------------*/
.listReceiptsHeader {
  border-radius: 10px;
  border: 3px solid #ccc;
  background-color: var(--BrandColor);
  color: var(--LandingTextColor);
  height: 28px;
  width: 98%;
  font-size: medium;
  overflow-x: hidden;
}
.receiptsHeaderLeft {
  border: none;
  position: relative;
  height: 28px;
  width: 80%;
  font-size: medium;
  float: left;
  left: 1em;
  top: 1px;
}
.listReceiptsRow {
  overflow: auto;
  clear: both;
  border-radius: 10px;
  border: 2px solid #ccc;
  margin: 3px 0px 3px 3px;
  padding: 0px 3px 0px 3px;
  min-height: 87px;
  width: 96%;
  background-color: white;
}
.receiptCol1 {
  float: left;
  width: calc(100% - 200px);
}
.receiptCol2 {
  /*border: solid 3px red;  */
  float: left;
  width: calc(100% - 450px);
}
.receiptColR {
  /*border: solid 3px green;  */
  float: right;
  width: 120px;
  margin-top: 0;
}
.receiptColR1 {
  float: right;
  width: 70px;
}
.listReceiptsFooter {
  border-radius: 10px;
  border: 3px solid var(--ButtonBGColor); /**/
  background-color: var(--BrandColor);
  color: var(--LandingTextColor);
  height: 40px;
  width: 98%;
  font-size: large;
  overflow-x: hidden;
}
.receiptsFooterLeft {
  position: relative;
  height: 80px;
  width: 50%;
  max-width: 180px;
  font-size: large;
  float: left;
  left: 0px;
  top: 2px;
}
/*---------------------------------------------------------------*/
.headerOrder {
  position: fixed;
  width: 100%;
  height: calc(var(--HeadingHeight) + 13px); /*160px; calc(200 + var(--header-spec-ht) + var(--header-disp-ht) + var(--header-msg-ht)); */
  max-width: 750px;
  min-width: 350px;
  z-index: 4;
  background-color: var(--BrandColor);
  color: var(--LandingTextColor);
  /* border: 3px solid red;  */
}
.footerOrder {
  /*position: fixed;*/
  position: fixed;
  bottom: 0px;
  width: 100%;
  height: 80px;
  max-width: 750px;
  min-width: 350px;
  /*z-index: 100;*/
  background-color: var(--BrandColor);
  color: var(--LandingTextColor);
  /*border: 1px solid red;  */
}
.headerShort {
  width: 100%;
  height: 55px;
  background-color: var(--BrandColor);
  color: var(--LandingTextColor);
  /*border: 1px solid red;  */
}
.headerIMTop {
  width: 100%;
  height: 60px;
  /*border: 1px solid red;  */
}
.headerIMMiddleTop {
  top: 60px;
  height: 100px; /*82px;*/
  width: 100%;
  border: 1px solid blue; /* */
  font-size: 20px;
  color: black;
  font-family: var(--HeadFontType);
}
.headerIMButton {
  /*border: 1px solid green; */
  margin: 10px 3px 10px 70px;
  width: 200px;
  /*background-color: white;*/
  height: 55px;
}
.headerIMMiddle {
  top: 160px;
  height: 200px; /*82px;*/
  width: 100%;
  /*border: 1px solid green;  */
}
.headerLogoLeft {
  position: absolute;
  left: 0;
  top: 0;
  width: 20%;
  max-width: 150px;
  height: 110px; /*82px;*/
  margin: -15px 0 0 -19px; 
  /* border: 2px solid blue;*/
}
#headerLogo {
  border: none;
  position: absolute;
  display: block;
  width: 100%;
  height: 84%;
  /*border: 1px solid blue;   */
}
.headerTop {
  position: absolute;
  left: 0;
  top: 0;
  color: var(--BrandColor);
  background-color: var(--LandingTextColor);
  height: 40px; /*82px;*/
  width: 100%;
  z-index: 1;
}  
/*------ Landing Header Middle --------------------------------------------------------*/
.headerLandMiddle {
  position: absolute;
  left: 0;
  top: 65px;
  height: var(--header-land-msg-ht); /*82px;*/
  width: 100%;
  /* border: 1px solid red;  */
}
.headerLandMessage {
  position: absolute;
  color: black;
  background-color: white;
  /*border: 3px solid grey;*/
  border-radius: 10px;
  padding: 5px 5px 5px 10px;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 20px;
  top: 0;
  height: var(--header-land-msg-ht);
  left: 0;
  width: 93%;
}
/*-------------------------*/
.headerLeft {
  position: absolute;
  left: 0;
  top: 8px;
  font-size: 16px;
  height: 20px; /*82px;*/
  width: 180px;
  z-index: 4;
  cursor: pointer;
}
/* standard */
.headerCenter {
  position: relative;
  left: 15%;
  top: 2px;
  height: 50px; /*82px;*/
  width: 84%;
  /*width: 280px;*/
  /*border: 2px solid green; */
}
.headerCenterTop {
  position: absolute;
  left: 2px;
  height: 24px; /*82px;*/
  width: 82%;
  /*border: 1px solid blue;   */
}
.headerCenterMid {
  position: absolute;
  left: 5px;
  top: 28px;
  height: 24px; /*82px;*/
  width: 70%;
  /*border: 1px solid blue;   */
}
.headerCenterBot {
  position: absolute;
  z-index: 10;
  left: 2px;
  top: 50px;
  height: 25px; /*82px;*/
  width: 100%;
  /*border: 1px solid blue;  */
}
.headerMiddleLeft {
  position: absolute;
  left: 90px;
  top: 50px;
  height: 28px; /*82px;*/
  width: calc(100% - 0px);
  /*border: 3px solid blue;  */
}
.landingInformation { /*This includes store name and down */
  position: absolute;
  top: var(--header-land-info);
  width: 100%;
  /*border: 3px solid black;  */
}
/***** Order Page *****/
.headerOrderCenter {
  position: absolute;
  right: 2px;
  top: 2px;
  height: 50px; /*82px;*/
  width: 180px;
  /*width: 280px;*/
  /*border: 2px solid green; */
}
.headerCenterR2 {
  position: absolute;
  top: 0px;
  right: 53px;
  width: 40px;
  height: 30px;
  /*border: 3px solid red;  */
}
.headerCenterMenu {
  position: absolute;
  top: 0px;
  right: 3px;
  height: 30px;
  /*border: 3px solid red;  */
}
.headerOrderCenterTop {
  position: absolute;
  left: 2px;
  height: 24px; /*82px;*/
  width: 82%;
  /*border: 1px solid blue;   */
}
.headerOrderCenterMid {
  position: absolute;
  left: 5px;
  top: 28px;
  height: 24px; /*82px;*/
  width: 70%;
  /*border: 1px solid red;   */
}
.headerCenterMidH {
  position: absolute;
  left: 5px;
  top: 22px;
  height: 24px; /*82px;*/
  width: 70%;
  /*border: 1px solid blue;   */
}
/*------ Order Header Middle --------------------------------------------------------*/
.headerMiddle {
  position: absolute;
  left: 0;
  top: 45px;
  height: calc(var(--header-spec-ht)+var(--header-disp-ht)+var(--header-msg-ht)); /*82px;*/
  width: 100%;
  /* border: 1px solid red;  */
}
.headerLocation {
  position: absolute;
  left: 6%;
  width: 94%;
  height: 60px;
}
.headerSpecial {
  position: absolute;
  top: 0px;
  height: var(--header-spec-ht);
  left: 0;
  width: 100%;
}
.headerStoreInfo {
  position: absolute;
  top: var(--header-spec-ht);
  height: var(--header-disp-ht);
  left: 0;
  width: 100%;
}
.headerMessage {
  position: absolute;
  color: black;
  background-color: white;
  /*border: 3px solid grey;*/
  border-radius: 10px;
  top: calc(var(--header-spec-ht) + var(--header-disp-ht));
  height: var(--header-msg-ht);
  left: 0;
  width: 100%;
}
.headerMiddleTop {
  position: absolute;
  left: 100px;
  top: 20px;
  height: 22px; /*82px;*/
  width: 70%;
  /*border: 1px solid blue;   */
}
.headerBottom {
  position: absolute;
  left: 0;
  top: calc(50px + var(--header-spec-ht) + var(--header-disp-ht) + var(--header-msg-ht));
  height: 72px; /*82px;*/
  width: 100%;
  /* border: 1px solid green;  */
}
.headerMiddleBot {
  position: absolute;
  left: 100px;
  top: 45px;
  height: 22px; /*82px;*/
  width: 70%;
  /*border: 1px solid red;   */
}
.orderTypeDiv {
  position: absolute;
  top: 3px;
  left: 110px;
  /* border: 1px solid blue; */
}
.orderDiv {
  position: absolute;
  top: 2px;
  left: 0px;
  /* border: 1px solid blue; */
}
.headerBottomSelect {
  position: absolute;
  left: 0px;
  bottom: 1px;
  height: 29px; /*82px;*/
  width: 100%;
  /*border: 1px solid red;   */
}
/* Note - for Horizontal Scrolling Menu - see below */
#headerTypeButton {
  cursor: pointer;
  font-size: medium;
  color: var(--ButtonTextColor);
  background-color: var(--ButtonBGColor);
  font-weight: bold;
  border-radius: 5px;
  height: 20px;
}
#orderTypeButton {
  cursor: pointer;
  font-size: medium;
  color: black;
  color: var(--ButtonTextColor);
  background-color: var(--ButtonBGColor);
  font-weight: bold;
  border-radius: 5px;
  height: 20px;
}
.seatsDiv {
  position: absolute;
  top: 2px;
  left: 0;
  /* border: 1px solid blue; */
}
#showSeatsButton {
  cursor: pointer;
  font-size: medium;
  color: black;
  color: var(--ButtonTextColor);
  background-color: var(--ButtonBGColor);
  font-weight: bold;
  border-radius: 5px;
  left: 0;
  top: 0;
  height: 23px;
  padding: 5px 3px 5px 5px;
}
.headerLeftScan {
  position: absolute;
  left: 10%;
  width: 74%;
  left: 16%;
  top: 49px;
  height: var(--scan-height); /*82px;*/
  /* border: 1px solid blue; */
}
.headerMiddleScan {
  position: absolute;
  left: 26%;
  top: 59px;
  height: var(--scan-height); /*82px;*/
  width: 74%;
  /* border: 1px solid blue; */
}
.headerRight {
  position: absolute;
  right: 0px;
  top: 0;
  width: 180px;
  height: 82px;
  /*border: 3px solid purple; */
}
/**/
.headerTopImage {
  position: absolute;
  left: 1%;
  top: 0;
  width: 97%;
  border: none; /* 1px solid blue; */
}
.headerPicLeft {
  position: absolute;
  top: 313px;
  left: 1%;
  width: 47%;
  border: none; /* 1px solid blue; */
  /*overflow: hidden auto;*/
  /*white-space: nowrap;*/
}
.headerPicRight {
  position: absolute; 
  top: 313px;
  left: 51%;  
  width: 47%;
  /*border: 3px solid purple; */
}
/*---------------------------------------------------------------*/
/* Scanner Specific Classes */
.headerScanner {
  position: fixed;
  width: 100%;
  height: var(--HeadingHeight); /*160px; calc(200 + var(--header-spec-ht) + var(--header-disp-ht) + var(--header-msg-ht)); */
  max-width: 750px;
  min-width: 350px;
  z-index: 4;
  background-color: var(--BrandColor);
  color: var(--LandingTextColor);
  /* border: 3px solid red;  */
}
.headerScanCenter {
  position: absolute;
  right: 2px;
  top: 2px;
  height: 40px; /*82px;*/
  width: 180px;
  /*width: 280px;*/
  /*border: 2px solid green; */
}
.headerCenterR3 {
  position: absolute;
  top: 0px;
  right: 105px;
  width: 40px;
  height: 30px;
  /*border: 3px solid red;  */
}
.headerSCNLeftScan {
  position: absolute;
  /*left: calc((var(--screen-width) - var(--scanWD)) / 2px);*/
  left: calc((var(--screen-width) - var(--scanWD)) / 3);
  width: var(--scanWD);
  top: 40px;
  height: var(--scan-height); /*82px;*/
  /*border: 1px solid red; */
}
.headerSCNButton {
  /*border: 1px solid red;*/
  position: absolute;
  height: 30px;
  width: 120px;
  left: 25%;
  bottom: -46px; /* Below the Scanner*/
}
.scanFooterRight {
  /*border: 2px solid red;*/
  position: absolute;
  color: white; /*var(--LandingTextColor);*/
  height: 24px;
  width: 20px;
  right: 0px;
  top: 2px;
}
.scanFooterLeftBot {
  /*border: 2px solid green; */
  position: absolute;
  color: var(--LandingTextColor);
  bottom: 8px;
  height: 30px;
  width: 150px;
  font-family: var(--HeadFontType);
  font-size: calc(var(--HeadFontSize)-2px);
  left: 30%;
}
/*------------- HEADER -------------*/
/* 15Sep23 - This need to be checked and removed if not used */
#headerTopLeft {
  border: none;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 35%;
  height: 57px;
}
#headerBotLeft {
  border: none;
  position: absolute;
  left: 0px;
  top: 58px;
  width: 50%;
  height: 35px;
}
.buttonDivLeft {
  border: none; /*2px solid green; */
  position: absolute;
  left: 20px;
  width: 50px;
  height: 28px;
  bottom: 0px;
}
.buttonDivRight {
  border: none; /*2px solid red; */
  position: absolute;
  width: 50px;
  height: 28px;
  bottom: 0px;
  right: 20px;
  float: right;
}
#headerBotLeft h2 {
  top: 2px;
  padding-top: 2px;
  padding-left: 100px; /* left from headerBotLeft edge */
  font-size: 24px;
  color: #fff;
  font-weight: normal;
  float: left;
}
#headerTopMid {
  border: none;
  position: relative;
  left: 35%;
  top: 0px;
  width: 34%;
  max-width: 700px;
  height: 57px;
}
#headerTopRight {
  border: none;
  position: absolute;
  right: 0px;
  top: 0px;
  width: 31%;
  height: 57px;
}
#headerBotRight {
  border: none; /*1px solid red;*/
  position: absolute;
  right: 0px;
  top: 58px;
  width: 40%;
  height: 35px;
}
#headerBotStrip {
  border: none;
  position: absolute;
  left: 0;
  bottom: -4px;
  width: 100%;
  height: 13px;
  background-color: url(images/strip_bg.jpg) top left repeat;
}
.bottomButton {
  border: none; /*solid 1px red; */
  position: absolute;
  bottom: 12px;
  left: 20px;
  background-color: white;
}
.bottomButtonW {
  /*border: solid 1px red; */
  position: absolute;
  bottom: 7px;
  width: 290px;
  left: 2px;
  background-color: white;
}
.topRightCorner {
  /*border: solid 1px red; */
  position: absolute;
  top: -28px;
  right: -1px;
}
/************************************/
/*--- Search ---*/
.headerOrderCenterBot {
  position: absolute;
  z-index: 10;
  left: 14px;
  bottom: 35px;
  height: 36px; /*82px;*/
  width: 60%;
  /*border: 1px solid red;  */
}
#headerSearch {
  position: absolute;
  top: 0px;
  width: 250px;
  height: 30px;
  border: 2px solid #787878;
  /*border-color: var(--InputBorderColor);*/
  border-radius: 15px;
  background-color: white;
}         
#searchBtnDiv { /* search button clear */  
  border: none;
  position: absolute;
  left: 1px;
  top: 4px;
  width: 10px;
  height: 15px;
}
#clearSearchBtnDiv { /* search button */
  border: none;
  position: absolute;
  right: 11px;
  bottom: 9px;
  width: 10px;
  height: 15px;
}  
#searchBoxDiv { /* search text */
  /*border: 2px solid green;  */
  position: absolute;
  left: 24px;
  top: -22px;
  width: 214px;
  height: 34px;
}
.searchInput {
  border: none;
  top: 4px;
  padding-left: 1px;
  color: var(--InputTextColor);
  background-color: white; /*var(--InputBGColor);*/
  font-family: var(--InputFontType);
  font-size: 18px; /*var(--InputFontSize);*/
  width: 200px;
  height: 24px;
}
#headerSearchLoc {
  /* border: 1px solid green;  */
  position: absolute;
  right: 5px;
  top: 20px;
  width: 180px;
  height: 22px;
}
#clearSeatNameBtnDiv { /* SeatName button */
  border: none;
  position: absolute;
  left: -22px;
  bottom: 9px;
  width: 10px;
  height: 15px;
}
#seatsExitBtnDiv { /* search button clear */
  /*border: 1px solid red;*/
  position: absolute;
  right: 12px;
  top: 6px;
  width: 10px;
  height: 15px;
}
.react-horizontal-scrolling-menu--wrapper {
  min-height: 30px;
  height: 30px;
  width: 100%;
  clear: both;
  /*  text-align: center;
  margin-top: 5px;*/
  border: 1px solid blue; /*  */
}
.react-horizontal-scrolling-menu--scroll-container {
  min-height: 30px;
  height: 30px;
  width: 100%;
  clear: both;
  /*  text-align: center;
  margin-top: 5px;*/
  /*border: 1px solid green;   */
}
.horizmenu {
  margin-top: 5px;
  height: 40px;
  width: 100%;
  clear: both;
  /*  text-align: center;
  margin-top: 5px;*/
  /*border: 1px solid red;   */
}
.chipScroll {
  height: 22px;
  width: 30px;
  cursor: pointer;
  font-size: large;
  top: -2px;
  color: blue; 
  font-weight: bold;
  border-radius: 5px;
  /*border: 1px solid blue;   */
}
.catChipList {
  display: inline-block;
  width: calc(100% - 80px);
  height: 24px;
  margin-top: 4px;
  margin-left: 8px;
  margin-right: 3px;
  align-content: center;
  /* border: 1px solid purple;  */
}
#chipList {
  font-size: large;
  color: black;
  font-weight: bold;
  width: 100%;
  height: 22px;
  margin-top: 1px;
}
/*---------- ListItems --------------------------------------------------------------------------------------------------------------------------------------------*/
.SLQuickItems {
  /*border: solid 3px green; */
  display: block;
  /*position: absolute;  Stops scroll */
  margin-top: 146px; /* Push top of list down */
  height: calc(var(--itemDetailPopupHt) - 162px); /**/
  background-color: white;
}
.SLItemSearch {
  /*border: solid 3px green; */
  display: block;
  /*position: absolute;  Stops scroll */
  margin-top: 350px; /* Push top of list down */
  height: calc(var(--itemDetailPopupHt) - 418px); /**/
  background-color: white;
}
.scanList {
  /*border: solid 3px green; */
  display: block;
  /*position: absolute;  Stops scroll */
  margin-top: 230px; /* Push top of list down */
  height: calc(var(--itemDetailPopupHt) - 418px); /**/
  background-color: white;
}
.orderListBG {
  /*border: solid 3px green; */
  display: block;
  /*position: absolute;  Stops scroll */
  margin-top: 212px; /* Push top of list down */
  height: calc(var(--itemDetailPopupHt) - 418px); /**/
  background-color: white;
}
.aboutList {
  border: none; /*solid 3px green; */
  position: absolute;
  top: 90px; /* 110px;*/
  left: 0px;
  right: 0px;
  background-color: khaki;
}
.profileEdit {
  /*border: solid 3px blue; 
  border-radius: 15px;*/
  position: absolute;
  top: 50px; /* 110px;*/
  height: 1295px;
  left: 0;
  right: 0;
  background-color: white;
}
.contactEdit {
  /*border: solid 2px blue; 
  border-radius: 10px; */
  position: absolute;
  top: 120px;
  font-size: 14px;
  color: black;
  margin-left: 5px;
  padding-left: 5px;
  width: 96%;
  height: 510px;
  background-color: white;
}
.framePic {
  border: solid 3px blue;
  border-radius: 15px;
  position: relative;
  left: 40px;
  bottom: 20px;
  width: 190px;
  height: 270px;   
  margin: 0;
  overflow: hidden;
  padding: 15px 20px;
  background-color: white;
}
.frameProfile {
  /*border: solid 3px blue; */
  border-radius: 15px;
  position: relative;
  left: -4px;
  bottom: 8px;
  width: 210px;
  background-color: white;
}
.framePicUpload {
  position: relative;
  top: 131px;
  left: -6px;
  width: 200px;
  height: 80px;
  /*  border-radius: 10px;
  border: 3px solid #ccc;*/
  background-color: #eee;
  margin: 0;
  padding: 2px 2px 2px 2px;
}
.contactBox {
  margin: 0;
  width: 97%;
  height: 440px;
  overflow: hidden;
}
/*----------------------------------------------------------------------------------------*/
/* ### PORTFOLIO BOX ### */
.portfolioBox {
  padding-top: 5px;
  width: 556px;
  overflow: hidden;
  background: url(images/cross_bg.jpg) left top no-repeat;
  border-bottom: #333333 solid 1px;
}
  .portfolioBox h2 {
    padding: 10px 0 10px 0;
    font-weight: normal;
    font-family: var(--HeadFontType);
    font-size: 24px;
    color: #fff;
  }
  .portfolioBox p {
    padding-bottom: 10px;
    width: 294px;
    font-size: 12px;
    color: #a7a6a6;
    line-height: 20px;
    float: right;
  }
  .portfolioBox img {
    border: #323232 solid 4px;
    float: left;
  }
  .portfolioBox a {
    margin-top: 10px;
    font-size: 12px;
    width: 80px;
    float: left;
    color: #000;
    display: block;
    line-height: 31px;
    text-decoration: none;
    text-align: center;
    background: url(images/more_button.jpg) left top no-repeat;
  }
    .portfolioBox a:hover {
      background: url(images/more_botton_h.jpg) left top no-repeat;
    }
  .portfolioBox .last {
    border: none;
  }
/*----------------------------------------------------------------------------------------*/
.questionType {
  font-weight: bold;
  height: 26px;
  width: 150px;
  color: var(--InputTextColor);
  background-color: var(--InputBGColor);
  font-family: var(--InputFontType);
  font-size: var(--InputFontSize);
  border-width: 2px;
  border-color: var(--InputBorderColor);
  border-radius: 3px;
}
.listRow {
  overflow: auto;
  clear: both;
  border-radius: 10px;
  border: 2px solid #ccc;
  margin: 3px 0px 3px 3px;
  padding: 3px 0px 3px 3px;
}
.listRowS {
  border: none; /*solid 3px green;  */
  overflow: auto;
  clear: both;
  margin: 1px 0px 1px 0px;
  padding: 1px 3px 1px 3px;
}
.listColA {
  float: left;
  width: calc(100% - 5px);
}
.listCol1 {
  float: left;
  width: calc(100% - 152px);
}
.listColR {
  /*border: solid 3px green;  */
  float: right;
  width: 140px;
  margin-top: 0;
}
.listColRec {
  /*border: solid 3px green;  */
  float: right;
  width: 130px;
  margin-top: 0;
}
.listColR1 {
  float: left;
  width: 15px;
}
.listCol2 {
  /*border: solid 3px red;  */
  float: left;
  width: 56px;
}
.listCol3 {
  float: left;
  width: 1%;
}
.listColR4 {
  float: right;
  padding-right: 2px;
  padding-top: 2px;
  width: 66px;
}
.listCol5 {
  float: left;
  width: 25px;
}
.listColPayM1 {
  float: left;
  width: 300px;
}
.listColPayM2 {
  float: left;
  width: 20%;
}
.listColPayM3 {
  float: left;
  width: 23%;
}
.listColChangeM3 {
  float: right;
  width: 10%;
}
/*------------ Checkout --------------------------------------------------------------*/
.listColCH1 {
  float: left;
  padding-left: 6px;
  width: calc(100% - 126px);
}
.listColCH2 {
  float: left;
  padding-left: 3px;
  width: 86px;
}
.listColCH3 {
  float: left;
  padding-left: 3px;
  width: 25px;
}
.listVertContainer {
  display: flex;
  flex-direction: column; /* Stack children vertically */
  margin-bottom: 8px;
  padding: 4px 1px 1px 1px;
  border-radius: 10px;
  border: 1px ridge lightgrey; /**/
}
.listCHContainer {
  position: relative;
  display: flex;
  /*justify-content: space-between;*/
  justify-content: space-around;
}
.boxCH1 {
  flex: 13; /* Takes up 13n of the container */
  padding: 3px 0 3px 8px;
  color: black;
  font-size: 14px;
  /*border: 1px solid black;*/
}
.boxCH2 {
  display: table;
  flex: 3; /* Takes up 3n of the container */
  /*border: 1px solid red;*/
  line-height: 16px;
  margin-right: 4px;
  font-size: 15px;
  color: black;
  font-weight: bold;
}
.boxCH3 {
  display: block;
  flex: 1; /* Takes up 1n of the container */
  /*border: 1px solid green;*/
  margin-left: auto;
  margin-right: 2px;
}
.boxCH3Icon { /* See function image */
  float: right;
  display: inline-block;
  padding-right: 3px;
  top: 0px;
  width: 23px;
  resize: both;
  height: 23px;
}
.optionCHContainer {
  position: relative;
  display: flex;
  overflow: auto;
  /*justify-content: space-between;*/
  justify-content: space-around;
  margin-left: 5%;
  /*border: 1px solid blue;*/
}
.optionListCH {
  /*border: 1px solid green; */
  margin-top: 3px;
  padding: 2px 4px 1px 10%;
}
.boxCH4Pre {
  flex: 6; /* Takes up 13n of the container */
  padding: 1px;
  /*border: 1px solid black;*/
}
.boxCH4 {
  flex: 6; /* Takes up 13n of the container */
  padding: 1px;
  font-size: 14px;
  color: black;
  /*border: 1px solid black;*/
}
.boxCH5 {
  flex: 8; /* Takes up 13n of the container */
  padding: 1px;
  font-size: 14px;
  color: black;
  /*border: 1px solid black;*/
}
.boxCH6 {
  flex: 1; /* Takes up 13n of the container */
  padding: 1px;
  font-size: 14px;
  color: black;
  /*border: 1px solid black;*/
  margin-left: auto;
  margin-right: 6px;
}
.itemSpecial {
  /*border: 1px solid green; */
  margin-top: 3px;
  padding: 2px 4px 1px 10%;
  font-size: 14px;
  color: black;
  font-weight: bold;
}
.itemSpecialInst {
  padding-left: 10px;
  font-weight: normal;
}
.listOptionHeader {
  /*border: 1px solid black;*/
  position: relative;
  background-color: whitesmoke;
  height: 23px;
  font-size: 14px;
  font-weight: bold;
  color: black;
}
.listOptionFooter {
  /*border: 1px solid black;*/
  /*background-color: whitesmoke;*/
  font-size: 14px;
  color: black;
}
/*--- Combo List - Header and Footer ---*/
.listComboHeader {
  border-radius: 10px;
  border: 3px solid #ccc;
  color: var(--ListHeadTextColor);
  background-color: var(--ListHeadBGColor);
  height: 25px;
  font-family: var(--HeadFontType);
  font-size: var(--HeadFontSize);
  overflow-x: hidden;
}
.listComboHeaderLeft {
  border: none;
  position: relative;
  color: var(--ListHeadTextColor);
  background-color: var(--ListHeadBGColor);
  height: 25px;
  width: 60%;
  font-family: var(--HeadFontType);
  font-size: var(--HeadFontSize);
  float: left;
  left: 1em;
  top: 1px;
}
.listOrderMenuComboML {
  position: absolute;
  /*border: solid 3px green;  */
  width: 70px;
  top: 5px;
  right: 5px;
}
.listComboFooter {
  border-radius: 10px;
  border: 3px solid var(--ButtonBGColor); /**/
  background-color: linen;
  height: 5px;
  font-size: large;
  color: black;
  overflow-x: hidden;
}
/*------ List Orders - Menu Layout ----------------------------------------------*/
.listOrderHeader {
  border-radius: 10px;
  border: 3px solid #ccc;
  background-color: var(--ListHeadBGColor);
  color: var(--ListHeadTextColor);
  font-family: var(--HeadFontType);
  font-size: var(--HeadFontSize);
  height: 59px;
  font-size: medium;
  overflow-x: hidden;
}

.listOrderHeaderTotal {
  font-size: var(--HeadFontNameSize);
}
.listOrderHeaderMoney {
  font-size: var(--HeadFontNameSize);
}
.listOrderHeaderCount {
  font-size: var(--HeadFontSize);
}
.listOrderHeaderQuantity {
  font-size: var(--HeadFontSize);
}
/**/
/*------ List Orders - Category Layout - Currently the Same for all ----------------------------------------------*/
/* NOTE! - Clean up before copying to for specific Layouts */
.listOrderCategory {
  /*border: 2px solid red;   */
  font-size: 18px;
  font-weight: bold;
  color: var(--ListSectionTextColor);
  background-color: var(--ListSectionBGColor);
  font-family: var(--SectionFontType);
  font-size: var(--SectionFontSize);
  margin: 2px 1px 2px 1px;
  padding: 0px 1px 0px 1px;
  height: var(--category-height);
  width: 100%;
  position: relative;
}
.listOMCatL {
  float: left;
  width: 110px;
  color: var(--ListSectionTextColor);
  background-color: var(--ListSectionBGColor);
  font-family: var(--SectionFontType);
  font-size: var(--SectionFontSize);
}
.listOMCatNIL {
  float: left;
  width: 15px;
  color: var(--ListSectionTextColor);
  background-color: var(--ListSectionBGColor);
  font-family: var(--SectionFontType);
  font-size: var(--SectionFontSize);
}
.listOMCatTitle {
  /*border: 2px solid green;   */
  color: var(--ListSectionTextColor);
  background-color: var(--ListSectionBGColor);
  font-family: var(--SectionFontType);
  font-size: var(--SectionFontHeadSize);
}
.listOMCatR {
  /*border: 2px solid green;   */
  position: relative;
  float: left;
  width: calc(100% - 152px);
  color: var(--ListSectionTextColor);
  background-color: var(--ListSectionBGColor);
  font-family: var(--SectionFontType);
  font-size: var(--SectionFontSize);
}
.listOMCatRight {
  right: 10px;
  position: absolute;
  width: 27px;
  color: var(--ListSectionTextColor);
  background-color: var(--ListSectionBGColor);
  font-family: var(--SectionFontType);
  font-size: var(--SectionFontSize);
}
.cartImage {
  right: 2px;
  top: 2px;
  width: 34px;
  resize: both;
  height: 34px;
  cursor: pointer;
}
.cartImageCircle {
  right: 1px;
  top: 1px;
  width: 22px;
  resize: both;
  height: 22px;
  z-index: 5;
}
/*------ Generic - Menu Layout - Generic Menu - should be made specific to a format or deleted ----------------------------------------------*/
/* NOTE! - Clean up before copying to for specific Layouts */
.listMenuCircle {
  position: absolute;
  /*border: solid 3px green;  */
  width: 80px;
  top: 40px;
  right: 25px;
}
.functionImage {
  display: inline-block;
  padding-left: 0px;
  top: 0px;
  width: 25px;
  resize: both;
  height: 25px;
}
.functionImageL {
  right: 2px;
  top: 2px;
  width: 34px;
  resize: both;
  height: 34px;
}
.functionImageR {
  position: relative;
  display: inline-block;
  top: 5px;
  left: 10px;
  width: 25px;
  resize: both;
  height: 25px;
}
/*------ LIST Order Footer ------*/
/*.listOrderFooter {
  border-radius: 10px;
  border: 2px solid #ccc;    
  color: var(--ListHeadTextColor);
  background-color: var(--ListHeadBGColor);
  height: 89px;
  font-family: var(--HeadFontType);
  font-size: var(--HeadFontSize);
  overflow-x: hidden;
}*/
.listOrderFooter {
  height: 79px;
}
.listName {
  /*  border: 2px solid green;*/
  font-size: large;
  position: absolute;
  left: 10px;
}
.listMoneyTot {
  /*  border: 2px solid green;*/
  font-size: large;
  position: absolute;
  font-family: var(--HeadFontType);
  /*font-size: var(--HeadFontSize);*/
  font-size: 20px;
  font-weight: bold;
  color: var(--LandingTextColor);
  left: 142px;
}     
.listFooterLeft {
  /*border: 2px solid green; */
  position: relative;
  color: var(--LandingTextColor);
  height: 80px;
  width: 196px;
  font-family: var(--HeadFontType);
  font-size: var(--HeadFontSize);
  float: left;
  left: 0px;
  top: 2px;
}     
.listFooterRight {
  /*border: 2px solid red;*/
  position: relative;
  color: var(--LandingTextColor);
  height: 60px;
  width: 206px;
  font-family: var(--HeadFontType);
  font-size: var(--HeadFontSize);
  float: right;
  top: 2px;
}
.orderFooterLeftTop {
  /*border: 2px solid green; */
  position: absolute;
  color: var(--LandingTextColor);
  top: 5px;
  height: 25px;
  width: 80%;
  font-family: var(--HeadFontType);
  font-size: var(--HeadFontSize);
  left: 15px;
}
.orderMsg {
  position: absolute;
  font-size: large;
  top: -4px;
  overflow: hidden auto; /* prevent wrap! */
  white-space: nowrap; /* prevent wrap! */
}
.orderFooterLeftBot {
  /*border: 2px solid green; */
  position: absolute;
  color: var(--LandingTextColor);
  bottom: 10px;
  height: 30px;
  width: 166px;
  font-family: var(--HeadFontType);
  font-size: var(--HeadFontSize);
  float: left;
  left: 0px;
}
.orderFooterRight {
  /*border: 2px solid red;*/
  position: relative;
  color: var(--LandingTextColor);
  height: 60px;
  width: 156px;
  font-family: var(--HeadFontType);
  font-size: var(--HeadFontSize);
  float: right;
  top: 2px;
}
/*------ Order Footer ------*/
.footerCenterR2 {
  position: absolute;
  bottom: 0px;
  right: 57px;
  height: 30px;
  /*border: 3px solid red;  */
}   
.listMoney {
/*  border: 2px solid green;*/ 
  font-size: large;
  color: black;
  position: absolute;
  right: 30px;
}
.listFooter2 {
  border: none;
  background-color: white;
  height: 100px;
  font-size: large;
  color: #6080DD;
  overflow-x: hidden;
}
.listFooter2a {
  border: none;
  height: 120px;
  font-size: large;
  overflow-x: hidden;
}
.checkoutTotLeft2a {
  border: none;
  position: relative;
  height: 80px;
  width: 22%;
  font-size: 16px;
  font-weight: bold;
  float: left;
  left: 10px;
  top: 5px;
}
.checkoutTotLeft2b {
  border: none;
  position: relative;
  height: 80px;
  width: 22%;
  font-size: 18px;
  font-weight: bold;
  float: left;
  left: 10px;
  top: 5px;
}
.checkoutTotRight2a {
  border: none; /*2px solid red;*/
  position: relative;
  height: 80px;
  width: 34%;
  max-width: 200px;
  font-size: medium;
  font-weight: bold;
  float: left;
  top: 5px;
}
.checkoutTotRight2b {
  border: none; /*2px solid red;*/
  position: relative;
  height: 80px;
  width: 22%;
  font-size: 18px;
  font-weight: bold;
  float: right;
  right: 1px;
  top: 5px;
}
.footerBottom {
  position: relative;
  background-color: #6080DD;
  height: 60px;
  width: 100%;
  font-size: large;
  color: #6080DD;
  top: 62px;
}
.listFooter3 {
  border: none;
  background-color: white;
  height: 40px;
  font-size: large;
  color: #6080DD;
  overflow-x: hidden;
}
/*------------------------------------------------------------------------------------*/
.listShoppingCart {
  /*border: solid 3px green;  */
  position: absolute;
  cursor: pointer;
  top: 10px;
  right: -2px;
  width: 30px;
  resize: both;
  height: 30px;
  color: var(--ButtonTextColor);
  background-color: var(--ButtonBGColor);
  padding: 3px 6px 3px 3px;
  font-family: var(--ButtonFontType);
  font-size: large;
  font-weight: bold;
  border-radius: 10px;
  border: 3px solid var(--ButtonTextColor);
}
.addChange {
  /* border: solid 3px green; */
  top: 1px;
  width: 50px;
  width: 25px;
  resize: both;
  height: 25px;
}
.listCartAdd {
  width: 35px;
  resize: both;
  height: 35px;
}
/*------ Scanner Item -----------*/
.listScanRow {
  position: relative;
  overflow: auto; /*clip;*/
  clear: both;
  /*border: solid 1px green;  */
  background-color: var(--ListBGColor);
  border-radius: 10px;
  box-shadow: 3px 3px 3px var(--DropShadowColor);
  /*border: 2px solid #ccc; */
  margin: 6px 6px 6px 4px;
  padding: 0 0 6px 3px;
  min-height: 64px;
}      
.listScanRowName {
  position: relative;
  overflow: auto; /*clip;*/
  clear: both;
  /*border: solid 1px green;  */
  background-color: var(--ListBGColor);
  border-radius: 10px;
  box-shadow: 3px 3px 3px var(--DropShadowColor);
  border: 1px solid purple; /**/
  margin: 6px 6px 6px 4px;
  padding: 0 0 6px 3px;
  min-height: 40px;
}
.listSLScanRow {
  position: relative;
  overflow: auto; /*clip;*/
  clear: both;
  border: solid 1px black; /* */
  background-color: var(--ListBGColor);
  border-radius: 10px;
  box-shadow: 3px 3px 3px var(--DropShadowColor);
  /*border: 2px solid #ccc; */
  margin: 6px 6px 6px 4px;
  padding: 0 0 6px 3px;
  min-height: 97px;
}
.listSLScanRow37 {
  position: relative;
  overflow: auto; /*clip;*/
  clear: both;
  border: solid 1px black; /* */
  background-color: var(--ListBGColor);
  border-radius: 10px;
  box-shadow: 3px 3px 3px var(--DropShadowColor);
  /*border: 2px solid #ccc; */
  margin: 6px 6px 6px 4px;
  padding: 0 0 6px 3px;
  min-height: 37px;
}
.listSLScanRow57 {
  position: relative;
  overflow: auto; /*clip;*/
  clear: both;
  border: solid 1px black; /* */
  background-color: var(--ListBGColor);
  border-radius: 10px;
  box-shadow: 3px 3px 3px var(--DropShadowColor);
  /*border: 2px solid #ccc; */
  margin: 6px 6px 6px 4px;
  padding: 0 0 6px 3px;
  min-height: 61px;
}
.listScanText {
  /*border: solid 1px red;  */
  position: relative;
  overflow: auto;
  top: 0;
  left: 0;
  width: calc(100% - 162px);
}
.listScanItemName {
  padding: 0 0 0 4px;
  word-wrap: break-word;
  text-align: left;
  color: var(--ItemNameColor);
  font-family: var(--ItemNameFontType);
  font-size: var(--ItemNameFontSize);
  font-weight: var(--ItemNameFontWht);
}
.listScanItemDesc {
  padding: 0 0 0 4px;
  overflow-wrap: break-word;
  color: var(--ItemDescColor);
  font-family: var(--ItemDescFontType);
  font-size: var(--ItemDescFontSize);
  font-weight: var(--ItemDescFontWht);
}
.listScanCount {
  /*border: solid 1px green;  */
  position: absolute;
  top: 17px;
  left: 4px;
  width: 70px;
  font-family: var(--ItemBuyFontType);
  font-size: 14px;
  font-weight: normal;
  color: var(--ItemTotalColor);
}
.listScanCountB {
  /*border: solid 1px green;  */
  position: absolute;
  top: 17px;
  left: 4px;
  width: 70px;
  font-family: var(--ItemBuyFontType);
  font-size: 18px;
  font-weight: bold;
  color: var(--ItemTotalColor);
}
.listScanWht {
  /*border: solid 1px green;  */
  position: absolute;
  top: 17px;
  left: -4px;
  width: 100px;
  font-family: var(--ItemBuyFontType);
  font-size: 14px;
  font-weight: normal;
  color: var(--ItemTotalColor);
}
.listScanTotal {
  /* border: solid 1px green; */
  position: absolute;
  top: 31px;
  left: 0px;
  font-family: var(--ItemBuyFontType);
  font-size: var(--ItemBuyFontSize);
  font-weight: var(--ItemBuyFontWht);
  color: var(--ItemTotalColor);
}
.listScanBuyDiv {
  position: absolute;
  /*border: solid 1px green;  */
  width: 165px;
  top: 0px;
  right: 0px;
  color: var(--ItemBuyColor);
  font-family: var(--ItemBuyFontType);
  font-size: var(--ItemBuyFontSize);
  font-weight: var(--ItemBuyFontWht);
}
.listScanPriceTotal {
  position: absolute;
  /*border: solid 1px red;  */
  height: 84px;
  right: 97px;
  width: 56px;
}
.listScanEditDelete {
  /*border: solid 1px black;  */
  position: absolute;
  display: inline-block;
  top: 0px;
  right: 2px;
  height: 67px;
  width: 35px;
}
.listScanEditCartName {
  /*border: solid 1px red;  */
  position: absolute;
  display: inline-block;
  top: 18px;
  right: 0;
  height: 25px;
  width: 75px;
}
.listScanBtn0 {
  /*border: solid 1px green;  */
  /*position: absolute;*/
  width: 25px;
  resize: both;
  height: 25px;
}
.listScanBtn1 {
  /*border: solid 1px green;  */
  position: absolute;
  top: 35px;
  right: 2px;
  width: 25px;
  resize: both;
  height: 25px;
}
.listScanBtn2 {
  /*border: solid 1px red;  */
  position: absolute;
  top: 5px;
  right: -4px;
  width: 29px;
  resize: both;
  height: 29px;
}
.listScanBtn3 {
  /*border: solid 1px green;  */
  position: absolute;
  top: 40px;
  right: 3px;
  width: 27px;
  resize: both;
  height: 27px;
}
.listScanImage {
  /*border: solid 3px green;  */
  float: right;
  padding-top: -2px;
  margin-right: 0px;
  padding-bottom: -2px;
  width: 84px;
}
/*------ List Shopping List Items -------------------------------------------------------------------*/
.listSLBuyDiv {
  position: absolute;
  /*border: solid 1px green;  */
  width: 100px;
  height: 60px;
  top: 0px;
  right: 0px;
  color: var(--ItemBuyColor);
  font-family: var(--ItemBuyFontType);
  font-size: var(--ItemBuyFontSize);
  font-weight: var(--ItemBuyFontWht);
}
.listSLSText {
  /*border: solid 1px red;  */
  position: relative;
  overflow: auto;
  top: 0;
  left: 0;
  width: calc(100% - 102px);
}
.listSLEditCart {
  /*border: solid 1px red;  */
  position: absolute;
  display: inline-block;
  top: 48px;
  right: 30px;
  height: 40px;
  width: 75px;
}
/*------ List Orders - Menu Layout1 ----------------------------------------------*/
.listOrderRowML1 {
  position: relative;
  overflow: auto; /*clip;*/
  clear: both;
  /*border: solid 1px green;  */
  background-color: var(--ListBGColor);
  border-radius: 10px;
  box-shadow: 3px 3px 3px var(--DropShadowColor);
  /*border: 2px solid #ccc; */
  margin: 6px 6px 6px 4px;
  padding: 0 0 6px 3px;
  min-height: 87px;
}
.listOMTextDiv1 {
  /*border: solid 1px red;  */
  position: relative;
  overflow: auto;
  top: 0;
  left: 0;
  width: calc(100% - 162px);
}
.listOMItemNameML1 {
  padding: 0 0 0 4px;
  word-wrap: break-word;
  text-align: left;
  color: var(--ItemNameColor);
  font-family: var(--ItemNameFontType);
  font-size: var(--ItemNameFontSize);
  font-weight: var(--ItemNameFontWht);
}
.listOMItemDescML1 {
  padding: 0 0 0 4px;
  overflow-wrap: break-word;
  color: var(--ItemDescColor);
  font-family: var(--ItemDescFontType);
  font-size: var(--ItemDescFontSize);
  font-weight: var(--ItemDescFontWht);
}
.listOMBuyDiv1 {
  position: absolute;
  /*border: solid 1px green;  */
  width: 165px;
  top: 3px;
  right: 0px;
  color: var(--ItemBuyColor);
  font-family: var(--ItemBuyFontType);
  font-size: var(--ItemBuyFontSize);
  font-weight: var(--ItemBuyFontWht);
}
.listOMPriceTotalML1 {
  position: absolute;
  /*border: solid 1px red;  */
  height: 84px;
  right: 97px;
  width: 56px;
}
.listOMCountML1 {
  /*border: solid 1px green;  */
  position: absolute;
  top: 29px;
  right: 1px;
  width: 70px;
  font-family: var(--ItemBuyFontType);
  font-size: 14px;
  font-weight: normal;
  color: var(--ItemTotalColor);
}
.listOMTotalML1 {
  /* border: solid 1px green; */
  position: absolute;
  top: 29px;
  right: 1px;
  font-family: var(--ItemBuyFontType);
  font-size: var(--ItemBuyFontSize);
  font-weight: var(--ItemBuyFontWht);
  color: var(--ItemTotalColor);
}
.listOMQuickBuy1 {
  /*border: solid 3px green;  */
  position: absolute;
  top: 23px;
  right: 10px;
  width: 25px;
  resize: both;
  height: 25px;
}
.listOMCartPromoML1 {
  /*border: solid 1px red;  */
  position: absolute;
  display: inline-block;
  top: 48px;
  right: 0;
  height: 40px;
  width: 65px;
}
.listOMCartML1 {
  /*border: solid 1px green;  */
  position: absolute;
  top: 5px;
  right: 35px;
  width: 25px;
  resize: both;
  height: 25px;
}
.listOMCartML1Add {
  /*border: solid 1px green;  */
  position: absolute;
  top: 5px;
  right: -2px;
  width: 25px;
  resize: both;
  height: 25px;
}
.listOMPromoML1 {
  /*border: solid 3px green;  */
  position: absolute;
  top: 0px;
  right: 1px;
  width: 25px;
  resize: both;
  height: 25px;
} 
.listOMImageDiv1 {
  /*border: solid 3px green;  */
  float: right;
  padding-top: -2px;
  margin-right: 0px;
  padding-bottom: -2px;
  width: 84px;
}
.avatarOrderPic {
  height: 84px;
  width: 84px;
  background-color: var(--GridBGColor);
  border-radius: 10px;
}
.listOrderMenuColImage {
  height: 84px;
  width: 84px;
}
/*------ List Orders - Menu Layout2 ----------------------------------------------*/
/*------ Menu Layout 2 - Category Layout - Currently the Same for all ----------------------------------------------*/
.listOrderCategoryML2 {
  /*border: 2px solid red;   */
  color: var(--ListSectionTextColor);
  background-color: var(--ListSectionBGColor);
  font-family: var(--SectionFontType);
  font-size: var(--SectionFontSize);
  font-weight: bold;
  margin: 2px 1px 2px 1px;
  padding: 0px 1px 0px 1px;
  height: var(--category-height);
  width: 100%;
  position: relative;
}
.listOMCatLML2 {
  /*border: 2px solid purple;   */
  position: absolute;
  left: 5px;
  height: 74px;
  width: 110px;
  color: var(--ListSectionTextColor);
  background-color: var(--ListSectionBGColor);
  font-family: var(--SectionFontType);
  font-size: var(--SectionFontSize);
}
.listOMCatRML2 {
  /*border: 2px solid green;   */
  position: absolute;
  left: 125px;
  width: calc(100% - 182px);
  color: var(--ListSectionTextColor);
  background-color: var(--ListSectionBGColor);
  font-family: var(--SectionFontType);
  font-size: var(--SectionFontSize);
}
.listOMCatRightML2 {
  position: absolute;
  right: 15px;
  top: 15px;
  width: 27px;
  color: var(--ListSectionTextColor);
  background-color: var(--ListSectionBGColor);
  font-family: var(--SectionFontType);
  font-size: var(--SectionFontSize);
}          
/*** ML2 ***/
.listOrderRowML2 {
  /*border: 2px solid blue; */
  background-color: var(--ListBGColor);
  position: relative;
  overflow: clip;
  clear: both;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin: 4px 4px 5px 4px;
  padding: 0 0 6px 55px;
  min-height: 150px;
  height: 158px;
}
.listOMTextDiv2 {
  /*position: absolute; prevents height adjustment */
  /*border: solid 2px red;  */ 
  top: 0;
  margin: 0 0 5px 13px;
  width: calc(100% - 165px);
}       
.listOMItemNameML2 {
  padding: 0 0 0 3px;
  word-wrap: break-word;
  text-align: left;
  color: var(--ItemNameColor);
  font-family: var(--ItemNameFontType);
  font-size: var(--ItemNameFontSize);
  font-weight: var(--ItemNameFontWht);
}
.listOMItemDescML2 {
  padding: 0 0 0 5px;
  overflow-wrap: break-word;
  color: var(--ItemDescColor);
  font-family: var(--ItemDescFontType);
  font-size: var(--ItemDescFontSize);
  font-weight: var(--ItemDescFontWht);
}
.listOMFullDescML2 {
  font-size: 14px;
  color: black;
  padding: 10px 3px 3px 2px;
}
.listOMBuyDiv2 {
  position: absolute;
  /*border: solid 3px green;  */
  left: 3px;
  width: 218px;
  height: 147px;
  margin-top: 0;
  top: 5px;
  color: var(--ItemBuyColor);
  font-family: var(--ItemBuyFontType);
  font-size: var(--ItemBuyFontSize);
  font-weight: var(--ItemBuyFontWht);
}
.listOMPriceTotalML2 {
  position: absolute;
  /*border: solid 1px red;  */
  left: 1px;
  width: 56px;
  height: 50px;
}
.listOMCountML2 {
  /*border: solid 1px green;  */
  position: absolute;
  top: 17px;
  left: 4px;
  width: 70px;
  font-family: var(--ItemBuyFontType);
  font-size: 14px;
  font-weight: normal;
  color: var(--ItemTotalColor);
}
.listOMTotalML2 {
  /* border: solid 1px green; */
  position: absolute; 
  top: 31px;
  left: 0px;
  font-family: var(--ItemBuyFontType);
  font-size: var(--ItemBuyFontSize);
  font-weight: var(--ItemBuyFontWht);
  color: var(--ItemTotalColor);
}
.listOMQuickBuy2 {
  /*border: solid 3px green;  */
  position: absolute;
  top: 23px;
  left: 10px;
  width: 25px;
  resize: both;
  height: 25px;
}
.listOMCartPromoML2 {
  /*border: solid 3px red;  */
  position: absolute;
  display: inline-block;
  top: 68px;
  left: 5px;
  width: 70px;
}
.listOMImageDiv2 { /* Image Box - Right Side */
  position: absolute;
  right: 0px;
  width: 148px;
  height: 148px;
  top: 0;
}
.listOMCartML2 {
  /*border: solid 3px green;  */
  position: absolute;
  top: -5px;
  right: 11px;
  width: 25px;
  resize: both;
  height: 25px;
}
.listOMCartML2Add {
  /*border: solid 3px green;  */
  position: absolute;
  top: -5px;
  left: -2px;
  width: 25px;
  resize: both;
  height: 25px;
}
.listOMPromoML2 {
  /*border: solid 3px purple;  */
  position: absolute;
  top: 30px;
  left: 15px;
  width: 25px;
  resize: both;
  height: 25px;
}
.largeSqButton {
  cursor: pointer;
  color: var(--ButtonTextColor);
  background-color: var(--ButtonBGColor);
  font-size: large;
  font-weight: bold;
  border: 2px solid var(--ButtonTextColor);
}
/*------ List Orders - Menu Layout3 ----------------------------------------------*/
/* Custom Category for Menu Layout3 */
.listOrderCategoryML3 {
  /*border: 2px solid red;   */
  color: var(--ListSectionTextColor);
  background-color: var(--ListSectionBGColor);
  font-family: var(--SectionFontType);
  font-size: var(--SectionFontSize);
  font-weight: bold;
  margin: 2px 1px 2px 1px;
  padding: 0px 1px 0px 1px;
  height: var(--category-height);
  width: 100%;
  position: relative;
}
.listOMCatROML3 {
  position: absolute; /* Note - position-absolute inside listOrderCategory which is position-relative : positions this div inside listOrderCategory - see: https://stackoverflow.com/questions/2941189/how-to-overlay-one-div-over-another-div*/
  color: var(--ListSectionTextColor);
  background-color: var(--ListSectionBGColor);
  font-family: var(--SectionFontType);
  font-size: var(--SectionFontSize);
  font-weight: bold;
  top: 10px;
  left: 80px;
  width: calc(100% - 138px);
}
.listOMCatRightOML3 {
  position: absolute;
  right: 30px;
  top: 20px;
  width: 27px;
  color: var(--ListSectionTextColor);
  background-color: var(--ListSectionBGColor);
  font-family: var(--SectionFontType);
  font-size: var(--SectionFontSize);
}
.avatarCategoryPicML3 {
  height: 74px;
  width: 96%;
  margin-left: 2%;
  background-color: white;
  border-radius: 2px;
}
/*** ML3 ****/
.listOrderRowML3 {
  position: relative;
  overflow: auto; /*clip;*/
  clear: both;
  /*border: 3px solid blue; */
  background-color: var(--ListBGColor);
  border-radius: 5px;
  margin: 3px 3px 3px 3px;
  padding: 0 0 6px 3px;
  min-height: 112px;
  /*height: 158px;*/
}
.listOMTextDiv3 {
  position: relative;
  overflow: auto;
  float: left;
  padding-left: 25px;
  width: calc(100% - 147px);
  /*overflow-y: hidden;*/
  /*border: 3px solid blue; */
}            
.listOMItemNameML3 {
  padding: 0 0 0 3px;
  word-wrap: break-word;
  text-align: left;
  color: var(--ItemNameColor);
  font-family: var(--ItemNameFontType);
  font-size: var(--ItemNameFontSize);
  font-weight: var(--ItemNameFontWht);
}
.listOMItemDescML3 {
  padding: 0 0 0 5px;
  overflow-wrap: break-word;
  color: var(--ItemDescColor);
  font-family: var(--ItemDescFontType);
  font-size: var(--ItemDescFontSize);
  font-weight: var(--ItemDescFontWht);
}  
.listOMFullDescML3 {
  font-size: 14px;
  color: black;
  padding: 10px 3px 3px 2px;
}   
.listTitleML3 {                                   
  color: var(--ItemDescColor);
  font-family: var(--ItemDescFontType);
  font-size: var(--ItemDescFontSize);
  font-weight: var(--ItemDescFontWht);
  padding: 1px 3px 1px 2px;
}
.listLineML3 {
  font-size: 13px;
  color: black;
  padding: 3px 3px 3px 3px;
}
.listOMBuyDiv3 {
  position: absolute;
  /*border: solid 3px green;  */
  width: 70px;
  top: 5px;
  right: 5px;
  color: var(--ItemBuyColor);
  font-family: var(--ItemBuyFontType);
  font-size: var(--ItemBuyFontSize);
  font-weight: var(--ItemBuyFontWht);
}
.listOMPriceTotalML3 {
  position: absolute;
  /*border: solid 1px red;  */
  top: 10px;
  left: 5px;
  width: 56px;
  height: 55px;
}
.listOMCountML3 {
  /*border: solid 1px green;  */
  position: absolute;
  top: 16px;
  right: 0px;
  width: 52px;
  font-family: var(--ItemBuyFontType);
  font-size: 14px;
  font-weight: normal;
  color: var(--ItemTotalColor);
}
.listOMTotalML3 {
  /*border: solid 1px blue;  */
  position: absolute;
  top: 29px;
  left: 0px;
  height: 20px;
  font-family: var(--ItemBuyFontType);
  font-size: var(--ItemBuyFontSize);
  font-weight: var(--ItemBuyFontWht);
  color: var(--ItemTotalColor);
}
.listOMCartPromoML3 {
  /*border: solid 1px green;  */
  position: absolute;
  display: inline-block;
  top: 68px;
  right: 0;
  width: 70px;
}
.listOMQuickBuy3 {
  /*border: solid 3px green;  */
  position: absolute;
  top: 28px;
  left: 10px;
  width: 25px;
  resize: both;
  height: 25px;
}
.listOMCartML3 {
  /*border: solid 3px green;  */
  position: absolute;
  top: -10px;
  left: 3px;
  width: 25px;
  resize: both;
  height: 25px;
}
.listOMCartML3Add {
  /*border: solid 3px green;  */
  position: absolute;
  top: -10px;
  right: 0px;
  width: 25px;
  resize: both;
  height: 25px;
}
.listOMPromoML3 {
  /*border: solid 3px purple;  */
  position: absolute;
  top: 0px;
  right: 34px;
  width: 25px;
  resize: both;
  height: 25px;
}
/*------ List Orders - Menu Layout4 ----------------------------------------------*/
/*------ Menu Layout 4 - Category Layout - Currently the Same for all ----------------------------------------------*/
.listOrderCategoryML4 {
  /*border: 2px solid red;   */
  font-size: 18px;
  color: var(--ListSectionTextColor);
  background-color: var(--ListSectionBGColor);
  font-family: var(--SectionFontType);
  font-size: var(--SectionFontSize);
  font-weight: bold;
  margin: 2px 1px 2px 1px;
  padding: 0px 1px 0px 1px;
  height: var(--category-height);
  width: 100%;
  position: relative;
}
.listOMCatLML4 {
  /*border: 2px solid purple;   */
  position: absolute;
  color: var(--ListSectionTextColor);
  background-color: var(--ListSectionBGColor);
  font-family: var(--SectionFontType);
  font-size: var(--SectionFontSize);
  left: 5px;
  height: 74px;
  width: 110px;
}
.listOMCatRML4 {
  /*border: 2px solid green;   */
  position: absolute;
  text-align: center;
  left: 10px;
  width: calc(100% - 20px);
  color: var(--ListSectionTextColor);
  background-color: var(--ListSectionBGColor);
  font-family: var(--SectionFontType);
  font-size: var(--SectionFontSize);
  overflow: hidden Auto;
}
.listOMCatRightML4 {
  position: absolute;
  right: 15px;
  top: 15px;
  width: 27px;
  color: var(--ListSectionTextColor);
  background-color: var(--ListSectionBGColor);
  font-family: var(--SectionFontType);
  font-size: var(--SectionFontSize);
}
/*** ML4 ***/
.listOrderRowML4 {
  position: relative;
  overflow: auto; /*clip;*/
  clear: both;
  background-color: var(--ListBGColor);
  /*border: solid 1px blue;  */
  /*  border-radius: 10px;
  border: 2px solid #ccc;*/
  margin: 2px 0 3px 0;
  padding: 0 0 6px 3px;
  min-height: 50px;
  height: auto;
}
.listOMTextDiv4 {
  /*position: absolute; - putting this here prevents proper resizing! */
  /*border: solid 1px red;   */
  /*position: absolute;*/
  top: 0;
  margin-left: 4px;
  text-align: center;
  width: calc(100% - 110px);
}     
.listOMItemNameML4 {
  padding: 0 0 0 3px;
  word-wrap: break-word;
  text-align: left;
  color: var(--ItemNameColor);
  font-family: var(--ItemNameFontType);
  font-size: var(--ItemNameFontSize);
  font-weight: var(--ItemNameFontWht);
}
.listOMItemDescML4 {
  padding: 0 0 0 5px;
  overflow-wrap: break-word;
  color: var(--ItemDescColor);
  font-family: var(--ItemDescFontType);
  font-size: var(--ItemDescFontSize);
  font-weight: var(--ItemDescFontWht);
}
.listOMBuyDiv4 {
  position: absolute;
  /*border: solid 3px green;  */
  width: 100px;
  height: 40px;
  top: 0px;
  right: 0px;
  color: var(--ItemBuyColor);
  font-family: var(--ItemBuyFontType);
  font-size: var(--ItemBuyFontSize);
  font-weight: var(--ItemBuyFontWht);
}
.listOMPriceTotalML4 {
  position: absolute;
  /*border: solid 3px red;  */
  height: 40px;
  top: 0;
  right: 46px;
  width: 56px;
}
.listOMCountML4 {
  /*border: solid 1px green;  */
  position: absolute;
  top: 16px;
  right: 1px;
  width: 60px;
  font-family: var(--ItemBuyFontType);
  font-size: 14px;
  font-weight: normal;
  color: var(--ItemTotalColor);
}
.listOMTotalML4 {
  /* border: solid 1px green; */
  position: absolute;
  top: 29px;
  right: 1px;
  font-family: var(--ItemBuyFontType);
  font-size: var(--ItemBuyFontSize);
  font-weight: var(--ItemBuyFontWht);
  color: var(--ItemTotalColor);
} 
.listOMCartPromoML4 {
  /*border: solid 3px red;  */
  position: absolute;
  top: 2px;
  right: 5px;
  width: 35px;
  height: 25px;
}       
.listOMCartML4 {
  /*border: solid 3px green;  */
  position: absolute;
  top: -2px;
  left: 4px;
  width: 25px;
  resize: both;
  height: 25px;
}
.listOMCartML4Add {
  /*border: solid 3px green;  */
  position: absolute;
  top: -2px;
  right: 0;
  width: 25px;
  resize: both;
  height: 25px;
}
.listOMImageDivML4 {
  /*border: solid 3px green;  */
  position: absolute;
  top: 7px;
  right: 38px;
  width: 25px;
  resize: both;
  height: 25px;
}
.listOMImageDiv4 {
  float: right;
  padding-right: 2px;
  width: 76px;
}
.listOMQuickBuy4 {
  /*border: solid 3px purple;  */
  position: absolute;
  top: 22px;
  right: 10px;
  width: 25px;
  resize: both;
  height: 25px;
}
.listOMPromoML4 {
  /*border: solid 3px purple;  */
  position: absolute;
  top: 23px;
  right: 10px;
  width: 25px;
  resize: both;
  height: 25px;
}
/*------ Menu Layout5 - Left and Right ----------------------------------------------*/ 
.listOrderRowColLeft {
  position: absolute;
  overflow: clip;
  clear: both;
  border-radius: 5px;
  width: 49%;
  left: 1px;
  border: 2px solid #ccc;
  margin: 2px 0px 3px 3px;
  padding: 0px 0px 0px 0px;
  min-height: 84px;
}
.listOrderRowColRight {
  position: absolute;
  overflow: clip;
  clear: both;
  border-radius: 5px;
  width: 49%;
  right: 1px;
  border: 2px solid #ccc;
  margin: 2px 0px 3px 3px;
  padding: 0px 0px 0px 0px;
  min-height: 84px;
}
.listOMCatRML5 {
  /*border: 2px solid green;   */
  position: relative;
  float: left;
  left: 10px;
  width: calc(100% - 90px);
  color: var(--ListSectionTextColor);
  background-color: var(--ListSectionBGColor);
  font-family: var(--SectionFontType);
  font-size: var(--SectionFontSize);
}
.listOMCatRightML5 {
  right: 10px;
  position: absolute;
  width: 37px;
  color: var(--ListSectionTextColor);
  background-color: var(--ListSectionBGColor);
  font-family: var(--SectionFontType);
  font-size: var(--SectionFontSize);
}
/*------ List Orders - Menu Layout5 ----------------------------------------------*/
.listOrderRowML5 {
  position: relative;
  overflow: clip;
  clear: both;
  background-color: var(--ListBGColor);
  /*border: solid 1px blue;  */
  /*  border-radius: 10px;
  border: 2px solid #ccc;*/
  margin: 1px 0 1px 0;
  padding: 0 0 6px 0;
  height: 52px;
}
.listOMTextDiv5 {
  position: absolute;
  overflow: hidden auto; /* prevent wrap! */
  white-space: nowrap;   /* prevent wrap! */
  /*border: solid 1px red;  */
  top: 0;
  left: 2px;
  text-align: left;
  width: calc(100% - 92px);
}         
.listOMItemNameML5 {
  padding: 0 0 0 3px;
  word-wrap: break-word;
  text-align: left;
  color: var(--ItemNameColor);
  font-family: var(--ItemNameFontType);
  font-size: var(--ItemNameFontSize);
  font-weight: var(--ItemNameFontWht);
}
.listOMItemDescML5 {
  padding: 0 0 0 5px;
  overflow-wrap: break-word;
  color: var(--ItemDescColor);
  font-family: var(--ItemDescFontType);
  font-size: var(--ItemDescFontSize);
  font-weight: var(--ItemDescFontWht);
}
.listOMBuyDiv5 {
  position: absolute;
  /*border: solid 1px green;  */
  width: 80px;
  height: 40px;
  top: 0px;
  right: 5px;
  color: var(--ItemBuyColor);
  font-family: var(--ItemBuyFontType);
  font-size: var(--ItemBuyFontSize);
  font-weight: var(--ItemBuyFontWht);
}
.listOMPriceTotalML5 {
  position: absolute;
  /*border: solid 1px red;  */
  height: 40px;
  top: 0;
  right: 8px;
  width: 70px;
}
.listOMCountML5 {
  /* border: solid 2px blue; */
  position: absolute;
  top: -2px;
  right: -16px;
  width: 32px;
  resize: both;
  height: 25px;
  font-family: var(--ItemBuyFontType);
  font-size: 14px;
  font-weight: normal;
  color: var(--ItemTotalColor);
}
.listOMTotalML5 {
  /*border: solid 1px green;  */
  position: absolute;
  top: 21px;
  right: 12px;
  font-family: var(--ItemBuyFontType);
  font-size: var(--ItemBuyFontSize);
  font-weight: var(--ItemBuyFontWht);
  color: var(--ItemTotalColor);
}
.listOMCartPromoML5 {
  /*border: solid 3px red;  */
  position: absolute;
  top: 2px;
  right: -10px;
  width: 35px;
  height: 25px;
}       
.listOMCartML5 {
  /*border: solid 3px green;  */
  position: absolute;
  top: 16px;
  right: 0;
  width: 25px;
  resize: both;
  height: 25px;
}
.listOMCartML5Add {
  /*border: solid 3px green;  */
  position: absolute;
  top: 27px;
  right: 0;
  width: 25px;
  resize: both;
  height: 25px;
}
.listOMImageDivML5 {
  /*border: solid 3px green;  */
  position: absolute;
  top: 7px;
  right: 38px;
  width: 25px;
  resize: both;
  height: 25px;
}
.listOMImageDiv5 {
  float: right;
  padding-right: 2px;
  width: 76px;
}
.listOMCircleML5 {
  /*border: solid 3px purple;  */
  position: absolute;
  top: 22px;
  right: 30px;
  width: 25px;
  resize: both;
  height: 25px;
}
.listOMPromoML5 {
  /*border: solid 3px purple;  */
  position: absolute;
  top: 23px;
  right: 2px;
  width: 25px;
  resize: both;
  height: 25px;
}
/*------ Menu Layout6 - Left and Right ----------------------------------------------*/
.listOrderRowCol6Left {
  position: absolute;
  overflow: clip;
  clear: both;
  border-radius: 5px;
  width: 47%;
  left: 1px;
  border-radius: 10px;
  box-shadow: 4px 4px 3px var(--DropShadowColor);
  /*border: 2px solid #ccc; */
  margin: 6px 2px 6px 6px;
  padding: 0px 0px 0px 3px;
  min-height: 230px;
}
.listOrderRowCol6Right {
  position: absolute;
  overflow: clip;
  clear: both;
  border-radius: 5px;
  width: 47%;
  right: 1px;
  border-radius: 10px;
  box-shadow: 4px 4px 3px var(--DropShadowColor);
  /*border: 2px solid #ccc; */
  margin: 6px 8px 6px 2px;
  padding: 0px 0px 0px 3px;
  min-height: 230px;
}
.listOMCatRML6 {
  /*border: 2px solid green;   */
  position: relative;
  float: left;
  left: 10px;
  width: calc(100% - 90px);
  color: var(--ListSectionTextColor);
  background-color: var(--ListSectionBGColor);
  font-family: var(--SectionFontType);
  font-size: var(--SectionFontSize);
}
.listOMCatRightML6 {
  right: 10px;
  position: absolute;
  width: 37px;
  color: var(--ListSectionTextColor);
  background-color: var(--ListSectionBGColor);
  font-family: var(--SectionFontType);
  font-size: var(--SectionFontSize);
}
/*------ List Orders - Menu Layout6 ----------------------------------------------*/
.listOrderRowML6 {
  position: relative;
  overflow: auto;
  background-color: var(--ListBGColor);
  border: none; /*  */
  /*  border-radius: 10px;
  border: 2px solid #ccc;*/
  margin: 1px 0 1px 0;
  padding: 0 0 6px 0;
  height: 242px;
}
.listOMTextDiv6 {
  position: absolute;
  word-wrap: break-word;
  text-align: left;
  /*border: solid 1px red;  */
  top: 100px;
  left: 3px;
  text-align: left;
  width: 97%;
}         
.listOMItemNameML6 {
  padding: 0 0 0 3px;
  word-wrap: break-word;
  text-align: left;
  color: var(--ItemNameColor);
  font-family: var(--ItemNameFontType);
  font-size: var(--ItemNameFontSize);
  font-weight: var(--ItemNameFontWht);
}
.listOMItemDescML6 {
  padding: 0 0 0 5px;
  overflow-wrap: break-word;
  color: var(--ItemDescColor);
  font-family: var(--ItemDescFontType);
  font-size: var(--ItemDescFontSize);
  font-weight: var(--ItemDescFontWht);
}
.listOMBuyDiv6 {
  position: absolute;
  /* border: solid 3px green; */
  width: 40px;
  height: 40px;
  top: 0px;
  right: 5px;
  color: var(--ItemBuyColor);
  font-family: var(--ItemBuyFontType);
  font-size: var(--ItemBuyFontSize);
  font-weight: var(--ItemBuyFontWht);
}
.listOMPriceTotalML6 {
  position: absolute;
  /*border: solid 1px red;  */
  height: 50px;
  top: 0;
  right: 8px;
  width: 50px;
}
.listOMCountML6 {
  /*border: solid 1px green;  */
  position: absolute;
  top: 16px;
  right: 0px;
  width: 50px;
  font-family: var(--ItemBuyFontType);
  font-size: 14px;
  font-weight: normal;
  color: var(--ItemTotalColor);
}
.listOMTotalML6 {
  /*border: solid 1px green;  */
  position: absolute;
  top: 29px;
  right: 0px;
  height: 25px;
  font-family: var(--ItemBuyFontType);
  font-size: var(--ItemBuyFontSize);
  font-weight: var(--ItemBuyFontWht);
  color: var(--ItemTotalColor);
}
.listOMCartPromoML6 {
  /*border: solid 3px red;  */
  position: absolute;
  top: 62px;
  right: -10px;
  width: 35px;
  height: 25px;
}
.listOMCartML6 {
  /*border: solid 3px green;  */
  position: absolute;
  top: -2px;
  left: 3px;
  width: 25px;
  resize: both;
  height: 25px;
}
.listOMCartML6Add {
  /*border: solid 3px green;  */
  position: absolute;
  top: -2px;
  right: 0;
  width: 25px;
  resize: both;
  height: 25px;
}
.listOMImageDivML6 {
  /*border: solid 3px green;  */
  position: absolute;
  top: 7px;
  right: 38px;
  width: 25px;
  resize: both;
  height: 25px;
}
.listOMImageDiv6 {
  float: right;
  padding-right: 2px;
  width: 76px;
}
.listOMCircleML6 {
  /*border: solid 3px purple;  */
  position: absolute;
  top: 26px;
  right: -5px;
  width: 25px;
  resize: both;
  height: 25px;
}
.listOMPromoML6 {
  /*border: solid 3px purple;  */
  position: absolute;
  top: 23px;
  right: 2px;
  width: 25px;
  resize: both;
  height: 25px;
}    
/*------ List Orders - Menu Layout7 ----------------------------------------------*/
/*------ Menu Layout 7 - Category Layout - Currently the Same for all ----------------------------------------------*/
.listOrderCategoryML7 {
  /*border: 2px solid red;   */
  color: var(--ListSectionTextColor);
  background-color: var(--ListSectionBGColor);
  font-family: var(--SectionFontType);
  font-size: var(--SectionFontSize);
  font-weight: bold;
  margin: 2px 1px 2px 1px;
  padding: 0px 1px 0px 1px;
  height: var(--category-height);
  width: 100%;
  position: relative;
}
.listOMCatLML7 {
  /*border: 2px solid purple;   */
  position: absolute;
  left: 5px;
  height: 74px;
  width: 110px;
  color: var(--ListSectionTextColor);
  background-color: var(--ListSectionBGColor);
  font-family: var(--SectionFontType);
  font-size: var(--SectionFontSize);
}
.listOMCatRML7 {
  /*border: 2px solid green;   */
  position: absolute;
  left: 125px;
  width: calc(100% - 182px);
  color: var(--ListSectionTextColor);
  background-color: var(--ListSectionBGColor);
  font-family: var(--SectionFontType);
  font-size: var(--SectionFontSize);
}
.listOMCatRMLeft7 {
  /*border: 2px solid green;   */
  position: absolute;
  left: 15px;
  width: calc(100% - 182px);
  color: var(--ListSectionTextColor);
  background-color: var(--ListSectionBGColor);
  font-family: var(--SectionFontType);
  font-size: var(--SectionFontSize);
}
.listOMCatRightML7 {
  position: absolute;
  right: 15px;
  top: 15px;
  width: 27px;
  color: var(--ListSectionTextColor);
  background-color: var(--ListSectionBGColor);
  font-family: var(--SectionFontType);
  font-size: var(--SectionFontSize);
}          
/*** ML7 ***/
.listOrderRowML7 {
  /*border: 2px solid blue; */
  position: relative;
  overflow: auto; /*clip;*/
  clear: both;
  border-radius: 10px;
  box-shadow: 3px 3px 3px var(--DropShadowColor);
  margin: 6px 6px 6px 4px;
  padding: 0 0 6px 3px;
  min-height: 158px;
}
.listOMTextDiv7 {
  /*position: absolute;*/
  /*border: solid 2px red;  */ 
  top: 0;
  /*left: 165px;*/
  margin-left: 154px;
  width: calc(100% - 234px);
}
.listOMFullDescML7 {
  word-wrap: break-word;
  font-size: 14px;
  color: black;
  padding: 10px 3px 3px 2px;
}
.listOMBuyDiv7 {
  position: absolute;
  /*border: solid 1px blue;  */
  right: 5px;
  width: 70px;
  height: 147px;
  margin-top: 0;
  top: 5px;
  color: var(--ItemBuyColor);
  font-family: var(--ItemBuyFontType);
  font-size: var(--ItemBuyFontSize);
  font-weight: var(--ItemBuyFontWht);
}   
.listOMPriceTotalML7 {
  position: absolute;
  /*border: solid 1px red;  */
  height: 40px;
  top: 0;
  right: 0px;
  width: 70px;
}
.listOMCountML7 {
  /*border: solid 1px green;  */
  position: absolute;
  top: 16px;
  right: 0px;
  width: 70px;
  font-family: var(--ItemBuyFontType);
  font-size: 14px;
  font-weight: normal;
  color: var(--ItemTotalColor);
}         
.listOMTotalML7 {
  /*border: solid 1px green;  */
  position: absolute;
  top: 29px;
  right: 0px;
  width: 70px;
  font-family: var(--ItemBuyFontType);
  font-size: var(--ItemBuyFontSize);
  font-weight: var(--ItemBuyFontWht);
  color: var(--ItemTotalColor);
}         
.listOMQuickBuy7 {
  /*border: solid 3px green;  */
  position: absolute;
  top: 23px;
  left: 10px;
  width: 25px;
  resize: both;
  height: 25px;
}
.listOMCartPromoML7 {
  /*border: solid 3px red;  */
  position: absolute;
  display: inline-block;
  top: 68px;
  left: 5px;
  width: 70px;
}    
.listOMCartML7 {
  /*border: solid 3px green;  */
  position: absolute;
  top: -5px;
  left: 3px;
  width: 25px;
  resize: both;
  height: 25px;
}
.listOMCartML7Add {
  /*border: solid 3px green;  */
  position: absolute;
  top: -2px;
  right: 2px;
  width: 25px;
  resize: both;
  height: 25px;
}
.listOMPromoML7 {
  /*border: solid 3px purple;  */
  position: absolute;
  top: 30px;
  left: 15px;
  width: 25px;
  resize: both;
  height: 25px;
}
.listOMImageDiv7 {
  position: absolute;
  left: 0px;
  width: 148px;
  height: 148px;
  top: 0;
}
/*------ List Orders - Menu Layout8 ----------------------------------------------*/
/*------ Menu Layout 8 - Category Layout - Currently the Same for all ----------------------------------------------*/
.listOrderCategoryML8 {
  /*border: 2px solid red;   */
  color: var(--ListSectionTextColor);
  background-color: var(--ListSectionBGColor);
  font-family: var(--SectionFontType);
  font-size: var(--SectionFontSize);
  font-weight: bold;
  margin: 2px 1px 2px 1px;
  padding: 0px 1px 0px 1px;
  height: var(--category-height);
  width: 100%;
  position: relative;
}
.listOMCatLML8 {
  /*border: 2px solid purple;   */
  position: absolute;
  left: 5px;
  height: 74px;
  width: 110px;
  color: var(--ListSectionTextColor);
  background-color: var(--ListSectionBGColor);
  font-family: var(--SectionFontType);
  font-size: var(--SectionFontSize);
}
.listOMCatRML8 {
  /*border: 2px solid green;   */
  position: absolute;
  left: 125px;
  width: calc(100% - 182px);
  color: var(--ListSectionTextColor);
  background-color: var(--ListSectionBGColor);
  font-family: var(--SectionFontType);
  font-size: var(--SectionFontSize);
}
.listOMCatRightML8 {
  position: absolute;
  right: 15px;
  top: 15px;
  width: 27px;
  color: var(--ListSectionTextColor);
  background-color: var(--ListSectionBGColor);
  font-family: var(--SectionFontType);
  font-size: var(--SectionFontSize);
}          
/*** ML8 ***/
.listOrderRowML8 {
  /*border: 2px solid blue; */
  background-color: var(--ListBGColor);
  position: relative;
  overflow: clip;
  clear: both;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin: 4px 4px 5px 4px;
  padding: 0 0 6px 55px;
  min-height: 150px;
  height: 158px;
}
.listOMItemNameML8 {
  position: relative;
  color: var(--ItemNameColor);
  font-family: var(--ItemNameFontType);
  font-size: var(--ItemNameFontSize);
  font-weight: var(--ItemNameFontWht);
  left: 5px;
}
.listOMItemDescML8 {
  position: relative;
  color: var(--ItemDescColor);
  font-family: var(--ItemDescFontType);
  font-size: var(--ItemDescFontSize);
  font-weight: var(--ItemDescFontWht);
  left: 5px;
}
.listOMTextDiv8 {
  /*position: absolute; prevents height adjustment */
  /*border: solid 2px red;  */ 
  top: 0;
  left: 220px;
  margin: 0 0 5px 0;
  width: calc(100% - 154px);
}
.listOMFullDescML8 {
  font-size: 14px;
  color: black;
  padding: 10px 3px 3px 2px;
}
.listOMBuyDiv8 {
  position: absolute;
  /*border: solid 3px green;  */
  left: 3px;
  width: 218px;
  height: 147px;
  margin-top: 0;
  top: 5px;
  color: var(--ItemBuyColor);
  font-family: var(--ItemBuyFontType);
  font-size: var(--ItemBuyFontSize);
  font-weight: var(--ItemBuyFontWht);
}             
.listOMPriceTotalML8 {
  /*border: solid 3px red;  */
  float: left;
  width: 56px;
}
.listOMCountML8 {
  /*border: solid 1px green;  */
  position: absolute;
  top: 29px;
  left: 0px;
  width: 70px;
  font-family: var(--ItemBuyFontType);
  font-size: 14px;
  font-weight: normal;
  color: var(--ItemTotalColor);
}
.listOMTotalML8 {
  /*border: solid 3px green;  */
  position: absolute;
  top: 29px;
  left: 0px;
  font-family: var(--ItemBuyFontType);
  font-size: var(--ItemBuyFontSize);
  font-weight: var(--ItemBuyFontWht);
  color: var(--ItemTotalColor);
}
.listOMQuickBuy8 {
  /*border: solid 3px green;  */
  position: absolute;
  top: 23px;
  left: 10px;
  width: 25px;
  resize: both;
  height: 25px;
}
.listOMCartPromoML8 {
  /*border: solid 3px red;  */
  position: absolute;
  display: inline-block;
  top: 68px;
  left: 5px;
  width: 70px;
}
.listOMImageDiv8 { /* Image Box - Right Side */
  position: absolute;
  right: 0px;
  width: 148px;
  height: 148px;
  top: 0;
}
.listOMCartML8 {
  /*border: solid 3px green;  */
  position: absolute;
  top: -5px;
  left: 3px;
  width: 25px;
  resize: both;
  height: 25px;
}
.listOMCartML8Add {
  /*border: solid 3px green;  */
  position: absolute;
  top: -2px;
  right: 0;
  width: 25px;
  resize: both;
  height: 25px;
}
.listOMPromoML8 {
  /*border: solid 3px purple;  */
  position: absolute;
  top: 30px;
  left: 15px;
  width: 25px;
  resize: both;
  height: 25px;
}
/*-----------------------------------------------------------------------------------*/
/*------ List Receipts ----------------------------------------------*/
.listReceiptRow {
  overflow: auto;
  clear: both;
  border-radius: 10px;
  border: 2px solid #ccc;
  margin: 3px 0px 3px 3px;
  padding: 0px 0px 0px 3px;
  min-height: 70px;
}
.listHeaderLeft {
  border: none;
  position: relative;
  color: var(--ListHeadTextColor);
  background-color: var(--ListHeadBGColor);
  height: 50px;
  width: 40%;
  font-family: var(--HeadFontType);
  font-size: var(--HeadFontSize);
  float: left;
  left: 1em;
  top: 1px;
}
.listHeaderRight {
  border: none; /*2px solid red;*/
  position: relative;
  color: var(--ListHeadTextColor);
  background-color: var(--ListHeadBGColor);
  height: 50px;
  width: 46%;
  font-family: var(--HeadFontType);
  font-size: var(--HeadFontSize);
  float: right;
  top: 2px;
}
.listHeaderCenter {
  /*border: 2px solid red; */
  position: absolute;
  color: var(--ListHeadTextColor);
  background-color: var(--ListHeadBGColor);
  height: 40px;
  width: 60%;
  font-family: var(--HeadFontType);
  font-size: var(--HeadFontSize);
  left: 25%;
  right: 10%;
  top: 10px;
}
/* Help About */
.listHelpHeader {
  border-radius: 10px;
  border: 3px solid var(--ButtonBGColor); /**/
  background-color: linen;
  height: 30px;
  font-size: large;
  color: white;
  overflow-x: hidden;
}
.listHelpRow {
  overflow: auto;
  clear: both;
  border-radius: 10px;
  border: 2px solid var(--ButtonBGColor);
  margin: 3px 0px 3px 3px;
  padding: 0px 0px 0px 3px;
  min-height: 84px;
}
.listHelpFooter {
  border-radius: 10px;
  border: 3px solid var(--ButtonBGColor); /**/
  background-color: linen;
  height: 15px;
  font-size: large;
  color: white;
  overflow-x: hidden;
}
.ratingStars {
  /*border: 3px solid red; */
  height: 15px;
  font-size: 14px;
}
/*---------- End ListItems --------------------------------------------------------------------------------------------------------------------------------------------*/
/*---*/
.paymentCard {
  height: 45px;
}
.seatButton {
  cursor: pointer;
  color: var(--ButtonTextColor);
  background-color: var(--ButtonBGColor);
  font-size: large;
  font-weight: bold;
  border-radius: 5px;
  height: 26px;
}
.smallButton {
  cursor: pointer;
  color: var(--ButtonTextColor);
  background-color: var(--ButtonBGColor);
  font-family: var(--ButtonFontType);
  font-size: small;
  font-weight: bold;
  border-radius: 10px;
  border: 2px solid var(--ButtonTextColor);
}
.smallButtonLeft {
  cursor: pointer;
  color: var(--ButtonTextColor);
  background-color: var(--ButtonBGColor);
  font-family: var(--ButtonFontType);
  font-size: small;
  font-weight: bold;
  border-radius: 10px;
  border: 2px solid var(--ButtonTextColor);
  left: 10px;
}
.smallButtonRight {
  cursor: pointer;
  color: var(--ButtonTextColor);
  background-color: var(--ButtonBGColor);
  border: 2px solid var(--ButtonTextColor);
  font-family: var(--ButtonFontType);
  font-size: small;
  font-weight: bold;
  border-radius: 10px;
  left: 36px;
}
.minButton {
  cursor: pointer;
  color: var(--ButtonTextColor);
  background-color: var(--ButtonBGColor);
  border: 2px solid var(--ButtonTextColor);
  font-family: var(--ButtonFontType);
  font-size: small;
  font-weight: bold;
  border-radius: 6px;
  height: 20px;
}
.loginButton {
  cursor: pointer;
  color: black;
  background-color: whitesmoke;
  font-size: medium;
  font-weight:normal;
  width: 100%;
  height: 50px;
  border: 1px solid var(--ButtonTextColor);
}
.landingButton {
  cursor: pointer;
  color: var(--BrandColor);
  margin: 10px 0 0 40px;
  background-color: whitesmoke;
  font-size: 22px;
  font-weight: normal;
  border-radius: 15px;
  height: 30px;
  /*  border: 1px solid var(--ButtonTextColor);*/
}
.rightMargin {
  position: absolute;
  right: 3px;
}
.landingSButton {
  position: absolute;
  top: 64px;
  right: 20px;
  cursor: pointer;
  color: white;
  background-color: var(--BrandColor);
  font-size: 18px;
  font-weight: bold;
  border-radius: 15px;
  height: 30px;
  border: 5px solid var(--ButtonTextColor);
}
.landingXButton {
  cursor: pointer;
  color: white;
  margin: 10px 0 0 40px;
  background-color: var(--BrandColor);
  font-size: 22px;
  font-weight: bolder;
  border-radius: 15px;
  height: 40px;
  border: 5px solid var(--ButtonTextColor);
}
.favoritesButton {
  cursor: pointer;
  font-size: medium;
  color: var(--ButtonTextColor);
  background-color: var(--ButtonBGColor);
  font-weight: bold;
  border-radius: 5px;
  left: 20px;
  top: 3px;
  height: 24px;
}
.scanButton {
  cursor: pointer;
  font-size: medium;
  color: var(--ButtonTextColor);
  background-color: var(--ButtonBGColor);
  font-weight: bold;
  border-radius: 5px;
  left: 1px;
  top: 0px;
  height: 27px;
}
.mediumButton {
  cursor: pointer;
  color: var(--ButtonTextColor);
  background-color: var(--ButtonBGColor);
  font-family: var(--ButtonFontType);
  font-size: medium;
  font-weight: bold;
  border-radius: 10px;
  border: 2px solid var(--ButtonTextColor);
}
.medFxdButton {
  cursor: pointer;
  color: var(--ButtonTextColor);
  background-color: var(--ButtonBGColor);
  font-family: var(--ButtonFontType);
  font-size: medium;
  font-weight: bold;
  width: 165px;
  border-radius: 10px;
  border: 2px solid var(--ButtonTextColor);
}
.largeButton {
  cursor: pointer;
  color: var(--ButtonTextColor);
  background-color: var(--ButtonBGColor);
  font-family: var(--ButtonFontType);
  font-size: large;
  font-weight: bold;
  border-radius: 10px;
  border: 2px solid var(--ButtonTextColor);
}
.largeButtonSq {
  cursor: pointer;
  color: var(--ButtonTextColor);
  background-color: var(--ButtonBGColor);
  font-size: large;
  font-weight: bold;
  border: 2px solid var(--ButtonTextColor);
}
.leftButton {
  cursor: pointer;
  color: var(--ButtonTextColor);
  background-color: var(--ButtonBGColor);
  font-family: var(--ButtonFontType);
  font-size: small;
  font-weight: bold;
  border-radius: 10px;
  left: 5px;
  border: 2px solid var(--ButtonTextColor);
}
.leftButtonL {
  cursor: pointer;
  color: var(--ButtonTextColor);
  background-color: var(--ButtonBGColor);
  font-family: var(--ButtonFontType);
  font-size: 16px;
  font-weight: bold;
  border-radius: 10px;
  left: 5px;
  border: 2px solid var(--ButtonTextColor);
}
.centerButton {
  cursor: pointer;
  color: var(--ButtonTextColor);
  background-color: var(--ButtonBGColor);
  font-family: var(--ButtonFontType);
  font-size: small;
  font-weight: bold;
  border-radius: 10px;
  left: 20px;
  border: 2px solid var(--ButtonTextColor);
}
.rightButton {
  cursor: pointer;
  color: var(--ButtonTextColor);
  background-color: var(--ButtonBGColor);
  font-family: var(--ButtonFontType);
  font-size: small;
  font-weight: bold;
  border-radius: 10px;
  left: 40px;
  border: 2px solid var(--ButtonTextColor);
}
/*------------------------------------------------*/
.managerPage {
  /*border: solid 3px green;  */
  top: 0;
  margin: 0 5px 0 10px;
  background-color: white;
}
.headerMgr {
  width: 100%;
  height: 65px;
  background-color: var(--BrandColor);
  color: var(--LandingTextColor);
  /*border: 1px solid red;  */
}
.managerMessage {
  border: 1px solid blue; /* */
  position: absolute;
  top: 38px;
  left: 0px;
  right: 50px;
  height: 22px;
}
.managerInfo {
  position: absolute;
  top: 96px;
  width: 100%;
  height: 300px;
}
#HoursOfOpTop {
  position: relative;
  width: 90%;
  left: 5px;
  top: 6px;
  height: 27px;
}
.HoursOfOp {
  position: relative;
  width: 80%;
  left: 10px;
  height: 20px;
  /*border: 1px solid blue;  */
}
.HoursToday {
  /*border: 1px solid red;  */
  position: absolute;
  font-size: 16px;
  color: var(--InputTextColor);
  font-weight: bold;
  left: 15px;
}
.HoursOfOpDay {
  position: absolute;
  font-size: 14px;
  color: var(--InputTextColor);
  font-weight: bold;
  left: 60px;
}
.HoursOfOpLeft {
  position: absolute;
  font-size: 14px;
  color: var(--InputTextColor);
  font-weight: bold;
  left: 10px;
}
.HoursOfOpTime {
  position: absolute;
  font-size: 14px;
  color: var(--InputTextColor);
  font-weight: bold;
  left: 125px;
}
.HoursOfOpTimeLeft {
  position: absolute;
  font-size: 14px;
  color: var(--InputTextColor);
  font-weight: bold;
  left: 90px;
  /*border: 1px solid blue;  */
}
.managerDashboard {
  /*border: 1px solid red;  */
  position: absolute;
  top: 194px;
  width: 100%;
  height: 225px;
}
.managerFunctionsA {
  position: absolute;
  top: 445px;
  left: 2%;
  width: 50%;
  height: 300px;
}
.managerFunctionsB {
  position: absolute;
  top: 445px;
  left: 53%;
  width: 49%;
  height: 300px;
}
.mangerButton {
  /*border: 1px solid green; */
  margin: 1px 3px 1px 1px;
  width: 80%;
  /*background-color: white;*/
  height: 55px;
}
/* Table Clean */
.scanTitleDiv {
  margin: 10px 5px 1px 5px;
  text-align: center;
}
.getQR {
  margin: 1px 1px 1px 1px;
  width: 300px;
  height: 390px;
}
.getQRPopup {
  padding: 0px;
  margin: 0px;
  color: #787878;
  background-color: #fcf7f8;
  border: solid 2px #0073e6;
  border-radius: 18px;
  border-style: groove;
  position: absolute;
  left: 0px;
  width: 332px;
  height: 420px;
  line-height: 12px;
  font-family: var(--HeadFontType);
  font-size: 12px;
}
/*------------------------------------------------*/
/* Landing Page */
/*------------------------------------------------*/
.locationListPU {
  padding: 0px;
  margin: 0px;
  color: #787878;
  background-color: #fcf7f8;
  border: solid 2px #0073e6;
  border-radius: 10px;
  border-style: groove;
  position: absolute;
  left: 0px;
  width: 350px;
  height: 780px;
  line-height: 12px;
  font-family: var(--HeadFontType);
  font-size: 12px;
}
.locationPopupDiv {
  /*display: block;
  z-index: 999;
  pointer-events: auto;
  border: solid 10px black;   */
  margin: 0;
  /*border-width: 4px;
  border-color: #0073e6;
  border-style: groove;*/
  border-radius: 10px;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 1px;
  width: 340px;
  /*height: 1000px;*/
  height: 100%;
  bottom: 2px;
  background-color: white;
  text-decoration: none;
  text-align: left;
  color: black;
  font-family: var(--HeadFontType);
  font-size: 12px;
  scrollbar-face-color: #367CD2;
  scrollbar-shadow-color: #FFFFFF;
  scrollbar-highlight-color: #FFFFFF;
  scrollbar-3dlight-color: #FFFFFF;
  scrollbar-darkshadow-color: #FFFFFF;
  scrollbar-track-color: #FFFFFF;
  scrollbar-arrow-color: #FFFFFF;
}
/*-------------------------------------*/  
.headerLocList {
  position: fixed;
  width: 100%;
  height: 80px; 
  top: 24px;
  z-index: 4;
  background-color: var(--BrandColor);
  color: var(--LandingTextColor);
  /*border: 3px solid red;   */
}
.locationTopInfo {
  /*position: relative;*/
  display: block;
  left: 0;
  top: 0;
  margin: 2px 3px 0 0px;
  color: var(--BrandColor);
  background-color: var(--LandingTextColor);
  height: 122px;
  width: 100%;
  z-index: 2;
  /*border: 1px solid red; */
}
.locationLeft {
  position: absolute;
  left: 0;
  top: 12px;
  font-size: 14px;
  height: 20px; /*82px;*/
  width: 100%;
  z-index: 4;
  cursor: pointer;
  font-size: 16px;
}
.locationDetailHeader {
  position: fixed;
  display: block;
  /*border: solid 4px green;   */
  height: 35px;
  width: 340px;
  background-color: white;
  z-index: 2;
  /*height: var(--itemDetailPopupHt);*/
}
.locationHeaderDesc {
  position: absolute;
  display: block;
  width: 336px;
  /*border: solid 2px green; */
  padding: 12px 0px 0px 8px;
  top: 35px;
  height: 85px; /*82px;*/
  background-color: var(--BrandColor);
  z-index: 2;
}
.headerListBottom {
  position: absolute;
  left: 0;
  top: 57px;
  height: 65px; /*82px;*/
  width: 100%;
  /* border: 1px solid green;  */
}
.locationsList {
  /*border: solid 3px green;  */
  position: relative;
  top: 10px; /* 110px;*/
  left: 0px;
  right: 0px;
  width: 100%;
  background-color: white;
}
.listLocRow {
  overflow: auto;
  clear: both;
  border-radius: 10px;
  border: 2px solid #ccc;
  box-shadow: 3px 3px 3px var(--DropShadowColor);
  margin: 5px 5px 6px 3px;
  padding: 3px 0px 3px 3px;
}
.headerRecCenterMid {
  position: absolute;
  left: 30px;
  top: 75px;
  height: 24px; /*82px;*/
  width: 70%;
  /*border: 1px solid blue;   */
}
.headerRecBottomRight {
  position: absolute;
  right: 136px;
  bottom: 14px;
  height: 24px; /*82px;*/
  width: 50%;
  /*border: 1px solid blue;   */
}
.receiptsFav {
  position: absolute;
  top: 2px;
  right: 10px;
  width: 110px;
  z-index: 120;
  /* border: 1px solid blue; */
}    
.receiptList {
  /*border: solid 3px green; */
  position: absolute;
  top: 162px; /* 110px;*/
  left: 0px;
  right: 0px;
  bottom: 80px;
  background-color: white;
}
/*-------------------------------------*/   
.locationInfoPU {
  padding: 0px;
  margin: 0px;
  color: #787878;
  background-color: #fcf7f8;
  border: solid 2px #0073e6;
  border-radius: 18px;
  border-style: groove;
  position: absolute;
  left: 0px;
  width: 332px;
  height: 720px;
  line-height: 12px;
  font-family: var(--HeadFontType);
  font-size: 12px;
}
/*------------------------------------------------*/
#Messages {
  border: none;
  border-radius: 5px;
  position: absolute;
  top: 2px;
  right: 1px;
  left: 2px;
  width: 98%;
  bottom: 2px;
  resize: none;
  background-color: gray;
}
.messageImage {
  position: absolute;
  right: -2px;
  top: 2px;
  width: 14px;
  resize: both;
  height: 14px;
}
.popupImage {
  position: absolute;
  right: -2px;
  top: 45px;
  width: 14px;
  resize: both;
  height: 14px;
}
.popup-content {
/*  padding: 60px;
  margin: 60px;*/
  color: #787878;
  background-color: #fcf7f8;
  border: 2px solid rgba(0,0,0,.05);
  border-style: groove;
  position: absolute;
/*  top: 200px;
  left: 200px;*/
}
/*---------------------------*/
#orderTypeBox {
  border: solid 2px #0073e6;
  border-radius: 5px;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: hidden;
  padding: 2px;
  height: 700px;
  top: 2px;
  left: 2px;
  width: 339px;
  max-width: 700px;
  bottom: 2px;
  background-color: white;
  text-decoration: none;
  text-align: left;
  color: #fff;
  line-height: 13px;
  font-family: var(--HeadFontType);
  font-size: 12px;
  scrollbar-face-color: #367CD2;
  scrollbar-shadow-color: #FFFFFF;
  scrollbar-highlight-color: #FFFFFF;
  scrollbar-3dlight-color: #FFFFFF;
  scrollbar-darkshadow-color: #FFFFFF;
  scrollbar-track-color: #FFFFFF;
  scrollbar-arrow-color: #FFFFFF;
}
.ordertypeSettings {
  border: 2px solid blue; /*var(--indigo);*/
  border-radius: 15px;
  position: absolute;
  left: 2px;
  right: 2px;
  height: 164px;
  padding: 2px 2px 2px 2px;
  margin: 5px 5px 5px 5px;
}
.ordertypeDetails {
  /*border: 2px solid blue; var(--indigo);*/
  position: absolute;
  top: 214px;
  left: 2px;
  right: 2px;
  height: 160px;
  padding: 2px 2px 2px 2px;
  margin: 5px 5px 5px 5px;
}
/*---------------------------*/
#seatBox {
  border: solid 2px #0073e6;
  border-radius: 5px;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: hidden;
  padding: 2px;
  height: 98%;
  top: 2px;
  left: 2px;
  width: 339px;
  max-width: 700px;
  bottom: 2px;
  background-color: white;
  text-decoration: none;
  text-align: left;
  color: #fff;
  line-height: 13px;
  font-family: var(--HeadFontType);
  font-size: 12px;
  scrollbar-face-color: #367CD2;
  scrollbar-shadow-color: #FFFFFF;
  scrollbar-highlight-color: #FFFFFF;
  scrollbar-3dlight-color: #FFFFFF;
  scrollbar-darkshadow-color: #FFFFFF;
  scrollbar-track-color: #FFFFFF;
  scrollbar-arrow-color: #FFFFFF;
}
#receiptsBox {
  margin: 0;
  border-width: 4px;
  border-color: #0073e6;
  border-style: groove;
  border-radius: 10px;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 2px;
  top: 2px;
  left: 2px;
  width: 350px;
  height: 1000px;
  /*height: var(--itemDetailPopupHt); */
  bottom: 2px;
  background-color: white;
  text-decoration: none;
  text-align: left;
  color: black;
  font-family: var(--HeadFontType);
  font-size: 12px;
  scrollbar-face-color: #367CD2;
  scrollbar-shadow-color: #FFFFFF;
  scrollbar-highlight-color: #FFFFFF;
  scrollbar-3dlight-color: #FFFFFF;
  scrollbar-darkshadow-color: #FFFFFF;
  scrollbar-track-color: #FFFFFF;
  scrollbar-arrow-color: #FFFFFF;
}
.receiptCommentField {
  /*border: 2px solid red; */
  margin: 1px 3px 1px 3px;
  width: 99%;
  /*background-color: white;*/
  height: 85px;
}
.receiptComment {
  border: solid 1px blue;
  left: 6px;
  height: 45px;
  width: 92%;
  word-wrap: break-word;
  margin-left: 6px;
  padding-left: 3px;
  margin-top: 3px;
  color: var(--InputTextColor);
  background-color: var(--InputBGColor);
  font-family: var(--InputFontType);
  border-width: 2px;
  border-color: var(--InputBorderColor);
  border-radius: 3px;
  font-size: large;
  resize: none;
}
.receiptTop {
  position: absolute;
  display: block;
  border-radius: 8px;
  left: 0;
  top: 0;
  margin: 0px 3px 0 4px;
  color: var(--BrandColor);
  /*background-color: var(--LandingTextColor);*/
  background-color: white;
  height: 22px; /*82px;*/
  width: 100%;
}
.receiptLeft {
  /*border: solid 1px red;*/
  position: absolute;
  left: 0;
  top: 3px;
  font-size: 14px;
  height: 20px; /*82px;*/
  width: 190px;
  z-index: 4;
  cursor: pointer;
  font-size: 16px;
}
.receiptRight {
  position: absolute;
  right: 2px;
  top: 1px;
  height: 40px; /*82px;*/
  width: 120px;
  /*width: 280px;*/
  /*border: 2px solid green; */
}
.receiptList {
  /*border: 2px solid green;  */
  position: absolute;
  top: 160px;
  left: 5px;
  bottom: 25px;
  width: 100%;
  height: 80%;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: scroll;
}
#queryBox {
  border: solid 2px #0073e6;
  border-radius: 5px;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: hidden;
  height: 98%;
  top: 2px;
  right: 1px;
  left: 2px;
  width: 250px;
  max-width: 250px;
  bottom: 2px;
  background-color: white;
  text-decoration: none;
  text-align: left;
  color: #fff;
  line-height: 13px;
  font-family: var(--HeadFontType);
  font-size: 12px;
}
/*popup-content {
  color: #787878;
  background-color: #fcf7f8;
  border: 2px solid rgba(0,0,0,.05);
  border-style: groove;
  position: absolute;
}*/
/*---------------------------------------------*/
.reservePU {
  border: solid 4px #0073e6;/**/
  border-radius: 18px;/**/
  overflow: hidden;
  /*overflow-x: hidden;
  overflow-y: hidden;*/
  height: 580px;
  top: 2px;
  right: 1px;
  left: 2px;
  width: 300px;
  bottom: 2px;
  background-color: white;
  color: black;
  line-height: 14px;
  font-family: var(--HeadFontType);
  font-size: 14px;
}
.reserveDiv {
  margin: 5px 5px 5px 10px;
}
.reserveMessage {
  border: solid 1px #0073e6; /**/
  border-radius: 6px; /**/
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: hidden;
  height: 160px;
  width: 95%;
  padding: 3px 5px 3px 4px;
  left: 0px;
}
#locationBox {
  /*border: solid 2px #0073e6;*/
  /*border-radius: 5px;*/
  overflow: scroll;
  /*overflow-x: hidden;
  overflow-y: hidden;*/
  height: 98%;
  top: 2px;
  right: 1px;
  left: 2px;
  width: 100%;
  bottom: 2px;
  background-color: white;
  color: black;
  line-height: 17px;
  font-family: var(--HeadFontType);
  font-size: 14px;
}
.locationTitle {
  /*border: 2px solid blue;  */
  position: absolute;
  top: 287px;
  left: 10px;
  width: 100%;
  height: 130px;
  line-height: 20px;
  background-color: var(--HeadTextColor);
  font-family: var(--HeadFontType);
  font-size: 14px;
}
#locationHours {
  /*border: 2px solid blue;  */
  position: absolute;
  top: 418px;
  left: 8px;
  width: 100%;
  height: 110px;
}
#mapDiv {
  border: 2px solid blue; /*  */
  border-radius: 6px; /**/
  position: absolute;
  top: 558px;
  width: 97%;
  height: 200px;
  margin-left: 3px;
  /* border: 2px solid red; */
}
/*---------------------------------------------------------*/
.popup {
  padding: 60px;
  margin: 60px;
  color: #787878;
  background-color: #fcf7f8;
  border: 2px solid rgba(0,0,0,.05);
  border-style: groove;
  position: absolute;
  top: 200px;
  left: 200px;
}
#messageBox {
  border: solid 4px #0073e6;
  border-radius: 5px;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: hidden;
  height: 130px;
  width: 330px;
  background-color: whitesmoke;
  text-decoration: none;
  text-align: left;
  line-height: 17px;
  font-family: var(--HeadFontType);
  scrollbar-face-color: #367CD2;
  scrollbar-shadow-color: #FFFFFF;
  scrollbar-highlight-color: #FFFFFF;
  scrollbar-3dlight-color: #FFFFFF;
  scrollbar-darkshadow-color: #FFFFFF;
  scrollbar-track-color: #FFFFFF;
  scrollbar-arrow-color: #FFFFFF;
}
.errorMessage {
  color: red; 
  font-size: 13px;
}
.errorBox {
  color: red;
  font-size: 22px;
  font-weight: bold;
  margin: 10px 10px 0px 5px;
  height: 25px;
}
#PageTitle {
  position: absolute;
  top: 2px;
  right: 2px;
  left: 2px;
  width: 98%;
  bottom: 2px;
  resize: none;
}
btnImage.resize {
  transform: scale(0.5, 0.5);
  width: 20px;
  height: 20vh;
}
menuImage {
  height: 42px;
  float: right;
  margin: 0px;
  display: block;
  background: url(images/Info2.png) left top no-repeat;
}
/*------------- Main Page ------------------------------------------*/
.pageMain {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  max-width: 750px;
  min-width: 350px;
  border: none;
  background-color: white;
  color: var(--HeadingTextColor);
  font-family: var(--HeadingFontType);
  font-size: var(--HeadingFontSize);
}
.pageMainFx {         
  /*border: 2px solid red;*/
  position: absolute;
  display: block;
  width: 100%;
  height: 1100px;
  max-width: 750px;
  min-width: 350px;
  background-color: white;
}
.mainCntr {
  position: absolute;
  top: var(--main-top);
  bottom: var(--main-bottom);
  left: .0%;
  width: 100%;
  height: auto;
  overflow-y: auto;
  overflow-x: hidden;
}
/*#clockDiv {
  border: none; 
  position: absolute;
  right: 15px;
  top: 30px;
  width: 44px;
  height: 20px;
  font-size: 18px;
  color: #fff;
  font-weight: normal;
}*/
#loginDiv {
  border: none;
  position: absolute;
  right: 10px;
  top: 2px;
  width: 130px;
  height: 20px;
}
.omImage {
  position: relative;
  top: 0px;
  left: 10%;
  width: 80%;
  resize: both;
  height: 50px;
}
.loginImage {
  position: relative;
  padding-left: 6px;
  bottom: 0px;
  width: 20px;
  resize: both;
  height: 20px;
}
.loginImageSB {
  position: relative;
  padding-left: 6px;
  bottom: 18px;
  width: 20px;
  resize: both;
  height: 20px;
}
.loginImageBig {
  position: relative;
  padding-right: 12px;
  top: 0px;
  width: 40px;
  resize: both;
  height: 40px;
}
.headImage {
  position: relative;
  top: 0px;
  width: 80px;
  resize: both;
}
.topicImage {
  position: relative;
  padding-left: 0px;
  top: 0px;
  width: 60px;
  resize: both;
}
#statusDiv {
  border: none;
  position: absolute;
  left: 1px;
  top: 2px;
  width: 70px;
  height: 20px;
}
.statusImage {
  position: absolute;
  left: 8px;
  top: 0px;
  width: 20px;
  resize: both;
  height: 20px;
}
#logonName {
  border: none;
  position: absolute;
  left: 67px;
  top: 2px;
  width: 270px;
  height: 20px;
}
#functionDiv {
  border: none;
  position: absolute;
  right: -30px;
  bottom: 6px;
  width: 210px;
  height: 20px;
}
.iconLine {
  /*border: solid 3px green;  */
  display: inline-block;
  width: 56px;
}
.favoriteImage {
  position: relative;
  top: 3px;
  width: 18px;
  resize: both;
  height: 18px;
}
.favoriteImageB {
  position: relative;
  top: -1px;
  width: 23px;
  resize: both;
  height: 23px;
}
.dietImage {
  position: relative;
  top: 3px;
  width: 14px;
  resize: both;
  height: 18px;
}
.ratingStars {
  position: relative;
  top: -3px;
  height: 12px;
  font-size: 12px;
}
/*.listButton {
  background-color: darkcyan;
  height: 45px;
  width: 80px;
}*/
/*------------- MENU -------------*/
#menuCntr {
  position: absolute;
  width: 570px;
  height: 28px;
  bottom: 0;
  right: 0px;
}
#menuCntr ul {
  margin: 0px;
  padding: 0;
}
#menuCntr li {
  padding-left: 1px;
  list-style: none;
  float: left;
}
#menuCntr li a {
  text-decoration: none;
  text-align: center;
  width: 89px;
  height: 30px;
  display: block;
  color: #fff;
  line-height: 30px;
  font-family: var(--HeadFontType);
  font-size: 18px;
  background: url(images/menu_bg.jpg) left top no-repeat;
}
#menuCntr li a:hover {
color: #333333;
  }
/*------------ FOOTER MENU & FOOTER CONTAINER ------------*/
#footerCntr {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 40px;
  overflow: hidden;
  background: url(images/welcome_bg.jpg) center top repeat;
}
#footerCollapsed {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 0px;
  overflow: hidden;
}

#footTopStrip {
  border: none;
  position: absolute;
  left: 0;
  top: -1px;
  width: 100%;
  height: 5px;
  background: url(images/strip_bg.jpg) top left repeat;
}
.footermenu {
  position: absolute;
  left: 2px;
  bottom: 10px;
  width: 520px;
  overflow: hidden;
}
  .footermenu ul li {
    padding: 0px 18px;
    list-style: none;
    float: left;
    background: url(images/footer_line.jpg) left 4px no-repeat;
  }
  .footermenu ul li a {
    color: #fff;
    font-size: 12px;
    text-decoration: none;
  }
  .footermenu ul li a:hover {
    text-decoration: underline;
  }
  .footermenu ul li.first {
    padding-left: 0px;
    background: none;
  }
  .footermenu p {
    padding-top: 14px;
    float: left;
    color: #fff;
    font-size: 12px;
  }
  .footermenu p a {
    color: #fff;
    text-decoration: none;
  }
  .footermenu p a:hover {
    text-decoration: underline;
  }

#footerRight {
  border: none;
  position: absolute;
  right: 0px;
  bottom: 0px;
  width: 35%;
  height: 35px;
}
.twitterbox {
  border: none;
  position: absolute;
  right: 10px;
  bottom: 7px;
  width: 110px;
  height: 20px;
}
.linkbox {
  border: none;
  position: absolute;
  right: 200px;
  bottom: 7px;
  width: 110px;
  height: 20px;
}

/*------------- End of - Header and Footer css ---------------------------------------------------------------------------*/


/*------------- Edit Page css ---------------------------------------------------------------------------*/

#editTop {
  border: none; /*solid 4px green;*/
  position: absolute;
  left: 0px;
  top: 3px;
  width: 50%;
  height: 45%;
  background-color: red;
}
#editTopLeft {
  border: none; /*solid 4px green; */
  position: absolute;
  left: 0px;
  top: 3px;
  width: 35%;
  height: 45%;
  background-color: white;
}
#editTopRight {
  border: none; /*solid 4px blue; */
  position: absolute;
  right: 0px;
  top: 3px;
  width: 64%;
  height: 45%;
}
#editBottom {
  border: none;
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 100%;
  height: 53%;
}
.insideFrame {
  /*border: solid 3px black;  */
  position: absolute;
  top: 2px;
  bottom: 1px;
  left: 5px;
  right: 3px;
  background-color: white;
}
.editInsideLower {
  border: none; /*solid 3px black; */
  position: absolute;
  top: 250px;
  bottom: 1px;
  left: 5px;
  right: 3px;
  background-color: white;
}
.editDateInput {
  border: solid 4px blue;
  border-radius: 3px;
/*  box-shadow: 0 0 0 2px var(--box-color);*/
  position: absolute;
  width: 240px;
  font-weight: bold;
  margin-left: 24px;
  color: var(--text-color);
  background-color: var(--back-color);
}
.editCommentInput {
  border: solid 3px blue;
  position: absolute;
  margin-top: 5px;
  width: 240px;
  height: 40px;
  color: var(--text-color);
  background-color: var(--back-color);
}
.stripTab {
  border: solid 2px blue;
  height: 100%;
  width: 100%;
  background-color: white;
}
.tabList {
  border: none; /*solid 2px red; */
  width: 490px;
  background-color: white;
}
.tabList1 {
  border: none; /*solid 2px red; */
  width: 490px;
  background-color: white;
}    
.headerDiv {
  border: none; /*solid 4px red; */
  position: absolute;
  top: 5px;
  bottom: 1px;
  left: 0px;
  background-color: white;
}
.headerTitle {
  border: none; /*solid 3px black; */
  position: absolute;
  top: 0px;
  left: 5px;
  right: 5px; 
  width: 200px;
  background-color: white;
}
.headerGrid {
  border: none; /*solid 3px black; */
  position: absolute;
  top: 50px;
  bottom: 1px;
  background-color: white;
}
/*-----------------------------------------------------------*/
.buttonLeft {
  border: none; /*2px solid green; */
  position: absolute;
  left: 20px;
  width: 100px;
  height: 30px;
}
.buttonMiddle {
  border: none; /*2px solid red; */
  position: absolute;
  width: 100px;
  height: 28px;
  left: 130px;
}
.buttonBottom {
  border: none; /*2px solid red; */
  position: absolute;
  width: 200px;
  height: 28px;
  left: 40px;
  bottom: 20px;
}
.buttonRight {
  border: none; /*2px solid red; */
  position: absolute;
  width: 100px;
  height: 28px;
  left: 230px;
}
.buttonMidRight {
  border: none; /*2px solid red; */
  position: absolute;
  width: 100px;
  height: 28px;
  left: 172px;
}
.editTab {
  border: none; /*solid 4px blue; /* */
  position: absolute;
  top: 35px; /* Room for tab*/
  bottom: 1px;
  left: 5px;
  right: 5px;
  bottom: 0;
  right: 0;
  background-color: white;
}
.editTabFull {
  border: none; /*solid 4px red; */
  position: absolute;
  left: 0px;
  top: 0px; /* Room for tab*/
  bottom: 0px;
  right: 0px;
  bottom: 0;
  right: 0;
  background-color: white;
}
.editPane {  /* Not used with Tabs */
  border: none; /*solid 4px red; */
  position: absolute;
  left: 0px;
  top: 5px; 
  bottom: 1px;
  left: 5px;
  right: 5px;
  bottom: 0;
  right: 0;
  background-color: white;
}
.editTabTop {
  border: none; /*solid 4px green; */
  position: absolute;
  left: 0px;
  top: 1px;
  width: 100%;
  height: 45%;
  background-color: white;
}
.editTabBottom {
  border: none;
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 100%;
  height: 50%;
}
.editTabLeft {
  border: none; /*solid 1px blue; */
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 1px;
  width: 50%;
  background-color: white;
}
.editTabLeftNarrow {
  /*border: solid 2px blue;  */
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 1px;
  width: 33%;
  background-color: white;
}
.editTabLeftNarrowFixed {
  /*border: solid 2px red;  */
  position: relative;
  left: 0px;
  top: 5px;
  bottom: 5px;
  width: 350px;
  background-color: white;
}
.editTabMiddle {
  border: none; /*solid 1px green;  */
  position: absolute;
  left: 33%;
  top: 1px;
  bottom: 1px;
  width: 34%;
  background-color: white;
}
.editTabLeftWide {
  border: none; /*solid 1px blue; */
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 1px;
  width: 69%;
  background-color: white;
}
.editTabRight {
  border: none; /*solid 1px green;  */
  position: absolute;
  right: 1px;
  top: 0px;
  bottom: 0px;
  width: 50%;
  background-color: white;
}
.editTabRightNarrow {
  border: none; /*solid 1px green;  */
  position: absolute;
  right: 0px;
  top: 0px;
  bottom: 0px;
  width: 33%;
  background-color: white;
}
.editField {
  /* border: 2px solid red; */
  padding: 0 0 0 15px;
  margin: 1px 3px 1px 3px;
  width: 92%;
  /*background-color: white;*/
  justify-content: right;
  height: 35px;
}
.editFieldT {
  /* border: 2px solid red; */
  padding: 0 0 0 15px;
  margin: 1px 3px 1px 3px;
  width: 90%;
  /*background-color: white;*/
  justify-content: right;
  height: 42px;
}
.editFieldW {
  /* border: 2px solid red; */
  padding: 0 0 0 15px;
  margin: 1px 3px 1px 3px;
  width: 99%;
  /*background-color: white;*/
  justify-content: right;
  height: 35px;
}
.editTAField {
  border: none; /*2px solid red; */
  margin: 1px 3px 1px 3px;
  width: 99%;
  /*background-color: white;*/
  height: 65px;
}
.editFieldLabel {
  /* border: 2px solid red; */
  font-size: small;
  left: 0;
  color: black;
}
.editFieldLabelB {
  /* border: 2px solid red; */
  font-size: 14px;
  font-weight: bold;
  left: 0;
  color: black;
}
.editFieldLabelLeft {
  font-size: small;
  right: 0;
  color: black;
}
/**/
.editFieldL {
  border: 2px solid red; /**/
  padding: 0 0 0 10px;
  margin: 1px 3px 1px 3px;
  width: 45%;
  height: 35px;
}
.editFieldR {
  border: 2px solid green; /* */
  padding: 0 0 0 10px;
  margin: 1px 3px 1px 160px;
  width: 45%;
  height: 35px;
}
.displayInfo {
  position: relative;
  left: 4px;
  width: 92%;
  height: 20px;
  margin-top: 3px;   
  padding-left: 3px;
  color: var(--InputTextColor);
  border: none;
}
/*-------- Edit Fields -------------------*/
.editInput {
  position: absolute;
  left: var(--prompt-left);
  right: 4px;
  width: 58%;
  height: 23px;
  margin-top: 0px;  
  padding-left: 3px;
  color: var(--InputTextColor);   
  background-color: var(--InputBGColor);
  font-family: var(--InputFontType);
  font-size: var(--InputFontSize);    
  border-width: 2px;
  border-color: var(--InputBorderColor);
  border-radius: 3px;
}
.editInputS {
  position: absolute;
  left: var(--prompt-left);
  right: 4px;
  width: 30%;
  height: 23px;
  margin-top: 0px;
  padding-left: 3px;
  color: var(--InputTextColor);
  background-color: var(--InputBGColor);
  font-family: var(--InputFontType);
  font-size: var(--InputFontSize);
  border-width: 2px;
  border-color: var(--InputBorderColor);
  border-radius: 3px;
}
.editInputM {
  position: absolute;
  left: var(--prompt-left);
  right: 4px;
  width: 46%;
  height: 23px;
  margin-top: 0px;
  padding-left: 3px;
  color: var(--InputTextColor);
  background-color: var(--InputBGColor);
  font-family: var(--InputFontType);
  font-size: var(--InputFontSize);
  border-width: 2px;
  border-color: var(--InputBorderColor);
  border-radius: 3px;
}
.editInputL {
  position: absolute;
  left: var(--prompt-left);
  right: 4px;
  width: 60%;
  height: 23px;
  margin-top: 0px;
  padding-left: 3px;
  color: var(--InputTextColor);
  background-color: var(--InputBGColor);
  font-family: var(--InputFontType);
  font-size: var(--InputFontSize);
  border-width: 2px;
  border-color: var(--InputBorderColor);
  border-radius: 3px;
}
.editInputMed {
  position: absolute;
  left: var(--prompt-left);
  right: 4px;
  width: 160px;
  height: 23px;
  margin-top: 0px;
  padding-left: 3px;
  color: var(--InputTextColor);
  background-color: var(--InputBGColor);
  font-family: var(--InputFontType);
  font-size: var(--InputFontSize);
  border-width: 2px;
  border-color: var(--InputBorderColor);
  border-radius: 3px;
}
.editInputFM {
  position: absolute;
  left: var(--prompt-left);
  right: 4px;
  width: 200px;
  height: 23px;
  margin-top: 0px;
  padding-left: 3px;
  color: var(--InputTextColor);
  background-color: var(--InputBGColor);
  font-family: var(--InputFontType);
  font-size: var(--InputFontSize);
  border-width: 2px;
  border-color: var(--InputBorderColor);
  border-radius: 3px;
}
.editInputTA {
  color: var(--InputTextColor);
  background-color: var(--InputBGColor);
  font-family: var(--InputFontType);
  font-size: var(--InputFontSize);
  border-width: 2px;
  border-color: var(--InputBorderColor);
  border-radius: 3px;
}
.editShortInput {
  position: absolute;
  left: var(--prompt-left);
  right: 4px;
  width: 60px;
  height: 23px;
  margin-top: 0px;
  padding-left: 3px;
  color: var(--InputTextColor);
  background-color: var(--InputBGColor);
  font-family: var(--InputFontType);
  font-size: var(--InputFontSize);
  border-width: 2px;
  border-color: var(--InputBorderColor);
  border-radius: 3px;
}
.editNumInput {
  position: absolute;
  left: var(--prompt-left);
  right: 4px;
  width: 110px;
  height: 23px;
  margin-top: 0px;
  padding-right: 3px;
  text-align: right;
  color: var(--InputTextColor);
  background-color: var(--InputBGColor);
  font-family: var(--InputFontType);
  font-size: var(--InputFontSize);
  border-width: 2px;
  border-color: var(--InputBorderColor);
  border-radius: 3px;
}
.editNumShortInput {
  position: absolute;
  left: var(--prompt-left);
  right: 4px;
  width: 60px;
  height: 23px;
  margin-top: 0px;
  text-align: right;
  padding-right: 3px;
  color: var(--InputTextColor);
  background-color: var(--InputBGColor);
  font-family: var(--InputFontType);
  font-size: var(--InputFontSize);
  border-width: 2px;
  border-color: var(--InputBorderColor);
  border-radius: 3px;
}
.editPhoneInput {
  border: none;
  border-radius: 3px;
  box-shadow: 0 0 0 2px var(--box-color);
  position: absolute;
  left: var(--prompt-left);
  right: 4px;
  width: 130px;
  max-width: 340px;
  height: 23px;
  margin-top: 0px;
  color: var(--text-color);
  padding-left: 3px;
  background-color: var(--back-color);
}
.editMoneyInput {
  position: absolute;
  left: var(--prompt-left);
  right: 4px;
  width: 110px;
  height: 23px;
  margin-top: 0px;
  text-align: right;
  padding-right: 3px;
  color: var(--InputTextColor);
  background-color: var(--InputBGColor);
  font-family: var(--InputFontType);
  font-size: var(--InputFontSize);
  border-width: 2px;
  border-color: var(--InputBorderColor);
  border-radius: 3px;
}
.editMoneyInputB {
  position: absolute;
  left: var(--prompt-left);
  right: 4px;
  width: 110px;
  height: 23px;
  margin-top: 0px;
  text-align: right;   
  padding-right: 3px;  
  font-weight: bold;
  color: var(--InputTextColor);   
  background-color: var(--InputBGColor);
  font-family: var(--InputFontType);
  font-size: var(--InputFontSize);    
  border-width: 2px;
  border-color: var(--InputBorderColor);
  border-radius: 3px;
}
.editMoneyInputBS {
  width: 82px;
  height: 23px;
  text-align: right;
  padding-right: 3px;
  font-weight: bold;
  color: var(--InputTextColor);
  background-color: var(--InputBGColor);
  font-family: var(--InputFontType);
  font-size: 18px;
  border-width: 2px;
  border-color: var(--InputBorderColor);
  border-radius: 3px;
}
.editMoneyShortInput {
  position: absolute;
  left: var(--prompt-left);
  right: 4px;
  width: 50px;
  height: 23px;
  margin-top: 0px;
  text-align: right;
  padding-right: 3px;
  color: var(--InputTextColor);
  background-color: var(--InputBGColor);
  font-family: var(--InputFontType);
  font-size: var(--InputFontSize);
  border-width: 2px;
  border-color: var(--InputBorderColor);
  border-radius: 3px;
}
/*-------- RO -------*/
.editInputRO {
  position: absolute;
  left: var(--prompt-left);
  right: 4px;
  width: 200px;
  height: 23px;
  margin-top: 0px;
  padding-left: 3px;
  color: var(--InputTextColor);
  background-color: var(--ro-back-color);
  font-family: var(--InputFontType);
  font-size: var(--InputFontSize);
  border-width: 2px;
  border-color: var(--InputBorderColor);
  border-radius: 3px;
}
.editInputRO2 {
  position: absolute;
  left: var(--prompt-left);
  right: 4px;
  width: 200px;
  height: 23px;
  margin-top: 0px;
  padding-left: 3px;
  color: var(--InputTextColor);
  background-color: var(--ro-back-color);
  font-family: var(--InputFontType);
  font-size: var(--InputFontSize);
  border-width: 2px;
  border-color: var(--InputBorderColor);
  border-radius: 3px;
}
.editInputRO3 {
  position: absolute;
  left: 50px;
  width: 260px;
  height: 23px;
  margin-top: 0px;
  padding-left: 3px;
  color: var(--InputTextColor); 
  background-color: var(--ro-back-color);
  font-family: var(--InputFontType);
  font-size: var(--InputFontSize);
  border-width: 2px;
  border-color: var(--InputBorderColor);
  border-radius: 3px;
}
.dispRO3 {
  border: none;
  position: absolute;
  left: 60px;
  width: 260px;
  height: 23px;
  margin-top: 0px;
  padding-left: 3px;
  color: var(--InputTextColor);
  background-color: var(--ro-back-color);
  font-family: var(--InputFontType);
  font-size: var(--InputFontSize);
}
.editShortInputRO {
  position: absolute;
  left: var(--prompt-left);
  right: 4px;
  width: 60px;
  height: 23px;
  margin-top: 0px;
  padding-left: 3px;
  color: var(--InputTextColor);
  background-color: var(--ro-back-color);
  font-family: var(--InputFontType);
  font-size: var(--InputFontSize);
  border-width: 2px;
  border-color: var(--InputBorderColor);
  border-radius: 3px;
}
.editNumInputRO {
  position: absolute;
  left: var(--prompt-left);
  right: 4px;
  width: 110px;
  height: 23px;
  margin-top: 0px;
  text-align: right;
  padding-right: 3px;
  color: var(--InputTextColor);
  background-color: var(--ro-back-color);
  font-family: var(--InputFontType);
  font-size: var(--InputFontSize);
  border-width: 2px;
  border-color: var(--InputBorderColor);
  border-radius: 3px;
}
.editNumShortInputRO {
  position: absolute;
  left: var(--prompt-left);
  right: 4px;
  width: 60px;
  height: 23px;
  margin-top: 0px;
  padding-right: 3px;
  text-align: right;
  color: var(--InputTextColor);
  background-color: var(--ro-back-color);
  font-family: var(--InputFontType);
  font-size: var(--InputFontSize);
  border-width: 2px;
  border-color: var(--InputBorderColor);
  border-radius: 3px;
}
.editNumShortInputROB {
  position: absolute;
  left: var(--prompt-left);
  right: 4px;
  width: 60px;
  height: 23px;
  margin-top: 0px;
  text-align: right;
  padding-right: 3px;
  color: var(--InputTextColor); 
  background-color: var(--ro-back-color);
  font-family: var(--InputFontType);
  font-size: var(--InputFontSize);
  border-width: 2px;
  border-color: var(--InputBorderColor);
  border-radius: 3px;
}
.editMoneyInputRO {
  position: absolute;
  left: var(--prompt-left);
  right: 4px;
  width: 110px;
  height: 23px;
  margin-top: 0px;
  text-align: right;
  padding-right: 3px;
  color: var(--InputTextColor);
  background-color: var(--ro-back-color);
  font-family: var(--InputFontType);
  font-size: var(--InputFontSize);
  border-width: 2px;
  border-color: var(--InputBorderColor);
  border-radius: 3px;
}
.editMoneyInputROB {
  position: absolute;
  left: var(--prompt-left);
  right: 4px;
  width: 110px;
  height: 23px;
  margin-top: 0px;
  text-align: right;
  padding-right: 3px;
  color: var(--InputTextColor);
  background-color: var(--ro-back-color);
  font-family: var(--InputFontType);
  font-size: var(--InputFontSize);
  border-width: 2px;
  border-color: var(--InputBorderColor);
  border-radius: 3px;
}
.editMoneyShortInputRO {
  position: absolute;
  left: var(--prompt-left);
  right: 4px;
  width: 50px;
  height: 23px;
  margin-top: 0px;
  padding-right: 3px;
  text-align: right;
  color: var(--InputTextColor);
  background-color: var(--ro-back-color);
  font-family: var(--InputFontType);
  font-size: var(--InputFontSize);
  border-width: 2px;
  border-color: var(--InputBorderColor);
  border-radius: 3px;
}
.editCardInput {
  position: absolute;
  left: 40px;
  width: 240px;
  height: 24px;
  margin-top: 0px;
  padding-left: 3px;
  color: var(--InputTextColor);
  background-color: var(--ro-back-color);
  font-family: var(--InputFontType);
  font-size: var(--InputFontSize);
  border-width: 2px;
  border-color: var(--CardBorderColorN);
  border-radius: 3px;
}
.editCardShortInputCVC {
  position: absolute;
  left: 40px;
  width: 100px;
  height: 24px;
  margin-top: 0px;
  padding-left: 3px;
  color: var(--InputTextColor);
  background-color: var(--ro-back-color);
  font-family: var(--InputFontType);
  font-size: var(--InputFontSize);
  border-width: 2px;
  border-color: var(--CardBorderColorC);
  border-radius: 3px;
}
.editCardShortInputEXP {
  position: absolute;
  left: 40px;
  width: 100px;
  height: 24px;
  margin-top: 0px;
  padding-left: 3px;
  color: var(--InputTextColor);
  background-color: var(--ro-back-color);
  font-family: var(--InputFontType);
  font-size: var(--InputFontSize);
  border-width: 2px;
  border-color: var(--CardBorderColorE);
  border-radius: 3px;
}
.editCardInputName {
  position: absolute;
  left: 40px;
  width: 240px;
  height: 24px;
  margin-top: 0px;
  padding-left: 3px;
  color: var(--InputTextColor);
  background-color: var(--ro-back-color);
  font-family: var(--InputFontType);
  font-size: var(--InputFontSize);
  border-width: 2px;
  border-color: var(--InputBorderColor);
  border-radius: 3px;
}
/*-------- Div CSS --------*/
.editDDPos { /* div around Drop Down and Date, DateTime*/
  position: absolute;
  left: var(--prompt-left);
  right: 4px;
  margin-top: -17px;
  height: 30px;
  width: 200px;
}
.editDDPosMiddle { /* div around Drop Down and Date, DateTime*/
  position: absolute;
  left: 20px;
  right: 20px;
  height: 24px;
  width: 200px;
  margin-top: 6px;
}
.editInputDD { /* Inside editDDPos */
  /*border: solid 1px blue;*/
  position: absolute;
  left: 0px;
  width: 200px; 
  height: 30px;
  margin-top: 0px;
  padding-left: 0px;
  color: var(--InputTextColor);
  background-color: var(--InputBGColor);
  font-family: var(--InputFontType);
  font-size: var(--InputFontSize);
  border-width: 2px;
  border-color: var(--InputBorderColor);
  border-radius: 3px;
}
.editDateInput {
  position: absolute;
  left: 0px;
  width: 240px;
  margin-top: 0px;
  color: var(--InputTextColor); 
  background-color: var(--InputBGColor);
  font-family: var(--InputFontType);
  font-size: var(--InputFontSize);    
  border-width: 2px;
  border-color: var(--InputBorderColor);
  border-radius: 3px;
}
.editInputWide {
  position: absolute;
  left: var(--prompt-left);
  right: 4px;
  height: 23px;
  width: 80%;
  margin-top: 0px;
  padding-left: 3px;
  color: var(--InputTextColor);
  background-color: var(--InputBGColor);
  font-family: var(--InputFontType);
  font-size: var(--InputFontSize);
  border-width: 2px;
  border-color: var(--InputBorderColor);
  border-radius: 3px;
}
.chkField { /* div around field Selection Check*/
  position: absolute;
  left: var(--prompt-left);
  right: 4px;
  width: 40px;
  height: 23px;
}
/*----- Textarea CSS -------*/
.editTAInput {
  border: solid 1px blue;
  position: absolute;
  left: var(--prompt-left);
  height: 60px;
  right: 4px;
  width: 66%;
  word-wrap: break-word;
  padding-left: 3px;
  margin-top: 0px;
  color: var(--InputTextColor);
  background-color: var(--InputBGColor);
  font-family: var(--InputFontType);
  /*font-size: var(--InputFontSize);*/
  border-width: 2px;
  border-color: var(--InputBorderColor);
  border-radius: 3px;
  font-size: large;
}
.editTAInputFull {
  border: solid 1px blue;
  position: absolute;
  left: 4px;
  height: 60px;
  width: 95%;
  word-wrap: break-word;
  padding-left: 3px;
  padding-top: 5px;
  color: var(--InputTextColor);
  background-color: var(--InputBGColor);
  /*font-family: var(--InputFontType);*/
  font-size: var(--InputFontSize);
  border-width: 2px;
  border-color: var(--InputBorderColor);
  border-radius: 3px;
  font-size: large;
}
.editTAInputFullShort {
  border: solid 1px blue;
  position: absolute;
  left: 6px;
  height: 45px;
  width: 92%;
  word-wrap: break-word;
  margin-left: 6px;
  padding-left: 3px;
  margin-top: 3px;
  color: var(--InputTextColor);
  background-color: var(--InputBGColor);
  font-family: var(--InputFontType);
  /*font-size: var(--InputFontSize);*/
  border-width: 2px;
  border-color: var(--InputBorderColor);
  border-radius: 3px;
  font-size: large;
}
.editTAInputWide {
  position: absolute;
  left: var(--prompt-left);
  height: 60px;
  right: 4px;
  width: 80%;
  word-wrap: break-word;
  vertical-align: text-top;
  margin-top: 0px;
  padding-left: 3px;
  color: var(--InputTextColor);
  background-color: var(--InputBGColor);
  font-family: var(--InputFontType);
  /*font-size: var(--InputFontSize);*/
  border-width: 2px;
  border-color: var(--InputBorderColor);
  border-radius: 3px;
  font-size: large;
}
.editTAInputWideLong {
  position: absolute;
  left: var(--prompt-left);
  height: 140px;
  right: 4px;
  width: 80%;
  word-wrap: break-word;
  vertical-align: text-top;
  margin-top: 0px;  
  padding-left: 3px;
  color: var(--InputTextColor);
  background-color: var(--InputBGColor);
  font-family: var(--InputFontType);
  /*font-size: var(--InputFontSize);*/
  border-width: 2px;
  border-color: var(--InputBorderColor);
  border-radius: 3px;
  font-size: large;
  background-color: var(--InputBGColor);
}
/*------------- Narrow --------------------------------------------*/
.editNrwNumShortInput {
  position: absolute;
  left: var(--prompt-narrow-left);
  right: 4px;
  width: 50px;
  height: 23px;
  margin-top: 0px;
  padding-left: 3px;
  color: var(--InputTextColor);
  background-color: var(--InputBGColor);
  font-family: var(--InputFontType);
  font-size: var(--InputFontSize);
  border-width: 2px;
  border-color: var(--InputBorderColor);
  border-radius: 3px;
}
.editNrwDDPos { /* div around Drop Down and Date, DateTime*/
  position: absolute;
  left: var(--prompt-narrow-left);
  right: 4px;
  margin-top: -30px;
  height: 28px;
}
/*------ Money Amount Field ------*/
.moneyLabel {
  font-size: 14px;
  color: black;
  width: 100px;
  min-width: 100px;
}
.moneyAmount {
  font-size: 16px;
  color: black;
  font-weight: bold;
  float: right;
}
/*--------*/
.displayFieldMoney {
  /*border:2px solid red; */
  padding: 0 0 0 8px;
  margin: 1px 3px 1px 23px;
  width: 200px;
  /*background-color: white;*/
  justify-content: right;
  height: 21px;
}
/*--------*/
.displayField {
  border: none; /*2px solid red; */
  padding: 0 0 0 8px;
  margin: 1px 3px 1px 3px;
  width: 95%;
  /*background-color: white;*/
  justify-content: right;
  height: 21px;
}
.displayFieldShort {
  border: none; /*2px solid red; */
  padding: 0 0 0 8px;
  margin: 1px 3px 1px 3px;
  width: 95%;
  /*background-color: white;*/
  justify-content: right;
  height: 1px;
}
.displayFieldTall {
  border: none; /*2px solid red; */
  padding: 0 0 0 15px;
  margin: 1px 3px 1px 3px;
  width: 90%;
  /*background-color: white;*/
  justify-content: right;
  height: 35px;
}
/*------------- End of - Edit Page css ---------------------------------------------------------------------------*/
/*--- Shopping List Popup ---*/
.popupShoppingList {
  padding: 0px;
  margin: 0px;
  color: #787878;
  border-radius: 10px;
  background-color: purple;
  position: absolute;
  width: 354px;
  height: var(--itemDetailPopupHt); /**/
  line-height: 17px;
  font-family: var(--HeadFontType);
  font-size: 12px;
}
.shoppingList {
  /*display: block;
  z-index: 999;
  pointer-events: auto;  */
  /*border: solid 2px purple;*/
  margin: 0;
  border-width: 4px;
  border-color: purple; /*#0073e6;*/
  border-style: groove;
  border-radius: 10px;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 0px;
  top: 0px;
  left: 0px;
  width: 350px;
  /*height: 1000px;*/
  height: var(--itemDetailPopupHt); /* */
  bottom: 2px;
  background-color: white;
  text-decoration: none;
  text-align: left;
  color: black;
  font-family: var(--HeadFontType);
  font-size: 12px;
  scrollbar-face-color: #367CD2;
  scrollbar-shadow-color: #FFFFFF;
  scrollbar-highlight-color: #FFFFFF;
  scrollbar-3dlight-color: #FFFFFF;
  scrollbar-darkshadow-color: #FFFFFF;
  scrollbar-track-color: #FFFFFF;
  scrollbar-arrow-color: #FFFFFF;
}
.headerSL { /* Header Top - Brand-Blue Background - all other header blocks include '< xxx' are in this box */
  display: block;
  position: fixed;
  width: 350px;
  height: 145px; /*160px; calc(200 + var(--header-spec-ht) + var(--header-disp-ht) + var(--header-msg-ht)); */
  z-index: 4;
  background-color: var(--BrandColor);
  color: var(--LandingTextColor);
  /*border: 2px solid red;   */
}
.itemDetailHeader {
  display: block;
  /*border: solid 4px green;  */
  background-color: white;
  /*height: var(--itemDetailPopupHt);*/
}
.shoppingListHeader {
  display: block;
  /*border: solid 2px green;  */
  background-color: white;
  /*height: var(--itemDetailPopupHt);*/
}
/*.shoppingListTopInfo {
  border: solid 2px red;
  display: block;
  left: 0;
  top: 0;
  color: var(--BrandColor);
  height: 37px;
  width: 348px;
  z-index: 0;
}  */
.shoppingListTop {
  position: absolute;
  display: block;
  /*border-radius: 10px;*/
  left: 0;
  top: 0;
  margin: 0px 1px 0 1px;
  color: var(--BrandColor);
  /*background-color: var(--LandingTextColor);*/
  background-color: white;
  height: 33px; /*82px;*/
  width: 348px;
  z-index: 2;
}
.shoppingListLeft {
  position: absolute;
  left: 0;
  top: 4px;
  font-size: 14px;
  height: 27px; /*82px;*/
  width: 230px;
  z-index: 4;
  cursor: pointer;
  font-size: 16px;
}
.shoppingListRight {
  position: absolute;
  left: 175px;
  top: 5px;
  height: 27px; /*82px;*/
  width: 200px;
  z-index: 4;
  cursor: pointer;
  font-size: 16px;
  /*border: 2px solid green; */
}
.shoppingListRight2 {
  position: absolute;
  left: 241px;
  top: 5px;
  height: 27px; /*82px;*/
  width: 200px;
  z-index: 4;
  cursor: pointer;
  font-size: 16px;
  /*border: 2px solid green; */
}
.headerSLCenterTop {
  position: absolute;
  top: 44px;
  left: 20px;
  height: 24px; /*82px;*/
  width: 82%;
  font-size: 20px;
  font-weight: bold;
  /*background-color: var(--BrandColor);*/
  /*border: 2px solid green;   */
}
.headerSLCenterTop2 {
  position: absolute;
  top: 68px;
  left: 20px;
  height: 24px; /*82px;*/
  width: 82%;
  font-size: 16px;
  font-weight: bold;
  /*background-color: var(--BrandColor);*/
  /*border: 2px solid green;   */
}
.headerSLQCenterTop {
  position: absolute;
  top: 36px;
  left: 12px;
  height: 24px; /*82px;*/
  width: 82%;
  font-size: 20px;
  font-weight: bold;
  /*background-color: var(--BrandColor);*/
  /*border: 2px solid green;   */
}
.headerSLQCenterTop2 {
  position: absolute;
  top: 83px;
  left: 12px;
  height: 24px; /*82px;*/
  width: 82%;
  font-size: 16px;
  font-weight: bold;
  /*background-color: var(--BrandColor);*/
  /*border: 2px solid green;   */
}
.editSLItemInfo {
  /*border: solid 3px green; */
  display: block;
  /*position: absolute;  Stops scroll */
  margin-top: 150px; /* Push top of list down */
  /*height: calc(var(--itemDetailPopupHt) - 165px); */
  height: 200px;
  background-color: white;
}
.detailItemInfo {
  display: block;
  margin-top: var(--options-header-height);
  height: var(--options-list-height); /* Height needs to be set based on Options and Related List Heigh */
  /*border: solid 2px green;  */
  background-color: white;
}
.listSLRow {
  overflow: auto;
  clear: both;
  border-radius: 10px;
  border: 2px solid #ccc;
  margin: 3px 0px 3px 3px;
  padding: 0px 3px 0px 3px;
  min-height: 87px;
  font-size: 16px;
  width: 96%;
  background-color: white;
}
.listSLHeader {
  border-radius: 10px;
  border: 3px solid #ccc;
  background-color: var(--BrandColor);
  color: var(--LandingTextColor);
  height: 28px;
  padding-left: 10px;
  width: 331px;
  font-size: large;
  overflow-x: hidden;
  /*border: solid 2px green;  */
}
.listSLFooter {
  border-radius: 10px;
  border: 3px solid var(--ButtonBGColor); /**/
  background-color: var(--BrandColor);
  color: var(--LandingTextColor);
  height: 40px;
  width: 98%;
  font-size: large;
  overflow-x: hidden;
}
/* Shopping List Search */
.headerScanSearch {
  position: absolute;
  z-index: 10;
  left: 20px;
  bottom: 20px;
  height: 36px; /*82px;*/
  width: 50%;
  /*border: 1px solid red;   */
}
.headerScanSearchItem {
  position: absolute;
  z-index: 10;
  left: 20px;
  top: 90px;
  height: 36px; /*82px;*/
  width: 60%;
  /*border: 1px solid red;   */
}         
#headerSearchSL {
  position: absolute;
  top: 15px;
  width: 150px;
  height: 25px;
  border: 2px solid #787878;
  /*border-color: var(--InputBorderColor);*/
  border-radius: 15px;
  background-color: white;
}
#searchBtnDivSL { /* search button clear */  
  border: none;
  position: absolute;
  left: 1px;
  top: 2px;
  width: 8px;
  height: 11px;
}
#clearSearchBtnDivSL { /* search button */
  border: none;
  position: absolute;
  right: 14px;
  bottom: 9px;
  width: 8px;
  height: 11px;
}  
#searchBoxDivSL { /* search text */
  /*border: 2px solid green;  */
  position: absolute;
  left: 24px;
  top: -22px;
  width: 154px;
  height: 24px;
}
.searchInputSL {
  border: none;
  top: 4px;
  padding-left: 1px;
  color: var(--InputTextColor);
  background-color: white; /*var(--InputBGColor);*/
  font-family: var(--InputFontType);
  font-size: 18px; /*var(--InputFontSize);*/
  width: 120px;
  height: 20px;
}
/*--- Shopping List Item Popup ---*/
.headerSLItem {
  display: block;
  position: fixed;
  width: 350px;
  height: 120px; /*160px; calc(200 + var(--header-spec-ht) + var(--header-disp-ht) + var(--header-msg-ht)); */
  z-index: 4;
  background-color: var(--BrandColor);
  color: var(--LandingTextColor);
  /*border: 2px solid red;   */
}
.editSLItemField {
  /*border: 2px solid purple;  */
  padding: 1px 0 0 15px;
  margin: 1px 1px 1px 1px;
  width: 346px;
  height: 50px;
}
.editSLItemLabel {
  /* border: 2px solid red; */
  position: absolute;
  font-size: small;
  left: 13px;
  color: black;
}
.editSLItemInput {
  position: absolute;
  left: 8px;
  width: 94%;
  height: 23px;
  margin-top: 18px;
  padding-left: 3px;
  color: var(--InputTextColor);
  background-color: var(--InputBGColor);
  font-family: var(--InputFontType);
  font-size: var(--InputFontSize);
  border-width: 2px;
  border-color: var(--InputBorderColor);
  border-radius: 3px;
}
.editSLTAField {
  border: none; /*2px solid red; */
  padding: 0 0 0 10px;
  margin: 0px 3px 1px 3px;
  width: 92%;
  height: 45px;
}
.editSLTAInput {
  position: absolute;
  left: 8px;
  height: 45px;
  width: 94%;
  word-wrap: break-word;
  padding-left: 3px;
  margin-top: 18px;
  color: var(--InputTextColor);
  background-color: var(--InputBGColor);
  font-family: var(--InputFontType);
  font-size: var(--InputFontSize);
  border-width: 2px;
  border-color: var(--InputBorderColor);
  border-radius: 3px;
  resize: none; 
}
.editSLQTAInput {
  position: absolute;
  left: 8px;
  height: 35px;
  width: 94%;
  word-wrap: break-word;
  padding-left: 3px;
  margin-top: 18px;
  color: var(--InputTextColor);
  background-color: var(--InputBGColor);
  font-family: var(--InputFontType);
  font-size: var(--InputFontSize);
  border-width: 2px;
  border-color: var(--InputBorderColor);
  border-radius: 3px;
  resize: none;
}
.editSLSpecField { /* SL Item Price */
  /*border: 2px solid red; */
  position: absolute;
  left: 13px;
  padding: 0 0 0 10px;
  margin: 45px 8px 1px 0px;
  width: 90%;
  /*background-color: white;*/
  height: 75px;
}
.NumSLQuantity {
  position: absolute;
  left: 250px;
  font-size: large;
  font-weight: bold;
  border: 2px solid #ccc;
  border-radius: 5px;
}
/*--- Shopping Lists Popup ---*/
.popupShoppingLists {
  padding: 0px;
  margin: 0px;
  color: #787878;
  border-radius: 10px;
  background-color: #fcf7f8;
  position: absolute;
  width: 350px;
  height: var(--itemDetailPopupHt); /**/
  line-height: 17px;
  font-family: var(--HeadFontType);
  font-size: 12px;
}
.shoppingLists {
  /*display: block;
  z-index: 999;
  pointer-events: auto;  */
  /*border: solid 2px purple;*/
  border-width: 4px;
  border-color: #0073e6;
  border-style: solid;
  border-radius: 10px;
  overflow-x: hidden;
  overflow-y: scroll;
  width: 350px;
  /*height: 1000px;*/
  height: var(--itemDetailPopupHt); /* */
  bottom: 2px;
  background-color: white;
  text-decoration: none;
  text-align: left;
  color: black;
  font-family: var(--HeadFontType);
  font-size: 12px;
  scrollbar-face-color: #367CD2;
  scrollbar-shadow-color: #FFFFFF;
  scrollbar-highlight-color: #FFFFFF;
  scrollbar-3dlight-color: #FFFFFF;
  scrollbar-darkshadow-color: #FFFFFF;
  scrollbar-track-color: #FFFFFF;
  scrollbar-arrow-color: #FFFFFF;
}
.headerSLs {
  display: block;
  position: fixed;
  width: 350px;
  height: 145px; /*160px; calc(200 + var(--header-spec-ht) + var(--header-disp-ht) + var(--header-msg-ht)); */
  z-index: 4;
  background-color: var(--BrandColor);
  color: var(--LandingTextColor);
  /*border: 2px solid red;   */
}
.shoppingListsTop {
  position: absolute;
  display: block;
  /*border-radius: 10px;*/
  left: 0;
  top: 0;
  margin: 0px 1px 0 1px;
  color: var(--BrandColor);
  /*background-color: var(--LandingTextColor);*/
  background-color: white;
  height: 33px; /*82px;*/
  width: 348px;
  z-index: 2;
}
.shoppingListsLeft {
  position: absolute;
  left: 0;
  top: 4px;
  font-size: 14px;
  height: 20px; /*82px;*/
  width: 2000px;
  z-index: 4;
  cursor: pointer;
  font-size: 16px;
} 
.headerSLsCenterTop {
  position: absolute;
  top: 44px;
  left: 25px;
  height: 24px; /*82px;*/
  width: 82%;
  /*background-color: var(--BrandColor);*/
  /*border: 2px solid green;   */
}
.shoppingListsView {
  /*border: solid 3px green; */
  display: block;
  /*position: absolute;  Stops scroll */
  margin-top: 150px; /* Push top of list down */
  height: calc(var(--itemDetailPopupHt) - 165px); /**/
  background-color: white;
}
.detailItemInfo {
  display: block;
  margin-top: var(--options-header-height);
  height: var(--options-list-height); /* Height needs to be set based on Options and Related List Heigh */
  /*border: solid 2px green;  */
  background-color: white;
}
.listSLsRow {
  position: relative;
  overflow: auto;
  clear: both;
  border-radius: 10px;
  border: 2px solid #ccc;
  margin: 3px 0px 3px 3px;
  padding: 0px 3px 0px 3px;
  min-height: 93px;
  font-size: 16px;
  background-color: white;
}
#listSLsRowName { 
  /*border: solid 1px green; */
  position: absolute;
  word-wrap: break-word;
  text-align: left;
  left: 0px;
  top: 0px;
  width: 290px;
  height: 50px;
}
#listSLsRowDelete { /* delete button */
  /*border: solid 1px red; */
  position: absolute;
  left: 290px;
  top: 0px;
  width: 40px;
  height: 60px;
}
#listSLsRowCount {
  /*border: solid 1px green; */
  position: absolute;
  word-wrap: break-word;
  text-align: left;
  left: 0px;
  top: 45px;
  width: 330px;
  height: 40px;
}
.listSLsHeader {
  border-radius: 10px;
  border: 3px solid #ccc;
  background-color: var(--BrandColor);
  color: var(--LandingTextColor);
  height: 28px;
  padding-left: 10px;
  width: 331px;
  font-size: large;
  overflow-x: hidden;
  /*border: solid 2px green;  */
}
.listSLsFooter {
  border-radius: 10px;
  border: 3px solid var(--ButtonBGColor); /**/
  background-color: var(--BrandColor);
  color: var(--LandingTextColor);
  height: 40px;
  width: 98%;
  font-size: large;
  overflow-x: hidden;
}
/* Shopping List Search */
.headerListsScanSearch {
  position: absolute;
  z-index: 10;
  left: 14px;
  bottom: 2px;
  height: 36px; /*82px;*/
  width: 60%;
  /*border: 1px solid red;  */
}         
#headerSearchSLs {
  position: absolute;
  top: 0px;
  width: 150px;
  height: 25px;
  border: 2px solid #787878;
  /*border-color: var(--InputBorderColor);*/
  border-radius: 15px;
  background-color: white;
}         
#searchBtnDivSLs { /* search button clear */  
  border: none;
  position: absolute;
  left: 1px;
  top: 2px;
  width: 8px;
  height: 11px;
}
#clearSearchBtnDivSLs { /* search button */
  border: none;
  position: absolute;
  right: 14px;
  bottom: 9px;
  width: 8px;
  height: 11px;
}  
#searchBoxDivSLs { /* search text */
  /*border: 2px solid green;  */
  position: absolute;
  left: 24px;
  top: -22px;
  width: 154px;
  height: 24px;
}
.searchInputSL {
  border: none;
  top: 4px;
  padding-left: 1px;
  color: var(--InputTextColor);
  background-color: white; /*var(--InputBGColor);*/
  font-family: var(--InputFontType);
  font-size: 18px; /*var(--InputFontSize);*/
  width: 120px;
  height: 20px;
}
/*--- Item Detail Popups ---*/
.popupItemDetail {
  padding: 0px;
  margin: 0px;
  color: #787878;
  border-radius: 10px;
  background-color: #fcf7f8;
  position: absolute;
  width: 354px;
  height: var(--itemDetailPopupHt); /**/
  line-height: 17px;
  font-family: var(--HeadFontType);
  font-size: 12px;
}
/*--- Shopping List Item Popups ---*/
.popupSLItem {
  color: #787878;
  border-radius: 10px;
  background-color: #fcf7f8;
  position: absolute;
  width: 354px;
  height: var(--itemDetailPopupHt); /**/
  line-height: 17px;
  font-family: var(--HeadFontType);
  font-size: 12px;
}
/*---------------- Add Item Detail - No Image - Desc Only ---------------------------------------------------------------*/
.addItemDescHeader {
  position: relative;
  /*border: solid 3px green;  */
  margin: 0;
  background-color: white;
  width: 100%;
}      
.addItemDescTitleDesc {
  position: relative;
  overflow: auto; /*clip;*/
  clear: both;
  border-radius: 10px;
  border: 2px solid #ccc;
  margin: 3px 3px 8px 3px;
  padding: 13px 3px 8px 3px;
  min-height: 82px;
}
.addItemDescName {
  font-size: 18px;
  color: black;
  font-weight: bold;
  padding: 3px 3px 4px 3px;
}
.addItemDescTitle {
  font-size: 17px;
  color: black;
  padding: 3px 3px 4px 3px;
}
.addItemDescDesc {
  font-size: 17px;
  color: black;
  padding: 3px 3px 4px 3px;
}
.addItemDescQty {
  position: relative;
  width: 100%;
  padding: 8px 3px 8px 3px;
  height: 39px;
}
.addItemDescTotal {
  position: relative;
  width: 100%;
  padding: 10px 3px 8px 3px;
  height: 39px;
}
/*---------------- Add Item Detail With Image ---------------------------------------------------------------*/
/* WHY IS THIS CALLED OPTION - CHANGE TO LightDetail??  */
/* Head Div For Detail Desc - without Options/Related */
.optionHeader {
  display: block;
  /*border: solid 3px black;  */
  margin: 0;
  border-width: 4px;
  border-color: #0073e6;
  border-style: groove;
  border-radius: 10px;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: hidden;
  padding: 2px;
  top: 2px;
  left: 2px;
  width: 350px;
  /*height: var(--itemDetailPopupHt);*/
  bottom: 2px;
  background-color: white;
  text-decoration: none;
  text-align: left;
  color: black;
  font-family: var(--HeadFontType);
  font-size: 12px;
  scrollbar-face-color: #367CD2;
  scrollbar-shadow-color: #FFFFFF;
  scrollbar-highlight-color: #FFFFFF;
  scrollbar-3dlight-color: #FFFFFF;
  scrollbar-darkshadow-color: #FFFFFF;
  scrollbar-track-color: #FFFFFF;
  scrollbar-arrow-color: #FFFFFF;
}
/*--- This is the div for the whole popup - only non-Block divs will scroll */
.itemSelection {
  /*display: block;
  z-index: 999;
  pointer-events: auto;
  border: solid 3px black;  */
  margin: 0;
  border-width: 4px;
  border-color: #0073e6;
  border-style: groove;
  border-radius: 10px;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 0px;
  /*top: 1px;
  left: 1px;*/
  width: 350px;
  /*height: 1000px;*/
  height: var(--itemDetailPopupHt);/* */
  /*bottom: 2px;*/
  background-color: white;
  text-decoration: none;
  text-align: left;
  color: black;
  font-family: var(--HeadFontType);
  font-size: 12px;
  scrollbar-face-color: #367CD2;
  scrollbar-shadow-color: #FFFFFF;
  scrollbar-highlight-color: #FFFFFF;
  scrollbar-3dlight-color: #FFFFFF;
  scrollbar-darkshadow-color: #FFFFFF;
  scrollbar-track-color: #FFFFFF;
  scrollbar-arrow-color: #FFFFFF;
}
.itemDetailHeader {
  display: block;
  /*border: solid 4px green;  */
  background-color: white;
  /*height: var(--itemDetailPopupHt);*/
}
.optionTopInfo {
  display: block;
  left: 0;
  top: 0;
  margin: 2px 3px 0 0px;
  color: var(--BrandColor);
  background-color: var(--LandingTextColor);
  height: 37px; /*82px;*/
  width: 99%;
  z-index: 0;
}
.optionTop {
  position: absolute;
  display: block;
  border-radius: 8px;
  left: 0;
  top: 0;
  margin: 3px 3px 0 4px;
  color: var(--BrandColor);
  /*background-color: var(--LandingTextColor);*/
  background-color: white;
  height: 52px; /*82px;*/
  width: 99%;
  z-index: 2;
}
.optionLeft {
  position: absolute;
  left: 0;
  top: 12px;
  font-size: 14px;
  height: 20px; /*82px;*/
  width: 2000px;
  z-index: 4;
  cursor: pointer;
  font-size: 16px;
}   
.optionOrderRight {
  position: absolute;
  right: 2px;
  top: 5px;
  height: 50px; /*82px;*/
  width: 40px;
  /*width: 280px;*/
  /*border: 2px solid green; */
}
.optionCenterR2 {
  position: absolute;
  top: 0px;
  right: 36px;
  height: 30px;
  /*border: 3px solid red;  */
}
.optionTopImage {
  position: absolute;
  display: block;
  top: 45px;
  left: 4px;
  width: 346px;
  height: var(--options-image-height);
  z-index: 5;
  /*border: 1px solid blue; */
}
.optionFlexPic {
  border-radius: 15px;
  box-shadow: 5px 5px 3px var(--DropShadowColor);
  /*border: 3px solid var(--LandingBackgroundColor);*/
  background-color: white;
  margin: 0 13px 0 4px;
  padding: 0; 
  z-index: 5;
  /*height: 230px;*/
  width: 332px;
  height: var(--options-image-height);
}
.optionFramePic {
  height: 230px;
  width: 100%;
  background-color: white;
  border-radius: 10px;
}
.optionDescHeader {
  position: absolute;
  top: 290px;
  width: 95%;
  /*border: solid 1px green;  */
  margin: 8px 10px 3px 10px;
  background-color: white;
}
.optionDescName {
  position: absolute;
  top: 0px;
  left: 5px;
  width: 95%;
  color: black;
  font-size: 18px;
  font-weight: bold;
  /*border: solid 1px red; */
}         
.optionDescription {
  position: absolute;
  top: 25px;
  left: 7px;
  width: 320px;
  color: black;
  font-size: 16px;
  font-weight: normal;
  /*border: solid 1px green;  */
}
.optionMoney {
  position: absolute;
  left: 7px;
  width: 325px;
  height: 80px;
  color: black;
  font-size: 14px;
  font-weight: normal;
  /*border: solid 1px blue;  */
}
.optionTotal {
  position: absolute;
  top: 60px;
  left: 0px;
  width: 320px;
  height: 30px;
  color: black;
  font-size: 14px;
  font-weight: normal;
  /*border: solid 1px red;  */
}
/*---------------- Add Item Detail - with Image and Options and Related Items ---------------------------------------------------------------*/
/* Note: AddItemDetail header with image uses the same css as AddItem*/
/*------------------------------------------------------------------------------------------------------------------------------------------*/
/* Popup Options and Related Items CSS   */
/*------------------------------------------------------------------------------------------------------------------------------------------*/
.infoHeader {
  display: block;
  /*border: solid 3px red; */
  margin-top: 15px;
  padding: 13px 2px 3px 3px;
  background-color: white;
}
.optionHeaderDesc {
  position: absolute;
  display: block;
  width: calc(100% - 2px);
  /*width: 100%;*/
  /*border: solid 2px green; */
  top: calc(var(--options-image-height) + 50px);
  padding: 13px 0px 3px 0px;
  background-color: white;
  z-index: 2;
}
.infoHeaderB {
  position: relative;
  /*border: solid 3px red; */
  padding: 3px 2px 3px 3px;
  background-color: white;
}
infoItemChangeList {
  display: block;
  margin-top: var(--options-header-height);
  height: 200px;
  border: solid 3px red; /* */
  background-color: red;
}
.infoItemChangeListHeader {
  /*border: 1px solid black;*/
  background-color: whitesmoke;
  height: 23px;
  font-size: 14px;
  font-weight: bold;
  color: black;
}
/*--- The Option and Related Item Scrolling Div */
.detailItemInfo {
  display: block;
  margin-top: var(--options-header-height);
  height: var(--options-list-height); /* Height needs to be set based on Options and Related List Heigh */
  /*border: solid 2px green;  */
  background-color: white;
}
infoItemListAfter {
  display: block;
  /*border: solid 3px red; */
  height: 15px;
  background-color: white;
}
.infoDescName {
  display: block;
  width: 95%;
  color: black;
  font-size: 18px;
  font-weight: bold;
  margin-left: 5px;
  /*border: solid 1px red; */
}
.infoDescription {
  display: block;
  width: 95%;
  font-size: 16px;
  font-weight: normal;
  color: black;
  margin-top: 6px;
  padding: 4px 6px 3px 7px;
}
.optionGroupHeader {
  display: block;
  /*border: solid 1px blue;  */
  margin-left: 5px;
  margin-top: 5px;
  margin-right: 4px;
  margin-bottom: 0px;
  height: 20px;
  z-index: 2000;
  background-color: white;
}
.optionGroupTitleL {
  font-size: 15px;
  color: black;
  font-weight: bold;
}
.optionGroupTitleR {
  font-size: 14px;
  color: black;
  font-weight: bold;
  float: right;
}
.optionPane {
  display: block;
  border: solid 1px blue; /* */
  border-radius: 5px;
  margin: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  background-color: white;
  margin-bottom: 2px;
  z-index: 2;
}
.optionCheckbox {
  color: brown;
  width: 24px;
  height: 24px;
}
.optionInst {
  display: block;
  /*border: solid 2px red; /* */
  padding: 0 0 2px 0;
  height: 72px;
  margin-top: -2px;
  margin-left: 8px;
  overflow-x: hidden;
  overflow-y: hidden;
  background-color: white;
}
optionOptionPrice {
  height: 20px;
  width: 100%;
  margin-top: -5px;
}
.optionTotalPrice {
  height: 30px;
  width: 100%;
  margin-top: 12px;
  color: black;
}
.optionCaption { /* Attempt to position price caption with css */
  display: block;
  border: solid 2px blue; /* */
  height: 20px;
  width: 80px;
  font-size: 18px;
}
.optionSelect {
  height: 15px;
  width: 100%;
  margin-top: -2px;
}
/* Related Items */
.relatedList { /* Note - Main Div nothing - get rid of Div? */

}  
.listRelatedHeader {
  border-radius: 10px;
  border: 3px solid var(--ButtonBGColor); /**/
  background-color: linen;
  position: relative;
  background-color: whitesmoke;
  height: 23px;
  font-size: large;
  font-weight: bold;
  color: black;
  margin: 100px,0,5px,0;
}
/* Related List Detail */
.listRelatedRow {
  position: relative;
  overflow: auto; /*clip;*/
  clear: both;
  border-radius: 10px;
  border: 2px solid #ccc;
  margin: 3px 0px 0px 0px;
  padding: 0px 0px 0px 3px;
  min-height: 82px;
}
.listRelatedCol1 {
  float: left;
  width: calc(100% - 141px);
}
.listRelatedColR {
  position: absolute;
  /*border: solid 3px green;  */
  width: 139px;
  top: 3px;
  right: 0px;
}
.listRelatedCol2 {
  position: absolute;
  /*border: solid 3px red;  */
  height: 74px;
  left: 0;
  width: 53px;
}
.listRelatedColRTot {
  /*border: solid 3px green;  */
  position: absolute;
  font-size: 16px;
  font-weight: bold;
  color: #3e80ed;
  top: 21px;
  left: 1px;
}      
.listRelatedIconLine {
  /*border: solid 3px red;  */
  position: absolute;
  top: 44px;
  height: 25px;
  right: 0;
  width: 55px;
}       
.listRelatedColPromo {
  /*border: solid 3px green;  */
  position: absolute;
  top: 9px;
  right: 0;
  width: 25px;
  resize: both;
  height: 25px;
}
.listRelatedColRSC {
  /*border: solid 3px green;  */
  position: absolute;
  top: 7px;
  right: 38px;
  width: 25px;
  resize: both;
  height: 25px;
}
.listRelatedColRImage {
  /*border: solid 3px green;  */
  position: absolute;
  top: 7px;
  right: 38px;
  width: 25px;
  resize: both;
  height: 25px;
}
.listRelatedCol4 {
  float: right;
  padding-right: 2px;
  width: 76px;
}
.listRelatedPromo {
  /*border: solid 3px green;  */
  position: absolute;
  top: 0px;
  right: 1px;
  width: 25px;
  resize: both;
  height: 25px;
}
.listRelatedFooter {
  border-radius: 10px;
  border: 3px solid var(--ButtonBGColor); /**/
  background-color: whitesmoke;
  height: 1px;
  font-size: large;
  font-weight: bold;
  color: black;
}
/*------------------------------------------------------------------------------------------------------------------------------------------*/
/*-----------------------------------------------------------------------------*/
/* Horizontal Scrolling Menu */
/*-----------------------------------------------------------------------------*/
/* Horizontal Select */
#categoriesSelect {
  position: absolute;
  left: 0px;
  height: 36px;
  width: 100%;
  /*bottom: 0px;*/
  top: 48px;
  overflow: auto;
  text-align: center;
  vertical-align: middle;
  color: red;
  font-size: 24px;
  background-color: white;
  /* border: 1px solid red;  */
}
.categoriesScroll {
  color: red;
}
.menu-item {
  padding: 0 3px;
  margin: 5px 3px;
  user-select: none;
  color: #999999; /*var(--LandingTextColor);*/
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  border: none;
}
.menu-item-wrapper.active {
  border: none;
}
.menu-item.active {
  border: 3px var(--BrandColor) solid;
  color: var(--BrandColor);
  border-radius: 5px;
}
.scroll-menu-arrow {
  padding: 0px 2px 2px 2px;
  font-size: 24px;
  font-weight: normal;
  color: #999999; /*var(--LandingTextColor);*/
  cursor: pointer;
}
.scroll-menu-arrow--disabled {
  /*visibility: hidden;*/
}
/*------- End of Horizontal Scroll css ---------------------------------*/
/*-----------------------------------------------------------------------------*/
.seatGroupHeader {
  display: block;
  border: none; /* solid 2px blue; /* */
  margin-left: 5px;
  margin-top: 5px;
  margin-bottom: 0px;
  height: 30px;
  background-color: white;
}
.seatGroupHeader1 {
  display: block;
  border: none; /*solid 2px red; /* */
  left: 5px;
  margin-left: 5px;
  margin-top: 5px;
  margin-bottom: 0px;
  height: 14px;
  background-color: white;
}
.seatGroupHeader2 {
  display: block;
  /*border: solid 2px blue;  */
  left: 5px;
  margin-left: 5px;
  margin-top: 10px;
  margin-bottom: 0px;
  height: 30px;
  background-color: white;
}
.seatGroupHeader3 {
  display: block;
  /* border: solid 2px blue; */
  left: 5px;
  margin-left: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
  height: 20px;
  background-color: white;
}
.seatCheckbox {
  color: brown;
  width: 20px;
  height: 20px;
}
.seatCheckPane {
  /*display: block;*/
  border: solid 2px var(--BrandColor); /* */
  border-radius: 5px;
  margin: 5px 1px 5px 1px;
  overflow-x: hidden;
  overflow-y: hidden;
  background-color: white;
}
/*---------------- Options css---------------------------------------------------------------*/
.overlay {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  /*background-color: rgb(0,0,0);*/
  background-color: rgba(0,0,0, 0.9);
  overflow-x: hidden;
  transition: 0.5s;
}
/*---------- Buttons ---------------------*/
#TButton1 {
  background-color: #99ff66;
}
.NumQuantity {
  font-size: large;
  font-weight: bold;
  border: 2px solid #ccc;
  border-radius: 5px;
}
/*---------- Splitter --------------------*/
.pane-content {
  padding: 0 10px;
}
/* Note - no h1 */
h3 {
  font-size: 1.2em;
  margin: 10px 0;
  padding: 0;
}
h4 {
  color: #3e7ae0;
  font-size: 16px;
  font-weight: bolder;
  text-align: center;
}
h5 {
  color: #eb0725;
  font-size: calc(10px + 1vmin);
}
h6 {
  color: #3e7ae0;
  font-size: 22px;
}
h7 {
  color: #dc2033;
  font-size: 22px;
}
p {
  margin: 0;
  padding: 0;
}
#divC {
  position: absolute;
  border: none; /*solid 3px black; */
  top: 0px;
  margin: -10px 0px 10px 0px;
  height: 90%;
  width: 90%;
}
#chartSingle {
  border: solid 3px red;
  position: absolute;
  top: 0px;
  bottom: 20px;
  left: 10px;
  right: 10px;
  background-color: red;
}
/*--------------------------------------------------------------------------------------------------------------------------------*/
/* Template css - Get rid of what is not used specifically - March 8, 2019           */
/*--------------------------------------------------------------------------------------------------------------------------------*/
/* ### BANNER CONTAINER ### */
#bannerCntr {
  position: relative;
  top: 0;
  float: none;
  margin-left: auto;
  margin-right: auto;
  width: 1000px;
  height: 366px;
  background: url(images/banner_bg.jpg) left top no-repeat;
}
.bannerTextBox {
  padding-top: 104px;
  padding-left: 50px;
  float: left;
  width: 450px;
  overflow: hidden;
}
.bannerTextBox p {
  font-family: var(--HeadFontType);
  color: #fff;
  font-size: 28px;
}
.textBox {
  padding-top: 0px;
  float: left;
  width: 475px;
  overflow: hidden;
}
/* ### BANNER BOX ### */
.bannerBox {
  padding: 50px 0px 0px 25px;
  float: left;
  width: 428px;
  overflow: hidden;
}
/* ### HEADING BOX ### */
.headingBox {
  width: 98%;
  overflow: hidden;
  height: 176px;
}
.headingBox img {
  padding-top: 15px;
  float: right;
}
/* ### CONTENT CONTAINER ### */
#contentCntr {
  overflow: hidden;
  width: 98%;
  margin: 0 auto;
}
/*--------------------------------------------------------------------------------------------------------------------------------*/
/* Bambora css            */
/*--------------------------------------------------------------------------------------------------------------------------------*/
.bamboraPane {
  display: block;
  border: solid 2px blue; /* */
  width: 345px;
  height: 440px;
  border-radius: 5px;
  margin: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  background-color: white;
}
.bamboraHeading {
  display: block;
  width: 330px;
  height: 35px;
  margin-top: 10px;
  margin-left: 30px;
  overflow-x: hidden;
  overflow-y: hidden;
  background-color: white;
}
.bamboraCard {
  display: block;
  width: 310px;
  height: 320px;
  margin-left: 5px;
  overflow-x: hidden;
  overflow-y: hidden;
  background-color: white;
  /*border: solid 1px blue;  */
}
.bamboraFooter {
  display: block;
  width: 330px;
  height: 30px;
  margin-left: 30px;
  overflow-x: hidden;
  overflow-y: hidden;
  background-color: white;
}
.container {
  background-color: red; /*rgb(240, 240, 240);*/
  margin: auto;
  width: calc(95%);
  border: none;
  border-radius: 4px;
}
#checkout-form {
  position: relative;
  width: calc(75%); /* was 95% */
  margin: 10px 10px 10px 10px; /* was auto */
  padding: 10px;
}
#checkout-form label {
  display: block;
  min-height: 25px;
  font-size: 15px;
  font-weight: 500;
  margin: 5px 0;
  padding: 0;
  color: red;
}
#card-number, #card-cvv, #card-expiry {
  background-color: #FFF;
  display: block;
  width: calc(90%);
  border-radius: 2px;
  border: 1px solid rgba(200, 200, 200, 1);
  padding: 14px 60px 3px 20px;
  margin: auto;
  transition: all 100ms ease-out;
}
/* card images are added to card number */
#card-number {
  background-image: none;
  background-origin: content-box;
  background-position: calc(100% + 40px) center;
  background-repeat: no-repeat;
  background-size: contain;
}
/* buttons */
.btn {
  vertical-align: top;
}
.btn {
  position: relative;
  border: none;
  border-radius: 4px;
  background-color: rgba(120, 71, 181, 1);
  color: rgba(255, 255, 255, 1);
  display: inline-block;
  transition: all 100ms ease-out;
  padding: 11px 25px;
}
.btn:hover, .btn:active {
  background-color: rgba(69, 36, 89, 1);
}
.btn:active {
  box-shadow: inset 0 0 35px rgba(0, 0, 0, 0.3);
}
.btn:disabled {
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid rgba(120, 71, 181, 1);
  color: rgba(120, 71, 181, 1);
}
/* feedback is displayed after tokenization */
#feedback {
  position: relative;
  left: 15px;
  display: inline-block;
  background-color: transparent;
  border: 0px solid rgba(200, 200, 200, 1);
  border-radius: 4px;
  transition: all 100ms ease-out;
  padding: 11px;
}
  #feedback.error {
    color: red;
    border: 1px solid;
  }
  #feedback.success {
    color: seagreen;
    border: 1px solid;
  }
/*--------------------------------------------------------------------------------------------------------------------------------*/
/* Original ContractAccess css            */
/*--------------------------------------------------------------------------------------------------------------------------------*/
.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 30vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 3vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.Input-Half {
  width: 200px;
  color: var(--InputTextColor);
}
.Input-Full {
  width: 340px;
  color: var(--InputTextColor);
}
.ButtonImg {
  text-align: center;
  margin-top: 3px;
  margin-left: 3px;
  background-color: #000000;
  border: 0px;
  width: 20px;
  height: 20px;
}
.Header1 {
  text-align: center;
  color: #e0aa14;
  font-weight: bold;
  min-width: 200px;
}
.Label1 {
  text-align: right;
  color: #e0aa14;
  font-weight: bold;
}
.Label2 {
  text-align: center;
  color: #e0aa14;
  font-weight: bold;
}
.Data1 {
  text-align: center;
  color: black;
  font-weight: bold;
}
.Display1 {
  text-align: left;
  max-width: 180px; 
  color: var(--InputTextColor);
}
.Owner {
  text-align: right;
  color: #38b540;
  font-weight: bold;
}
.FullCardForm {
  width: 330px;
  min-width: 330px;
  height: 730px;
  border: 4px solid darkblue;
  border-radius: 10px;
  margin: 0vw;
  padding: 10px;
  position: relative;
  background-color: floralwhite;
  font-weight: bold;
  /* position:relative for .prevBtn and .nextBtn position:absolute; */
}
.PostalCardForm {
  width: 330px;
  min-width: 330px;
  height: 575px;
  border: 4px solid darkblue;
  border-radius: 10px;
  margin: 0vw;
  padding: 10px;
  position: relative;
  background-color: floralwhite;
  font-weight: bold;
  /* position:relative for .prevBtn and .nextBtn position:absolute; */
}
.ShortCardForm {
  width: 330px;
  min-width: 330px;
  height: 517px;
  border: 4px solid darkblue;
  border-radius: 10px;
  margin: 0vw;
  padding: 10px;
  position: relative;
  background-color: floralwhite;
  font-weight: bold;
  /* position:relative for .prevBtn and .nextBtn position:absolute; */
}
.MinimalCardForm {
  width: 330px;
  min-width: 330px;
  height: 365px;
  border: 4px solid darkblue;
  border-radius: 10px;
  margin: 0vw;
  padding: 10px;
  position: relative;
  background-color: floralwhite;
  font-weight: bold;
  /* position:relative for .prevBtn and .nextBtn position:absolute; */
}
.XMinimalCardForm {
  width: 330px;
  min-width: 330px;
  height: 425px;
  border: 4px solid darkblue;
  border-radius: 10px;
  margin: 0vw;
  padding: 10px;
  position: relative;
  background-color: floralwhite;
  font-weight: bold;
  /* position:relative for .prevBtn and .nextBtn position:absolute; */
}
.XMinimalCardForm1 {
  width: 330px;
  min-width: 330px;
  height: 420px;
  border: 4px solid darkblue;
  border-radius: 10px;
  margin: 0vw;
  padding: 10px;
  position: relative;
  background-color: floralwhite;
  font-weight: bold;
  /* position:relative for .prevBtn and .nextBtn position:absolute; */
}
.XMinimalCardForm2 {
  width: 305px;
  min-width: 305px;
  height: 225px;
  border: none; /*4px solid darkblue;*/
  border-radius: 10px;
  margin: 0vw;
  padding: 10px;
  position: relative;
  background-color: floralwhite;
  font-weight: bold;
}
.c {
  width: 305px;
  min-width: 305px;
  height: 225px;
  border: 4px solid darkblue;
  border-radius: 10px;
  margin: 0vw;
  padding: 10px;
  position: relative;
  background-color: floralwhite;
  font-weight: bold;
}
#PaymentForm {
  border: none; /*solid 2px green;*/
  height: 325px;
}
.cardStyle {
  border: solid 2px green;
  background-color: red; 
  position: absolute;
  top: 30px;
}
.cvcStyle {
  position: absolute;
  top: 60px;
  background-color: green;
}
.expiryStyle {
  position: absolute;
  top: 60px;
  background-color: blue;
}
.k-form-fieldset {
  font-size: 16px;
}
.k-rating-item {
  width: 35px;
  height: 35px;
}
/*----------------------------------------*/
/* Note - this overrides all grid rows    */
/* override telerik default theme values*/
/*.k-grid tbody .k-grid .k-grid-header {
  display: none;
}*/
.k-header {
  display: none;
}
.k-master-row {
  height: 15px;
}
.k-grid {
  font-size: 16px;
}
.k-grid td {
  padding: 0px;
}
.k-switch {

}
.k-switch-off .k-switch-thumb {
  background-color: #ff8080;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
